/* eslint-disable */
import pwcheck from '../utils/pwcheck'
import {
    createDateObj,
    IBANCheck
} from "./utils";
import i18next from "i18next";
import toast from "react-hot-toast";

class FormValidation {
    constructor(values, setValues) {
        this.state = {
            'passFieldStatus': {
                valid: true,
                message: ''
            }
        }
        this.fieldValidations = {}
        this.newValues(values)
        this.setValues = setValues
        this.externalData = {}
        this.validators = {
            unequal: {
                message: i18next.t('validator.field_required'),
                test: (val) => {
                    return (val && val !== ''.trim())
                }
            },
            sameas: {
                message: i18next.t('validator.field_sameas'),
                test: (val, allValues, data) => {
                    return allValues[data] && allValues[data] === val;
                }
            },
            length: {
                message: i18next.t('validator.field_length'),
                test: (val, allValues, data) => {
                    return val.toString().length === parseInt(data);
                }
            },
            required: {
                message: i18next.t('validator.field_required'),
                test: (val) => {
                    return (val && val !== ''.trim())
                }
            },
            alpha: {
                message: i18next.t('validator.field_alpha'),
                test: (val) => {
                    const re = /^([a-zA-Z0-9]*)$/
                    return re.test(val)
                }
            },
            'email': {
                message: i18next.t('validator.field_email'),
                test: (val) => {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return re.test(val)
                }
            },
            'url': {
                message: i18next.t('validator.field_url'),
                test: val => {
                    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\\.\\-]+\.[a-zA-Z]{2,5}[\\.]{0,1}/
                    return re.test(val)
                }
            },
            'string': {
                message: i18next.t('validator.field_string'),
                test: (val) => {
                    return (typeof val) === 'string'
                }
            },
            'boolean': {
                message: i18next.t('validator.field_boolean'),
                test: (val) => {
                    return (val === '1' || val === '0' || val === 1 || val === 0)
                    // return (typeof val)==='boolean';
                }
            },
            'integer': {
                message: i18next.t('validator.field_integer'),
                test: (val) => {
                    return Number.isInteger(1 * val)
                }
            },
            'date': {
                message: i18next.t('validator.field_date'),
                test: () => {
                    return true
                }
            },
            'dateminyears': {
                message: i18next.t('validator.field_dateminyears'),
                test: (val, allValues, data) => {
                    return ((createDateObj()) - (createDateObj(val))) / (1000 * 3600 * 24 * 365) >= data;
                }
            },
            'numeric': {
                message: i18next.t('validator.field_numeric'),
                test: (val) => {
                    return !isNaN(val)
                }
            },
            'iban': {
                message: i18next.t('validator.field_iban'),
                test: (val) => {
                    return IBANCheck(val)
                }
            },
            'minmoney': {
                message: () => {
                    return i18next.t('validator.field_minmoney');
                },
                test: (val, allValues, data) => {
                    return val >= parseFloat(data);
                }
            },
            'maxmoney': {
                message: () => {
                    return i18next.t('validator.field_maxmoney');
                },
                test: (val, allValues, data) => {
                    return val <= parseFloat(data);
                }
            },
            'pwcheck': {
                message: () => (this.passFieldStatus.message),
                test: (val) => {
                    const {valid, message} = pwcheck(val, this.externalData['email'] || '')
                    this.passFieldStatus = {
                        valid: valid,
                        message: message
                    }
                    return valid
                }
            }
        }

    }

    addExternalData(externalData) {
        this.externalData = externalData ? externalData : {}
    }

    translateValidation(text) {
        const tr = {
        }

        if (tr[text]) return tr[text];
        return text;
    }

    addFieldValidations(fieldValidations) {
        let newFieldValidations = {}
        Object.keys(fieldValidations).forEach((name) => {
            let newValidator = []
            let validators = Array.isArray(fieldValidations[name]) ? fieldValidations[name] : fieldValidations[name].split('|')
            validators.forEach((v) => {
                v = v.split(':');
                if (this.validators[v[0]]) {
                    let validation = {type: v[0], message: this.translateValidation(this.validators[v[0]].message)}
                    if (v[1]) validation.data = v[1];
                    newValidator.push(validation)
                } else {
                    console.log('validator ' + v[0] + ' not found')
                }
            })
            newFieldValidations[name] = newValidator
        })
        this.fieldValidations = newFieldValidations
    }

    responseAdapter(res) {
        if (res.errors) {
            Object.keys(res.errors).forEach((fieldName) => {
                this.addSingleFieldValidation(fieldName, res.errors[fieldName], this.getValues().changed[fieldName])
            });
        } else if (res.message) {
            toast(res.message, 'error')
        }
    }

    newValues(values) {
        this.values = values;
    }

    getValues() {
        return this.values;
    }

    addSingleFieldValidation(fieldname, errormessage, oldvalue) {
        if (Array.isArray(errormessage)) errormessage = errormessage[0];
        if (!oldvalue) {
            this.fieldValidations[fieldname] = [
                {type: 'required', message: this.translateValidation(errormessage), data: oldvalue}
            ]
        } else {
            this.fieldValidations[fieldname] = [
                {type: 'unequal', message: this.translateValidation(errormessage), data: oldvalue}
            ]
        }
    }

    validateAll() {

        let fieldToValue = []
        for (let fieldName of Object.keys(this.fieldValidations)) {
            let fieldValue = this.getValues().changed[fieldName]
            fieldToValue.push([fieldName, fieldValue])
        }
        return this.validateFields(fieldToValue)
    }

    handleFieldChange(field, newValue) {
        this.validateFields([[field, newValue]])
    }


    validateFields(fieldsToValues) {
        // read old states
        let changed = Object.assign({}, this.getValues().changed)
        let validated = Object.assign({}, this.getValues().validated)
        let errors = Object.assign({}, this.getValues().errors)
        // validate
        for (let fieldToValue of fieldsToValues) {
            changed[fieldToValue[0]] = fieldToValue[1]
            errors = this.validateField(fieldToValue[0], fieldToValue[1], errors, changed)
            if (!errors[fieldToValue[0]]) {
                // we have no errors, but it hasn't changed anything
                validated[fieldToValue[0]] = fieldToValue[1]
                if (validated[fieldToValue[0]] === this.getValues().original[fieldToValue[0]]) {
                    delete validated[fieldToValue[0]]
                }
            } else if (errors[fieldToValue[0]]) {
                delete validated[fieldToValue[0]]
            }
        }

        // write new states
        let newState
        newState = Object.assign({}, this.getValues())
        newState.changed = changed
        newState.validated = validated
        newState.errors = errors
        this.setValues(newState)
        // check if there are some errors
        return !errors || Object.keys(errors).length === 0
    }

    processMessage(validator, field) {
        if (validator.type !== 'pwcheck' && typeof validator.message === 'function') {
            return validator.message(validator.data, field)
        }
        return validator.message
    }

    validateField(field, val, errors, allValidatedFields) {
        let validators = this.fieldValidations[field]
        if (!this.fieldValidations[field]) {
            // field will not be validated
            return this.addError(field, '', errors)
        }
        if ((!val || val === '') && validators[0] && validators[0].type !== 'required') {
            // field is empty and not required
            return this.addError(field, '', errors)
        }
        // lets validate
        for (let validator of validators) {
            if (validator.type === 'unequal' && val === validator.data) {
                // server validation
                return this.addError(field, this.processMessage(validator), errors)
            } else if (!this.validators[validator.type].test(val, allValidatedFields, validator.data || '')) {
                // error found
                return this.addError(field, this.processMessage(validator), errors)
            }
        }
        // no error found
        return this.addError(field, '', errors)
    }

    addError(field, message, errors) {
        // if(!errors)errors = {};
        if (message === '') {
            if (errors[field]) delete errors[field]
        } else {
            errors[field] = message
        }
        return errors
    }
}

export default FormValidation
