import {useTranslation} from "react-i18next";
import React, {useContext, useEffect} from "react";
import {AuthContext} from "../../utils/Auth";
const DataPolicy = () => {
    //hooks & constants
    const {t} = useTranslation();
    const {setPageAttributes} = useContext(AuthContext);

    //effects
    useEffect(() => {
        setPageAttributes(t('title.policy'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //render
    return <div className={'grid gap-4 grid-cols-12'}>
    </div>
}


export default DataPolicy
