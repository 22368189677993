import React, {useState} from 'react'
import InfoBox from "../InfoBox";

const FieldSelect = ({className='', selectClassName = '', field, value, onChange, error}) => {

    const [dataOptions] = useState([])


    const renderOptions = (values) =>
        values.map((value, key) =>
            <option key={key} value={value.value}>
                {value.label}
            </option>
        )

    return (
        <div className={"mb-4 relative " + className}>
            {field.label &&
                <label htmlFor={field.name} className={'block pb-1'}>
                    {field.label}
                    {field.suffix && <InfoBox content={field.suffix}/>}
                </label>
            }
            <select className={"block w-full rounded bg-dark autofill:bg-dark focus:ring-0 focus:ring-offset-0 focus:border-primary border border-secondary text-sm " + selectClassName}
                    id={field.name}
                    aria-describedby={field.name + 'Help'}
                    onChange={(evt) => onChange(field.name, evt.target.value)}
                    value={value}
            >
                {field.placeholder && <option value="">{field.placeholder}</option>}
                {renderOptions(dataOptions.length>0?dataOptions:field.values)}
            </select>
            {error !== '' && <small className="form-text text-danger block">{error}</small>}
        </div>
    )

}

export default FieldSelect
