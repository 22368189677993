import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Box from "./Box";
import {domainBackgroundColors} from "../utils/utils";
import {useTranslation} from "react-i18next";
import {apiGetPublishedByWeek} from "../utils/api";

const BoxPublishedArticles = ({domains}) => {
    //hooks & constants
    const {t} = useTranslation();

    //states
    const [articlesByWeek, setArticlesByWeek] = useState({})
    //effects
    useEffect(() => {
        apiGetPublishedByWeek().then((response) => {
            if (response.status) {
                setArticlesByWeek(response.articles_by_week)
            }
        })
    }, []);

    //methods
    const getWeekArray = (count) => {
        let d = new Date();
        d.setHours(0, 0, 0);
        let weekArray = [];
        for (let i = 0; i < count; i++) {
            weekArray.push(Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7));
            d.setDate(d.getDate() - 7);
        }
        return weekArray;
    }

    const getScale = (max, count) => {
        if (max < count) {
            max = count;
        }
        let ret = [];
        for (let i = 0; i <= count; i++) {
            ret.push(Math.ceil(max / count * i));
        }
        return ret;

    }

    //render
    const numberOfScales = 6;
    const numberOfWeeks = 4;
    const weekArray = getWeekArray(numberOfWeeks);
    const maxArticlesPerWeek = Math.max(Math.max(...Object.values(articlesByWeek).map((week) => {
        return Object.values(week).reduce((a, b) => a + b, 0);
    })), numberOfScales);

    return domains && (
        <Box className={'!pt-4'}>
            <div
                className={"block pb-2 relative before:content-[''] before:block before:ml-[-1.5rem] before:mr-[-1.5rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary"}>
                {
                    domains.map((domain, index) => {
                        return <Link key={domain.id} to={'/domains/' + domain.id}
                                     className={'text-white transition-colors duration-300 hover:text-primary whitespace-nowrap mr-8 mb-1 inline-block'}><span
                            className={'w-5 inline-block rounded-lg align-middle h-2 mr-2 ' + domainBackgroundColors().at(index % (domainBackgroundColors().length))}/><span
                            className={'inline-block align-middle'}>{domain.domain_name.replace('www.', '')}</span></Link>;
                    })
                }
            </div>
            <div className={'pt-6'}>
                {
                    weekArray.map((week) => {
                        return <div key={week} className={'flex items-center'}>
                            <div className={'w-20'}>{t('graph.week')} <span className={"font-mono"}>{week}</span></div>
                            <div className={'w-full flex'}>
                                {
                                    articlesByWeek[week] && Object.keys(articlesByWeek[week]).map((domainId) => {
                                        let projectColor = domainBackgroundColors().at(domains.findIndex((domain) => domain.id === parseInt(domainId)) % (domainBackgroundColors().length));
                                        return <div
                                            key={domainId}
                                            title={articlesByWeek[week][domainId] + ' Published Articles'}
                                            style={{width: 'calc(' + articlesByWeek[week][domainId] * 100 / maxArticlesPerWeek + '% - 0.5rem)'}}
                                            className={'rounded-lg align-middle h-2 mr-2 ' + projectColor}
                                        />
                                    })
                                }
                            </div>
                        </div>
                    })
                }

                <div className={'flex items-center pt-2 font-mono'}>
                    <div className={'w-20 text-right'}>0</div>
                    <div className={'w-full flex items-center text-right'}>
                        {
                            getScale(maxArticlesPerWeek, numberOfScales).map((scale, index) => {
                                if (scale === 0) return null;
                                return <div key={index} className={'w-1/6 pr-2'}>{scale}</div>
                            })
                        }
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default BoxPublishedArticles;
