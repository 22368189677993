import React from "react";
import {closestCenter, DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";

const SortableList = ({children, onChangeOrder}) => {
    //hooks & constants
    const sensors = useSensors(
        useSensor(PointerSensor),
        /*useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates(),
        })*/
    );


    //states

    //effects



    const handleDragEnd = (event)=>{
        const {active, over} = event;
onChangeOrder(active.id, over.id);/*
        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }*/
    }

    //render

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={children.map((child) => child.key)}
                strategy={verticalListSortingStrategy}
            >
                {children}
            </SortableContext>
        </DndContext>
    );
}


export default SortableList
