import React from 'react'
import classnames from "classnames";
import * as NumberFormat from "react-number-format";

const FieldCurrencyDecimal = ({field, onChange, error}) =>
    <div className="form-group">
        <label htmlFor={field.name}>{field.label}</label>
        <div className={classnames("currency-decimal", "form-group", {'text-danger': error !== ''})}>
            <NumberFormat
                className="form-control"
                displayType={'input'}
                thousandSeparator={'.'}
                decimalSeparator={","}
                isNumericString={true}
                suffix={' EUR'}
                allowNegative={false}
                decimalScale={2}
                id={field.name}
                name={field.name}
                fixedDecimalScale={true}
                placeholder={field.placeholder}
                onFocus={(evt) => {
                    evt.target.select();
                }}
                onValueChange={(values) => {
                    const name = field.name;
                    const value = values.floatValue;
                    if (value === '' || typeof value === 'number') {
                        onChange(name, value)
                    } else {
                        onChange(name, '')
                    }
                }}
            />
        </div>
        {field.suffix && <small id={field.name + 'Help'} className="form-text text-muted">{field.suffix}</small>}
        {error !== '' && <small className="form-text text-danger">{error}</small>}
    </div>

export default FieldCurrencyDecimal


