import React from 'react';
import IconAdjustments from '../components/icons/IconAdjustments';
import IconArrow from '../components/icons/IconArrow';
import {Link} from "react-router-dom";
import IconCross from "./icons/IconCross";

const Box = ({icon = false, iconLink, iconFunction, iconState = false, className = '', children, borderClass= "border-secondary"}) => {
    return (
        <div className={"border border-solid rounded-xl relative p-6 " + className + " " + borderClass}>
            {icon && (
                <div className="absolute top-3 right-3">
                    {iconLink ? (<Link to={iconLink} className={'text-white'}><IconArrow/></Link>) : ''}
                    {iconFunction ? (<button onClick={iconFunction} className={'text-white'}>{(!iconState ? <IconAdjustments/> : <IconCross/>)}</button>) : ''}
                </div>
            )}
            {children}
        </div>
    );
};

export default Box;
