import FieldText from "../form/FieldText";
import IconCheck from "../icons/IconCheck";
import IconCross from "../icons/IconCross";
import React, {useContext, useState} from "react";
import classnames from "classnames";
import IconTime from "../icons/IconTime";
import {ARTICLE_STATES, formatterDate} from "../../utils/utils";
import IconProject from "../icons/IconProject";
import IconLogIn from "../icons/IconLogIn";
import IconTag from "../icons/IconTag";
import IconState from "../icons/IconState";
import IconLike from "../icons/IconLike";
import IconEdit from "../icons/IconEdit";
import IconDislike from "../icons/IconDislike";
import IconReset from "../icons/IconReset";
import IconLoading from "../icons/IconLoading";
import {Link} from "react-router-dom";
import {apiPutArticle} from "../../utils/api";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import IconPremium from "../icons/IconPremium";
import IconArrow from "../icons/IconArrow";
import FieldToggle from "../form/FieldToggle";
import {AuthContext} from "../../utils/Auth";
import FieldSelect from "../form/FieldSelect";
import classNames from "classnames";


const ArticleRow = ({
                        article, index, onChange = () => {
    }
                    }) => {
    //hooks & constants
    const {t} = useTranslation();

    //states
    const [editMode, setEditMode] = useState(false)
    const [revert, setRevert] = useState(false)
    const [fieldContent, setFieldContent] = useState('');
    const [fieldCategory, setFieldCategory] = useState('');
    const [fieldPremium, setFieldPremium] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useContext(AuthContext);

    //methods
    const editTitle = () => {
        setFieldContent(article.title);
        setFieldCategory(article.category);
        setFieldPremium(article.type === 'premium');
        setEditMode(true);
    }

    const cancelEdit = () => {
        setEditMode(false);
    }

    const submitEdit = () => {
        //if (fieldContent === article.title) return cancelEdit();
        setLoading(true);
        apiPutArticle(article.id, {
            title: fieldContent,
            type: fieldPremium ? 'premium' : 'simple',
            state: ARTICLE_STATES.SUGGESTION,
            category: fieldCategory
        }).then((response) => {
            onChange(response.article);
            toast.success(response.message);
        }).finally(() => {
            setLoading(false);
        })

        setEditMode(false);
    }

    const acceptSuggestion = () => {
        setLoading(true);
        apiPutArticle(article.id, {state: ARTICLE_STATES.SUGGESTION_ACCEPTED}).then((response) => {
            onChange(response.article);
            toast.success(t('article.suggestion_accept.success'));
        }).finally(() => {
            setLoading(false);
        })
    }

    const denySuggestion = () => {
        setLoading(true);
        apiPutArticle(article.id, {state: ARTICLE_STATES.SUGGESTION_DENIED}).then((response) => {
            onChange(response.article);
            toast.success(t('article.suggestion_deny.success'));
        }).finally(() => {
            setLoading(false);
        })
    }

    const revertSuggestion = () => {
        setLoading(true);
        apiPutArticle(article.id, {state: ARTICLE_STATES.SUGGESTION}).then((response) => {
            onChange(response.article);
            toast.success(t('article.suggestion_revert.success'));
        }).finally(() => {
            setLoading(false);
        })
    }

    const disableAccept = (type) => {
        console.log(user.user.usage.articles_left < 1)
        if (type === 'simple') {
            if (user.user.usage.articles_left < 1)
                return user.user.usage.articles_left < 1;
        } else {
            return user.user.usage.premium_articles_left < 1;
        }
        return false;
    }

    const articleStateSwitch = (article) => {
        switch (article.state.name) {
            case ARTICLE_STATES.SUGGESTION:
                return <>
                    {(!editMode ?
                            <>
                                {
                                    disableAccept(article.type) ?
                                        <>
                                            <button
                                            data-tooltip-content={(article.type === 'simple' ? t('article.currentlyNoArticles') : t('article.currentlyNoPremiumArticles'))} data-tooltip-id={'react-tooltip'}
                                            disabled={true}
                                            className={'bg-gray-500 rounded text-white text-center opacity-30 py-1 px-2'}>
                                            <IconLike
                                                className={'mx-auto !w-5 !h-5'}/></button>
                                            <button
                                                disabled={loading}
                                                className={'bg-primary rounded text-white text-center py-1 px-2 ml-1 hover:bg-primary-dark transition-colors duration-300'}
                                                onClick={() => editTitle()}><IconEdit
                                                className={'mx-auto !w-5 !h-5'}/>
                                            </button>
                                            <button
                                                data-tooltip-content={(article.type === 'simple' ? t('article.currentlyNoArticles') : t('article.currentlyNoPremiumArticles'))} data-tooltip-id={'react-tooltip'}
                                                disabled={true}
                                                className={'bg-gray-500 opacity-30 rounded text-white text-center py-1 px-2 ml-1'}>
                                                <IconDislike
                                                    className={'mx-auto !w-5 !h-5'}/></button>
                                        </>
                                        :
                                        <>
                                            <button
                                                onClick={() => acceptSuggestion()}
                                                className={'bg-success rounded text-white text-center py-1 px-2 hover:bg-success-dark transition-colors duration-300'}>
                                                <IconLike
                                                    className={'mx-auto !w-5 !h-5'}/></button>
                                            <button
                                                disabled={loading}
                                                className={'bg-primary rounded text-white text-center py-1 px-2 ml-1 hover:bg-primary-dark transition-colors duration-300'}
                                                onClick={() => editTitle()}><IconEdit
                                                className={'mx-auto !w-5 !h-5'}/>
                                            </button>
                                            <button
                                                onClick={() => denySuggestion()}
                                                disabled={loading}
                                                className={'bg-danger rounded text-white text-center py-1 px-2 ml-1 hover:bg-danger-dark transition-colors duration-300'}>
                                                <IconDislike
                                                    className={'mx-auto !w-5 !h-5'}/></button>
                                        </>

                                }
                            </>
                            :
                            <>
                                <button
                                    disabled={loading}
                                    className={'bg-secondary border border-solid border-secondary rounded text-white text-center py-1 px-2 ml-1 hover:border-primary hover:bg-primary transition-colors duration-300'}
                                    onClick={() => submitEdit()}><IconCheck className={'mx-auto !w-5 !h-5'}/></button>
                                <button
                                    disabled={loading}
                                    className={'bg-secondary border border-solid border-secondary rounded text-white text-center py-1 px-2 ml-1 hover:border-primary hover:bg-primary transition-colors duration-300'}
                                    onClick={() => cancelEdit()}><IconCross className={'mx-auto !w-5 !h-5'}/>
                                </button>
                            </>
                    )}
                </>;
            case ARTICLE_STATES.SUGGESTION_DENIED:
                return <button
                    onClick={() => revertSuggestion()}
                    disabled={loading}
                    className={'text-white'} onMouseOver={() => {
                    setRevert(true)
                }} onMouseLeave={() => {
                    setRevert(false)
                }}>{(!revert ? <IconDislike className={'ml-auto !w-5 !h-5 mr-2'}/> :
                    <IconReset className={'ml-auto !w-5 !h-5 mr-2'}/>)}</button>
            case ARTICLE_STATES.SUGGESTION_ACCEPTED:
                return <button
                    onClick={() => revertSuggestion()}
                    disabled={loading}
                    className={'text-white'} onMouseOver={() => {
                    setRevert(true)
                }} onMouseLeave={() => {
                    setRevert(false)
                }}>{(!revert ? <IconLike className={'ml-auto !w-5 !h-5 mr-2'}/> :
                    <IconReset className={'ml-auto !w-5 !h-5 mr-2'}/>)}</button>
            case ARTICLE_STATES.OUTLINE_GENERATING:
            case ARTICLE_STATES.ARTICLE_GENERATING:
            case ARTICLE_STATES.PUBLISHING:
                return <IconLoading animated="true" className={'ml-auto mr-2 !w-5 !h-5'}/>
            default:
                return <Link to={'/articles/' + article.id}
                             className={'inline-block bg-secondary border border-solid border-secondary rounded text-white text-center py-1 px-2 hover:border-primary hover:bg-primary transition-colors duration-300'}>
                    <IconArrow className={'ml-auto !w-5 !h-5 hover:stroke-white'}/>
                </Link>
        }
    }

    //render
    const hasImage = !!article.image_id;
    return <div
        className={classnames('col-span-12 grid gap-x-4 items-center grid-cols-12 p-2', (index % 2 === 0 ? 'bg-darker' : ''), (article.state === 'dismissed' ? 'opacity-30' : ''))}
        key={article.id}>
        {hasImage ? <div className={'col-span-2 lg:col-span-1'}>
            <img className={'w-auto max-h-11 rounded-l-md m-auto'} src={article.image.thumb_url}
                 alt={article.image.alt}/>
        </div> : null}
        <div className={classnames(hasImage ? 'col-span-8 lg:col-span-9' : 'col-span-10')}>
            {(editMode ?
                    <>
                        <FieldText
                            className={'!mb-2 w-full inline-block align-top'}
                            inputClassName={'py-1 px-3'}
                            field={{
                                name: 'suggestion-title-' + article.id,
                                type: 'text',
                                validator: 'required'
                            }}
                            error=''
                            value={fieldContent}
                            onChange={(name, value) => setFieldContent(value)}
                        />
                        {article.author.categories.length > 0 &&
                            <div className={'inline-block align-top pt-1 mb-2 relative'}>
                                <IconTag className={'absolute left-2 top-[0.6rem] z-10 pointer-events-none'}/>
                                <FieldSelect
                                    className={'!mb-0'}
                                    selectClassName={'py-0.5 pl-8'}
                                    field={{
                                        name: 'suggestion-category-' + article.id,
                                        validator: '',
                                        values: article.author.categories.map((cat, key) => {
                                            return {value: cat.title, label: cat.title}
                                        })
                                    }}
                                    value={fieldCategory}
                                    onChange={(name, value) => setFieldCategory(value)}
                                />
                            </div>
                        }
                        <div className={classNames('inline-block align-top mb-2', (article.author.categories.length > 0 ? 'ml-4' : ''))}>
                            <FieldToggle
                                field={{
                                    name: 'premium',
                                    label: t('article.premium')
                                }}
                                value={fieldPremium}
                                onChange={(name, value) => setFieldPremium(value)}/>
                        </div>
                    </>
                    : <b>
                        {article.title} {article.type === 'premium' && (
                        <IconPremium className={'inline-flex items-top'}/>
                    )}</b>
            )}
            <div className={'max-lg:clear-left'}>
                <div className={'inline-flex items-center mr-4'}><IconTime/>&nbsp;
                    <span>{formatterDate(article.created_at)}</span></div>
                <div className={'inline-flex items-center mr-4'}><IconProject/>&nbsp;
                    <span>{article.author.domain.domain_name}</span></div>
                <div className={'inline-flex items-center mr-4'}><IconLogIn/>&nbsp;<span>{article.author.name}</span>
                </div>
                <div className={'inline-flex items-center mr-4'}><IconTag/>&nbsp;<span>{article.category}</span></div>
                <div className={'inline-flex items-center mr-4'}><IconState/>&nbsp;
                    <span>{article.state.display_name}</span></div>
            </div>
        </div>
        <div className={'col-span-2 text-right'}>
            {articleStateSwitch(article)}
        </div>
    </div>
}


export default ArticleRow;
