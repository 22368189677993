import React from 'react';
import { Link } from 'react-router-dom';

const InfoText = ({ to = false, icon, text, steptext, description, border = true }) => {
    const descriptionClasses = border ? 'border-b border-dashed border-secondary' : '';

    return (
        <>
            {text ? (
                <><Link to={to} className={'inline-block mt-6 mb-4 text-white transition-colors duration-300 hover:text-primary'}>
                    {steptext && <span className={'inline-block align-middle mr-1'}>{steptext}</span>}
                    <span className={'inline-block align-middle mr-1'}>{icon}</span>
                    <span className={'inline-block align-middle'}>{text}</span>
                </Link>
                <div className={descriptionClasses +' whitespace-pre-line pb-7 text-sm'}>{description}</div></>
            ) : (
                <div className={descriptionClasses +' whitespace-pre-line pb-7 pr-10 text-sm'}>{description}</div>
                )}

        </>
    );
};

export default InfoText;
