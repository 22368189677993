import {getLoginToken} from "./utils";
import i18n from "i18next";

const apibase = "https://staging.evnxt.ai/api/";

const postRequest = (url, data, privateCall=false) => {
    let obj = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': i18n.language[0]+i18n.language[1]
        },
        body:data?JSON.stringify(data):null
    }
    if(privateCall){
        obj.headers['Authorization'] = 'Bearer ' + getLoginToken();
    }

    return fetch(url, obj)
        .then(response => response.json())
        .then(json => json)
        .catch(error => {
            console.error(error);
        });
}

const getRequest = (url, privateCall=false, params = {}) => {
    //get current languge


    let obj = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': i18n.language[0]+i18n.language[1]
        }
    }
    if(privateCall){
        obj.headers['Authorization'] = 'Bearer ' + getLoginToken();
    }

    if(Object.keys(params).length > 0){
        url += '?';
        for (const [key, value] of Object.entries(params)) {
            url += key + '=' + value + '&';
        }
        url = url.slice(0, -1);
    }

    return fetch(url, obj)
        .then(response => response.json())
        .then(json => json)
        .catch(error => {
            console.error(error);
        });
}

const deleteRequest = (url, privateCall=false) => {
    let obj = {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': i18n.language[0]+i18n.language[1]
        }
    }
    if(privateCall){
        obj.headers['Authorization'] = 'Bearer ' + getLoginToken();
    }

    return fetch(url, obj)
        .then(response => response.json())
        .then(json => json)
        .catch(error => {
            console.error(error);
        });
}

const putRequest = (url, data, privateCall=false) => {
    let obj = {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': i18n.language[0]+i18n.language[1]

        },
        body:data?JSON.stringify(data):null
    }
    if(privateCall){
        obj.headers['Authorization'] = 'Bearer ' + getLoginToken();
    }

    return fetch(url, obj)
        .then(response => response.json())
        .then(json => json)
        .catch(error => {
            console.error(error);
        });
}
// Auth
export const apiPostRegister = (name, email, password, tos) => postRequest(apibase + "auth/register", { name, email, password, tos });
export const apiPostLogin = (email, password) => postRequest(apibase + "auth/login", { email, password });
export const apiPostForgotPassword = (email) => postRequest(apibase + "auth/forgot-password", { email });
export const apiPostResetPassword = (email, token, password) => postRequest(apibase + "auth/reset-password", { token, password, email });
export const apiPostVerifyEmail = (token) => postRequest(apibase + "auth/verify-email", { token });
export const apiPostResendVerificationEmail = (email) => postRequest(apibase + "auth/resend-verification-email", { email });
export const apiPostLogout = () => postRequest(apibase + "auth/logout", null, true);
export const apiGetUser = () => getRequest(apibase + "auth/user", true);
export const apiGetUserNoToken = () => getRequest(apibase + "auth/user", false);

// Domain
export const apiGetDomains = () => getRequest(apibase + "domains", true);
export const apiGetDomain = (id) => getRequest(apibase + "domains/"+id, true);
export const apiPostDomain = (url) => postRequest(apibase + "domains", { url }, true);
export const apiDeleteDomain = (id) => deleteRequest(apibase + "domains/"+id, true);
export const apiPutDomain = (id, data) => putRequest(apibase + "domains/"+id, data, true);

// Domain Categories
export const apiGetDomainCategories = (domain_id) => getRequest(apibase + "domains/"+domain_id+"/categories", true);
export const apiGetDomainCategory = (domain_id, id) => getRequest(apibase + "domains/"+domain_id+"/categories/"+id, true);
export const apiPostDomainCategory = (domain_id, title, external_id) => postRequest(apibase + "domains/"+domain_id+"/categories", { title, external_id }, true);
export const apiDeleteDomainCategory = (domain_id, id) => deleteRequest(apibase + "domains/"+domain_id+"/categories/"+id, true);
export const apiPutDomainCategory = (domain_id, id, title, external_id) => putRequest(apibase + "domains/"+domain_id+"/categories/"+id, { title, external_id }, true);
export const apiGetDomainCategoryRefresh = (domain_id) => postRequest(apibase + "domains/"+domain_id+"/refresh-categories", null, true);

// Authors
export const apiGetAuthors = (params) => getRequest(apibase + "authors", true, params);
export const apiGetAuthor = (id) => getRequest(apibase + "authors/"+id, true);
export const apiPostAuthor = (name, domain_id) => postRequest(apibase + "authors", { name, domain_id }, true);
export const apiDeleteAuthor = (id) => deleteRequest(apibase + "authors/"+id, true);
export const apiPutAuthor = (id, values) => putRequest(apibase + "authors/"+id, values, true);
export const apiPostAuthorSyncCategories = (id, categories) => postRequest(apibase + "authors/"+id+"/sync-categories", { categories }, true);
export const apiPostAuthorLogout = (id) => postRequest(apibase + "authors/"+id+"/wp-logout", {id},  true);

// Author Links
export const apiGetAuthorLinks = (author_id) => getRequest(apibase + "authors/"+author_id+"/links", true);
export const apiGetAuthorLink = (author_id, id) => getRequest(apibase + "authors/"+author_id+"/links/"+id, true);
export const apiPostAuthorLink = (author_id, word, url) => postRequest(apibase + "authors/"+author_id+"/links", { word, url }, true);
export const apiDeleteAuthorLink = (author_id, id) => deleteRequest(apibase + "authors/"+author_id+"/links/"+id, true);
export const apiPutAuthorLink = (author_id, id, word, url) => putRequest(apibase + "authors/"+author_id+"/links/"+id, { word, url }, true);


// Articles
export const apiGetArticle = (id) => getRequest(apibase + "articles/"+id, true);
export const apiPutArticle = (id, values) => putRequest(apibase + "articles/"+id, values, true);
export const apiGetArticles = (params) => getRequest(apibase + "articles", true, params);
export const apiGetPublishedByWeek = (params) => getRequest(apibase + "articles/published", true, params);
export const apiPostChangeArticlePart = (params) => postRequest(apibase + "articles/change-article-part", params, true);

// profile
export const apiGetProfile = () => getRequest(apibase + "profile", true);
export const apiPutProfile = (values) => putRequest(apibase + "profile", values, true);
export const apiPostProfile = (values) => postRequest(apibase + "profile/update", values, true);
export const apiDeleteProfile = () => deleteRequest(apibase + "profile", true);
export const apiPutChangePassword = (old_password, new_password) => putRequest(apibase + "profile/change-password", { old_password, new_password }, true);
export const apiGetMessages = () => getRequest(apibase + "profile/messages", true);

//images
export const apiGetImages = (params) => getRequest(apibase + "images", true, params);

