import Box from "./Box";
import {useState} from "react";
import {apiDeleteAuthorLink, apiPutAuthorLink} from "../utils/api";
import IconTrash from "./icons/IconTrash";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import FormTranslated from "../components/form/FormTranslated";

const BoxLink = ({editable = true, link, onChange = null}) => {
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const {t} = useTranslation();

    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPutAuthorLink(link.author_id, link.id, validated.word, validated.url)
            .then((response) => {
                if (response.status) {
                    if (onChange) {
                        onChange(response.link);
                    }
                    setEdit(false);
                    toast(response.message);
                } else {
                    formValidator.responseAdapter(response);
                    formValidator.validateAll();
                }
            }).finally(() => {
            setLoading(false);
        });
    }

    const onDelete = () => {
        if (window.confirm(t('link.deleteConfirm'))) {
            setLoading(true);
            apiDeleteAuthorLink(link.author_id, link.id)
                .then((response) => {
                    if (onChange) {
                        onChange(null);
                    }
                    if (response.status) {
                        toast(response.message);
                    } else {
                        toast(response.message);
                        setLoading(false);
                    }
                }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }

    return (
        <Box className={'!p-3 h-full bg-dark'} icon={editable} iconFunction={() => setEdit(!edit)} iconState={edit}>
            {editable && <button className="absolute top-3 right-12 text-white" onClick={onDelete}><IconTrash/>
            </button>}
            <h2 className={"pr-3 mr-14"}>
                <span className={'inline-block align-middle'}>{link.word} </span> <span
                className={'inline-block align-middle text-xs'}>(Link: {link.url})</span>
            </h2>
            {edit ? <div className={'pt-4'}>
                <FormTranslated
                    loading={loading}
                    name={'editLink'}
                    filename={'editLink'}
                    data={link}
                    onSubmit={handleSubmit}
                />
            </div> : ''}
        </Box>
    )

};


export default BoxLink;
