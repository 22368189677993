import React from 'react';
import {useTranslation} from "react-i18next";

const FooterLinks = () => {
    const {t} = useTranslation();
    return (
        <div className={'flex justify-center md:justify-end'}>
            <a href="/terms-of-use" className={'mr-4 block uppercase transition-colors duration-300 hover:text-primary'}>
                {t('title.termsOfUse')}
            </a>
            <a href="/datapolicy" className={'mr-4 block uppercase transition-colors duration-300 hover:text-primary'}>
                {t('title.policy')}
            </a>
            <a href="/imprint" className={'block uppercase transition-colors duration-300 hover:text-primary'}>
                {t('title.imprint')}
            </a>
        </div>
    );
};

export default FooterLinks;
