import React from 'react';
import IconChevronRight from './icons/IconChevronRight';
import {Link} from "react-router-dom";

const LinkWithArrow = ({ href, text, bold, className = '' }) => {
  const linkClass = bold ? 'font-boldfont text-base ' : 'font-regular text-base ';
  const iconClass = bold ? '' : 'fill-primary';

  const attributes = {
    className: "text-white hover:text-primary transition-colors duration-300 inline-flex items-center space-x-1 " + linkClass + className,
  }
  if(href && href.startsWith('http')) {
    attributes.href = href;
    attributes.rel = 'noreferrer';
    return (
      <a {...attributes}>
        {text} <IconChevronRight className={"ml-1 " + iconClass}/>
      </a>
    );
  }else if(href) {
    attributes.to = href;
    return (
      <Link {...attributes}>
        {text} <IconChevronRight className={"ml-1 " + iconClass}/>
      </Link>
    );
  }
  return null;
};

export default LinkWithArrow;
