import React from "react";
import {useTranslation} from "react-i18next";
import IconTag from "./icons/IconTag";
import IconPencil from "./icons/IconPencil";
import IconKey from "./icons/IconKey";
import IconLink from "./icons/IconLink";
import IconWordPress from "./icons/IconWordPress";
import InfoText from "./parts/InfoText";


const AuthorHome = () => {
    //hooks & constants
    const {t} = useTranslation();

    //render
    return (
        <div>
            <InfoText
                to={'#categories'}
                icon={<IconTag />}
                text={t('author.categories')}
                description={t('author.categoriesDescription')}
            />
            <InfoText
                to={'#writingRules'}
                icon={<IconPencil />}
                text={t('author.writingRules')}
                description={t('author.writingRulesDescription')}
            />
            <InfoText
                to={'#keywords'}
                icon={<IconKey />}
                text={t('author.keywords')}
                description={t('author.keywordsDescription')}
            />
            <InfoText
                to={'#links'}
                icon={<IconLink />}
                text={t('author.links')}
                description={t('author.linksDescription')}
            />
            <InfoText
                to={'#wordpressApi'}
                icon={<IconWordPress />}
                text={t('author.wordpressApi')}
                description={t('author.wordpressApiDescription')}
                border={false}
            />
        </div>
    );
}

export default AuthorHome;
