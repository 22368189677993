import React from 'react';
import classNames from "classnames";

const FlexList = ({ items, className = '', itemClasses = '', keyword= false }) => {
  const itemClassName = keyword ? ' px-2 py-1 text-sm rounded-full inline-block align-top border border-solid bg-opacity-30' : ' flex-grow';
  return (
    <div className={"flex flex-col text-sm space-y-2 " + className}>
      {items.map((item, index) => (
        <div className={"flex items-end " + itemClasses } key={index}>
          <div className="flex-grow text-left self-start">{item.label}:</div>
          <div className={classNames("text-right ", item.className)+ itemClassName}>{item.value}</div>
        </div>
      ))}
    </div>
  );
};

export default FlexList;
