import React from 'react';

const NumberMono = ({ numClassName = '', num = '', bold }) => {
  const fontClassName = bold ? 'font-boldfont text-sm' : 'font-regular text-base';

  return (
    <span className={"font-mono w-8 text-center inline-block " + numClassName + " " + fontClassName}>{num}</span>
  );
};

export default NumberMono;
