import React, {useEffect, useState} from 'react'
import Form from "./Form";
import {useTranslation} from "react-i18next";

const FormTranslated = ({filename,name, ...rest}) => {
    //hooks & constants
    const {i18n} = useTranslation();

    //states
    const [fields, setFields] = useState(false);

    //effects
    useEffect(() => {
        const getFields = async () => {
            const lang = i18n.language[0]+i18n.language[1];
            const langSuffix = lang === 'en' ? '' : '_'+lang;
            const {default: fields} = await import('../../config/forms/'+filename+langSuffix);
            setFields(fields);
        }
        getFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filename, i18n.language]);

    return (fields && <Form key={name+filename+i18n.language[0]+i18n.language[1]}{...rest} fields={fields} />)
}


export default FormTranslated
