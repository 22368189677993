import Section from "../../components/Section";
import IconBubble from "../../components/icons/IconBubble";
import Box from "../../components/Box";
import Button from "../../components/Button";
import IconBars from "../../components/icons/IconBars";
import LinkWithNumber from "../../components/LinkWithNumber";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../utils/Auth";
import {apiGetDomains} from "../../utils/api";
import BoxCreateDomain from "../../components/BoxCreateDomain";
import BoxPublishedArticles from "../../components/BoxPublishedArticles";
import BoxDomain from "../../components/BoxDomain";
import IconProject from "../../components/icons/IconProject";
import IconStar from "../../components/icons/IconStar";
import IconTime from "../../components/icons/IconTime";
import BoxPartner from "../../components/BoxPartner";
import BoxLog from "../../components/BoxLog";

const Dashboard = () => {
    //hooks & constants
    const {t} = useTranslation();
    const {
        user,
        setPageAttributes
    } = useContext(AuthContext);

    //states
    const [domains, setDomains] = useState([])



    //effects
    useEffect(() => {
        setPageAttributes(t('title.dashboard'));
        apiGetDomains().then((response) => {
            if (response.status) {
                setDomains(response.domains)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //methods

    //render
    if(!user) return <></>
    return <div className={"grid gap-5 grid-cols grid-cols-12"}>

            {user.max_domains === 0 ? (
                <Section className={'col-span-12 md:col-span-6 md:col-start-4 mb-12'} icon={<IconBubble/>}
                         title={t('title.welcome')}>
                    <Box>
                    <h2 className={'mb-2 font-boldfont'}>{t('dashboard.welcomeTitle')}</h2>
                    <p>{t('dashboard.welcomeText')}</p>
                        <br/>
                        <Button
                            title={t('payment.newPlans')}
                            onClick={() => {
                                window.location.href = user.payment_plan_url
                            }}
                        />
                    </Box>
                </Section>
            ) : (
                <>
                    <Section className={'col-span-12 md:col-span-5 lg:col-span-4 xl:col-span-3'} icon={<IconBubble/>}
                             title={t('title.welcome')}>
                        <Box className={'bg-secondary'}>
                            <LinkWithNumber num={user.domains_count + '/' + user.max_domains} numClassName="w-8"
                                            href={'/domains/'} text={t('project.projectsCreated')} bold={true}/>
                            <LinkWithNumber num={user.suggestion_count} numClassName="w-8" href={'/articles/?state=suggestion'}
                                            text={t('project.articleSuggestions')} bold={true}/>
                            <LinkWithNumber num={user.outline_count} numClassName="w-8" href={'/articles/?state=outline'}
                                            text={t('project.articleOutlines')} bold={true}/>
                            <LinkWithNumber num={user.unpublished_count} numClassName="w-8" href={'/articles/?state=article'}
                                            text={t('project.articlesUnpublished')} bold={true}/>
                            <LinkWithNumber num={user.published_count} numClassName="w-8" href={'/articles/?state=published'}
                                            text={t('project.articlesPublished')} bold={true}/>
                            <br/>
                            <Button
                                title={t('payment.viewPlans')}
                                onClick={() => {
                                    window.location.href = user.payment_plan_url
                                }}
                            />
                        </Box>
                    </Section>
                    <Section className={'col-span-12 md:col-span-7 lg:col-span-8 xl:col-span-9'} icon={<IconBars/>}
                             title={t('title.publishedArticles')}>
                        <BoxPublishedArticles domains={domains}/>
                    </Section>
                    <Section className={'col-span-12 md:col-span-5 lg:col-span-4 xl:col-span-3'} icon={<IconStar/>} title={t('title.partner')}>
                            <BoxPartner/>
                    </Section>
                    <Section className={'col-span-12 md:col-span-7 lg:col-span-8 xl:col-span-9'} icon={<IconTime/>} title={t('title.log')}>
                        <BoxLog />
                    </Section>
                    <Section className={'col-span-12'} icon={<IconProject/>} title={t('title.activeProjects')}>
                        <div className={'grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'}>
                            {
                                domains.map((domain, index) => (<BoxDomain key={domain.id} domain={domain} index={index}/>))
                            }
                            <BoxCreateDomain/>
                        </div>
                    </Section>
                </>
            )}
    </div>
}


export default Dashboard
