import Section from "../../components/Section";
import Box from "../../components/Box";
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {apiGetAuthors} from "../../utils/api";
import IconLogIn from "../../components/icons/IconLogIn";
import BoxAuthor from "../../components/BoxAuthor";
import {AuthContext} from "../../utils/Auth";

const Authors = () => {
    //hooks & constants
    const {t} = useTranslation();
    const {setPageAttributes} = useContext(AuthContext);

    //states
    const [authors, setAuthors] = useState([])

    //effects
    useEffect(() => {
        setPageAttributes(t('title.authors'));
        apiGetAuthors().then((response) => {
            if (response.status) {
                setAuthors(response.authors)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //methods

    //render
    return authors && <div className={"grid gap-5 grid-cols grid-cols-12"}>
        <Section
            className={'col-span-12'}
            icon={<IconLogIn/>}
            title={t('title.authors')}
        >
            <div className={'grid gap-5 grid-cols grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'}>
                {
                    authors.length > 0 ? (
                        authors.map((author) => (
                            <BoxAuthor
                                domain={author.domain}
                                author={author}
                                isWordPress={author.domain.type === 'wordpress'}
                                key={author.id}
                            />
                        ))
                    ) : (
                        <Box className={'md:min-h-[274px] bg-darker pt-4'} borderClass={'border-secondary border-dashed'}>
                            <div className="flex flex-col items-center justify-center h-full">
                                <div className="">
                                    <h2 className="font-bold">{t('author.noAuthorsTitle')}</h2>
                                    <p className="mt-2 text-sm">{t('author.noAuthorsText')}</p>
                                </div>
                            </div>
                        </Box>
                    )
                }

            </div>
        </Section>
    </div>
}


export default Authors
