import React from 'react';
import NumberMono from './NumberMono';
import LinkWithArrow from './LinkWithArrow';

const LinkWithNumber = ({flex = false, num, numClassName, href, text, bold}) => {
    return (

        <div className={'my-1'}>
            {!flex ?
                <>
                  <NumberMono num={num} numClassName={"mr-2 " + numClassName}/>
                  <LinkWithArrow href={href} text={text} bold={bold}/>
                </>
                :
                <div className={'flex mt-2 justify-between'}>
                  <LinkWithArrow className={"!text-sm"} href={href} text={text} bold={bold}/>
                  <NumberMono num={num} numClassName={numClassName + ' text-right text-sm'}/>
                </div>
            }
        </div>
    );
};

export default LinkWithNumber;
