import React from 'react'
import SectionTitle from "./SectionTitle";

const Section = ({className, children, title, icon}) => {

    const sectionTitle = title ? <SectionTitle icon={icon?icon:null}>{title}</SectionTitle> : null;

    return (
        <div className={className}>
            {sectionTitle}
            {children}
        </div>
    )
}

export default Section
