import AuthProvider from "./utils/Auth";
import Layout from "./containers/Layout";
import {Toaster} from "react-hot-toast";

function App() {
    return (
        <AuthProvider>
            <Layout />
            <Toaster
                toastOptions={{
                    position: 'bottom-right',
                    style: {
                        background: '#F39A37',
                        color: '#fff',
                        border: '1px solid #F8C951',
                        padding: '15px',
                    },
                }}
            />
        </AuthProvider>
    );
}

export default App;
