import React from 'react';

const IconBars = ({className = ''}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={"w-4 h-4 " + className}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.9,3C11.5,3,12,3.5,12,4.1v2.2c0,0.6-0.5,1.1-1.1,1.1H4.1C3.5,7.5,3,7,3,6.4V4.1C3,3.5,3.5,3,4.1,3H10.9z
     	 M15.4,9.8c0.6,0,1.1,0.5,1.1,1.1v2.2c0,0.6-0.5,1.1-1.1,1.1H4.1c-0.6,0-1.1-0.5-1.1-1.1v-2.2c0-0.6,0.5-1.1,1.1-1.1H15.4z
     	 M19.9,16.5c0.6,0,1.1,0.5,1.1,1.1v2.2c0,0.6-0.5,1.1-1.1,1.1H4.1C3.5,21,3,20.5,3,19.9v-2.2c0-0.6,0.5-1.1,1.1-1.1H19.9z"/>
    </svg>
  );
};

export default IconBars;
