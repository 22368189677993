import React, {useState} from 'react';
import IconAI from "../icons/IconAI";
import Modal from "../Modal";
import {t} from "i18next";
import FieldText from "../form/FieldText";
import FieldButton from "../form/FieldButton";
import IconLoading from "../icons/IconLoading";
import {apiPostChangeArticlePart} from "../../utils/api";
import toast from "react-hot-toast";

const ArticleRegenerateModal = ({openModal, setOpenModal, articleID, textID, oldText, onChange}) => {

    //states
    const [instruction, setInstruction] = useState('')
    const [loading, setLoading] = useState(false)
    const [newText, setNewText] = useState('')

    //methods
    const regenerateText = () => {
        setLoading(true)
        apiPostChangeArticlePart({
            old_text: oldText,
            instruction: instruction,
            article_id: articleID
        }).then((response) => {
            toast.success(response.message);
            setNewText(response.new_text);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <button className={'absolute bottom-1 right-1'} onClick={() => {
                setOpenModal(true)
                setNewText('')
                setInstruction('')
            }}><IconAI className={'!w-4 !h-4'}/></button>
            <Modal
                isOpen={openModal}
                setIsOpen={setOpenModal}
                disabled={loading}
                className={'max-h-[calc(100%-4rem)] h-auto'}>
                <h3 className={"font-boldfont text-sm text-white mb-4 uppercase"}>
                    <IconAI className={'inline-block'}/> {t('title.regenerateText')}
                </h3>
                <div className={'overflow-auto pr-4 max-h-[calc(100vh-11rem)]'}>
                    {loading &&
                        <div
                            className={'absolute top-0 left-0 rounded-xl right-0 bottom-0 flex justify-center items-center z-10 bg-dark bg-opacity-70'}>
                            <IconLoading animated="true" className={'text-white !w-16 !h-16'}/>
                        </div>
                    }
                    <>
                        <span className={'text-white block'}>{t('title.currentText')}</span>
                        <div
                            id={textID}
                            className={'grow-1 p-3 w-full text-light bg-secondary rounded relative'}>
                            {oldText}
                        </div>
                        <span className={'text-white mt-4 block'}>{t('title.instructions')}</span>
                        <FieldText
                            className={'text-white mb-6'}
                            field={{type: 'text', name: 'instruction', validator: ''}}
                            error={''}
                            value={instruction}
                            onChange={(fieldName, value) => {
                                setInstruction(value);
                            }}
                        />
                        {newText === '' ?
                            <FieldButton
                                field={{
                                    label: t('title.regenerateText')
                                }}
                                disabled={loading}
                                primary={true}
                                onClick={regenerateText}
                            />

                            :
                            <>
                                <span className={'text-white mt-4 block'}>{t('title.regeneratedText')}</span>
                                <textarea
                                    className={'block w-full h-32 mb-4 bg-darker p-3 text-white !outline-none rounded resize-none  border-secondary focus:ring-0 focus:border-primary focus:ring-offset-0'}
                                    value={newText}
                                    onChange={(e)=>{
                                        setNewText(e.target.value)
                                    }}
                                />
                                <div className={'flex flex-wrap gap-2'}>
                                    <FieldButton
                                        field={{
                                            label: t('button.acceptChanges')
                                        }}
                                        disabled={loading}
                                        primary={true}
                                        onClick={() => {
                                            onChange(newText)
                                            setOpenModal(false)
                                        }}
                                    />
                                    <FieldButton
                                        field={{
                                            label: t('button.discard')
                                        }}
                                        disabled={loading}
                                        primary={false}
                                        onClick={() => {
                                            setOpenModal(false)
                                        }}
                                    />
                                </div>
                            </>
                        }
                    </>
                </div>
            </Modal>
        </>
    )
}

export default ArticleRegenerateModal
