import Box from "./Box";
import {useTranslation} from "react-i18next";
import SectionTitle from "./SectionTitle";
import React, {useContext} from "react";
import IconCheckCircle from "./icons/IconCheckCircle";
import IconLogIn from "./icons/IconLogIn";
import IconStar from "./icons/IconStar";
import {AuthContext} from "../utils/Auth";
import {useNavigate} from "react-router-dom";

const BoxLog = ({}) => {
    //hooks & constants
    const {t, i18n} = useTranslation();
    const {logs} = useContext(AuthContext);
    const navigate = useNavigate();

    //states

    //effects

    //methods
    const renderLogItem = (type, message, date) => {
        let icon = <IconCheckCircle className={"inline-block mb-1"}/>;
        let status = 'success';
        const localDateString = new Date(date).toLocaleDateString(i18n.language, { day: '2-digit', month: '2-digit', year: '2-digit' });
        let link = null;
        switch (type) {
            case 'domain_added':
                icon = <IconLogIn className={"inline-block mb-1"}/>;
                break;
            default:
                break;
        }
        return <li
            onClick={() => {
                //navigate
                if (link) {
                    navigate(link);
                }
            }}
            className={'mt-2 text-' + status+(link?' cursor-pointer':'')}
        >
            {icon}
            <span
                className="font-mono text-center inline-block font-regular mx-2 text-base">{localDateString}</span> – <span
            className={'ml-2'}>{message}</span>
        </li>
    }

    //render
    return <Box className={'bg-darker  md:min-h-[350px]'} borderClass={'border-secondary'}>
        <SectionTitle>{t('log.infoTitle')}</SectionTitle>
        <div className={'relative h-[270px] overflow-y-auto'}>
            <ul>
                {logs.map((log, index) => (
                    renderLogItem(log.type, log.message, log.created_at)
                ))}
            </ul>
        </div>
    </Box>

};


export default BoxLog;
