import React from 'react'

const FieldToggle = ({field, value, onChange}) => {
    return (
        <div className="toggler py-1">
            <input id={field.name} name={field.name} type="checkbox" checked={value} className={'hidden peer'}
                   onChange={() => {
                       onChange(field.name, !value);
                   }}/>
            <label htmlFor={field.name}
                   className={'block relative before:border before:border-secondary before:rounded-xl before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-14 before:h-6 peer-checked:after:bg-success cursor-pointer after:absolute after:top-1/2 after:left-3 after:w-5 after:h-5 after:-translate-y-1/2 after:-translate-x-1/2 after:transition-all after:ease-in-out after:content-[""] after:block after:rounded-full after:bg-gray-500'}>
                <svg className="toggler-on opacity-100 z-10 absolute top-1/2 left-3 w-5 h-5 -translate-y-1/2 -translate-x-1/2 transition-all ease-in-out" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 130.2 130.2">
                    <polyline className="path check" points="100.2,40.2 51.5,88.8 29.8,67.5" fill="none" stroke="#fff" strokeWidth="7px" strokeLinecap="round" strokeMiterlimit="10"></polyline>
                </svg>
                <svg className="toggler-off opacity-100 z-10 absolute top-1/2 left-3 w-5 h-5 -translate-y-1/2 -translate-x-1/2 transition-all ease-in-out" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 130.2 130.2">
                    <line className="path line" x1="34.4" y1="34.4" x2="95.8" y2="95.8" fill="none" stroke="#fff" strokeWidth="7px" strokeLinecap="round" strokeMiterlimit="10"></line>
                    <line className="path line" x1="95.8" y1="34.4" x2="34.4" y2="95.8" fill="none" stroke="#fff" strokeWidth="7px" strokeLinecap="round" strokeMiterlimit="10"></line>
                </svg>
                <span dangerouslySetInnerHTML={{__html: field.label}} className={'pl-16 block'}/>
            </label>
        </div>
    )
}
export default FieldToggle
