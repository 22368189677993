import React from 'react'
import classnames from "classnames";

const FieldOptions = ({ field, value: fieldValue, onChange, error}) => {
    const renderOptions = values =>
        values.map((value)=>
            <label className="radio-inline" key={value.value}>
                <input
                    type="radio"
                    onChange={(e) => {
                        onChange(field.name, e.target.value)
                    }}
                    name={field.name}
                    id={field.name+'_'+value.value}
                    value={value.value}
                    checked={(fieldValue==='' && field.value)?value.value===field.value:value.value===fieldValue}
                /> {value.label}
            </label>
        )

    return (
        <div className={classnames("form-group",{'text-danger':error!==''})}>
            <label htmlFor={field.name} className={'control-label'}>{field.label}</label>
            {renderOptions(field.values)}
            {field.suffix && <small id={field.name + 'Help'} className="block">{field.suffix}</small>}
            {error!=='' && <small className="form-text text-danger block">{error}</small>}
        </div>
    )
}

export default FieldOptions
