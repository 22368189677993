import Section from "../../components/Section";
import Box from "../../components/Box";
import LinkWithNumber from "../../components/LinkWithNumber";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {apiDeleteDomain, apiGetDomain, apiPutDomain} from "../../utils/api";
import FavIcon from "../../components/parts/FavIcon";
import {Link, useNavigate, useParams} from "react-router-dom";
import BoxCategory from "../../components/BoxCategory";
import BoxCreateCategory from "../../components/BoxCreateCategory";
import BoxRefreshCategories from "../../components/BoxRefreshCategories";
import FlexList from '../../components/parts/FlexList';
import IconProject from "../../components/icons/IconProject";
import IconLogIn from "../../components/icons/IconLogIn";
import IconTag from "../../components/icons/IconTag";
import BoxAuthor from "../../components/BoxAuthor";
import BoxCreateAuthor from "../../components/BoxCreateAuthor";
import {AuthContext} from "../../utils/Auth";
import Tabs from "../../components/Tabs";
import FormTranslated from "../../components/form/FormTranslated";
import toast from "react-hot-toast";
import IconTrash from "../../components/icons/IconTrash";
import DomainHome from "../../components/DomainHome";
import InfoText from "../../components/parts/InfoText";
import IconLightBulb from "../../components/icons/IconLightBulb";

const Domain = () => {
    //hooks & constants
    const {t} = useTranslation();
    const {id} = useParams();
    const {setPageAttributes} = useContext(AuthContext);
    const navigate = useNavigate();

    //states
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [domain, setDomain] = useState(null)

    //effects
    useEffect(() => {
        setPageAttributes(t('title.project'), [{title: t('title.projects'), href: '/domains'}]);
        apiGetDomain(id).then((response) => {
            if (response.status) {
                setDomain(response.domain)
                setPageAttributes(response.domain.domain_name, [{title: t('title.projects'), href: '/domains'}]);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    //methods
    const deleteDomain = () => {
        if (window.confirm(t('project.deleteConfirm'))) {
            setLoading(true);
            apiDeleteDomain(domain.id)
                .then((response) => {
                        if (response.status) {
                            toast(response.message);
                            navigate('/domains/');
                        } else {
                            toast(response.message);
                            setLoading(false);
                        }
                    }
                );
        } else {
            setLoading(false);
        }
    }

    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPutDomain(domain.id, validated)
            .then((response) => {
                if (response.status) {
                    setEdit(false);
                    setDomain({...domain, ...response.domain});
                    toast(response.message);
                } else {
                    formValidator.responseAdapter(response);
                    formValidator.validateAll();
                }
            }).finally(() => {
            setLoading(false);
        });
    }

    const tabs = () => {
        return {
            info: {
                title: <span className={'lg:hidden'}>{t('title.home')}</span>,
                icon: <IconLightBulb className={'lg:mr-[-0.4rem]'}/>,
                content: <DomainHome/>
            },
            categories: {
                title: t('title.categories'),
                icon: <IconTag/>,
                content:
                    <div className={''}>
                        <div className={'space-y-2 pb-4'}>
                            <InfoText
                                description={t('project.categoriesDescription')}
                                border={false}
                            />
                            {domain.categories.map((category, index) => {
                                return <div key={category.id} className={''}>
                                    <BoxCategory
                                        category={category}
                                        isWordPress={domain.type === 'wordpress'}
                                        onChange={(category) => {
                                            if (!category) {
                                                //is deleted
                                                const categories = [...domain.categories];
                                                categories.splice(index, 1);
                                                setDomain({...domain, categories})
                                            } else {
                                                //is edited
                                                const categories = [...domain.categories];
                                                categories[index] = category;
                                                setDomain({...domain, categories})
                                            }
                                        }}
                                    />
                                </div>
                            })}
                        </div>
                        <div className={'grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2'}>
                            <BoxRefreshCategories
                                domainId={domain.id}
                                isWordPress={domain.type === 'wordpress'}
                                onChange={(categories) => {
                                    setDomain({...domain, categories})
                                }}
                            />
                            <BoxCreateCategory
                                domainId={domain.id}
                                onChange={(category) => {
                                    const categories = [...domain.categories];
                                    categories.push(category);
                                    setDomain({...domain, categories})
                                }}
                            />
                        </div>
                    </div>
            },
            authors: {
                title: t('title.authors'),
                icon: <IconLogIn/>,
                content:
                <>
                    <InfoText
                        description={t('project.authorsDescription')}
                        border={false}
                    />
                    <div className={'grid gap-5 grid-cols grid-cols-2'}>
                        {
                            domain.authors.length > 0 && domain.authors.map((author) => (
                                <div key={author.id} className={'col-span-2 sm:col-span-1 lg:col-span-2 xl:col-span-1'}>
                                    <BoxAuthor author={author} isWordPress={domain.type === 'wordpress'}/>
                                </div>
                            ))
                        }
                        {domain.authors.length < 5 && <div className={'!col-start-1 col-span-2 sm:col-span-1 lg:col-span-2 xl:col-span-1'}>
                            <BoxCreateAuthor
                                domainId={domain.id}
                                onChange={(author) => {
                                    const authors = [...domain.authors];
                                    authors.push(author);
                                    setDomain({...domain, authors})
                                }}
                            />
                        </div>}
                    </div>
                </>
            }


        };
    }


    //render
    let domainData = domain && {
        url: domain.url,
        type: domain.type,
        autoapprove_suggestions: domain.autoapprove_suggestions?'1':'0',
        autoapprove_outlines: domain.autoapprove_outlines?'1':'0',
        autoapprove_articles: domain.autoapprove_articles?'1':'0',
        publish_type: domain.publish_type,
        image_ratio: domain.image_ratio,
    }
    return domain && <div className={"grid gap-5 grid-cols grid-cols-12"}>
        <Section className={'col-span-12 lg:col-span-4 xl:col-span-3'} icon={<IconProject/>}
                 title={t('title.projectInfo')}>
            <Box className={'bg-secondary col-span-4'} icon={true} iconState={edit} iconFunction={() => setEdit(!edit)}>
                {!edit ? <>
                    <div className={"text-center"}>
                        <FavIcon url={domain.favicon}/><br/>
                    </div>
                    {/*<pre>{JSON.stringify(domain, null, 2)}</pre>*/}

                    <FlexList
                        items={[
                            {label: t('project.domain'), value: domain.domain_name},
                            {label: t('project.type'), value: t('project.types.' + domain.type)},
                            {label: t('title.authors'), value: <Link to={'#authors'} className={'transition-colors duration-300 hover:text-primary font-mono'}>{domain.authors.length}</Link>, className: (domain.authors.length === 0 ? 'text-danger' : 'text-white')},
                            {label: t('title.categories'), value: <Link to={"#categories"} className={'transition-colors duration-300 hover:text-primary font-mono'}>{domain.categories.length}</Link>, className: (domain.categories.length === 0 ? 'text-danger' : 'text-white')},
                            {
                                label: t('project.autoApproveSuggestions'),
                                value: domain.autoapprove_suggestions ? t('project.acceptAuto') : t('project.acceptManual')
                            },
                            {
                                label: t('project.autoApproveOutlines'),
                                value: domain.autoapprove_outlines ? t('project.acceptAuto') : t('project.acceptManual')
                            },
                            {
                                label: t('project.autoApproveArticles'),
                                value: domain.autoapprove_articles ? t('project.acceptAuto') : t('project.acceptManual')
                            },
                            {
                                label: t('project.publishType'),
                                value: t('project.publishType-'+domain.publish_type)
                            },
                            {label: t('project.imageRatio'), value: domain.image_ratio},


                        ]}
                    />
                    <hr className="border-dark mt-8 ml-[calc(-1.5rem-1px)] mr-[calc(-1.5rem-1px)]"/>
                    <br/>


                    <LinkWithNumber flex={true} num={domain.suggestion_count} numClassName="w-8"
                                    href={'/articles/?domain_id=' + domain.id + '&state=suggestion'}
                                    text={t('project.articleSuggestions')}/>
                    <LinkWithNumber flex={true} num={domain.outline_count} numClassName="w-8"
                                    href={'/articles/?domain_id=' + domain.id + '&state=outline'}
                                    text={t('project.articleOutlines')}/>
                    <LinkWithNumber flex={true} num={domain.unpublished_count} numClassName="w-8"
                                    href={'/articles/?domain_id=' + domain.id + '&state=article'}
                                    text={t('project.articlesUnpublished')}/>
                    <LinkWithNumber flex={true} num={domain.published_count} numClassName="w-8"
                                    href={'/articles/?domain_id=' + domain.id + '&state=published'}
                                    text={t('project.articlesPublished')}/>
                    <LinkWithNumber flex={true} num={domain.media_count} numClassName="w-8"
                                    href={'/media/?domain_id=' + domain.id}
                                    text={t('project.media')}/>
                </> : <div className={"mt-6"}>
                    <button className="absolute top-3 right-12 text-white" onClick={deleteDomain}><IconTrash/></button>
                    <FormTranslated
                        loading={loading}
                        name={'editDomain'}
                        filename={'editDomain'}
                        data={domainData}
                        onSubmit={handleSubmit}
                    />
                </div>}
            </Box>
        </Section>

        <div className={'col-span-12 lg:col-span-8 xl:col-span-9 pt-7'}>
            <Tabs initialActiveItem={'info'} items={tabs()}/>
        </div>
    </div>
}


export default Domain
