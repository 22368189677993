import React, {useState} from 'react';
import FieldSelect from "../form/FieldSelect";
import ArticleRegenerateModal from "./ArticleRegenerateModal";
import {apiPostChangeArticlePart} from "../../utils/api";
import toast from "react-hot-toast";

const ArticleElement = ({
                            id,
                            multiline,
                            type,
                            articleID,
                            value,
                            highlight = [],
                            onChange,
                            onDelete = () => {
                            },
                            onSplit = () => {
                            },
                            onCombine = () => {
                            }
                        }) => {
    //hooks and context
    const ref = React.createRef();
    const TAGTYPES = {
        'h1': {selectable: false, classes: "text-2xl", placeholder: "h1 - Heading 1"},
        'h2': {selectable: false, classes: "text-xl", placeholder: "h2 - Heading 2"},
        'p': {selectable: true, classes: "", placeholder: "p - Paragraph"},
        'b': {selectable: true, classes: "font-semibold", placeholder: "b - Bold"},
        'i': {selectable: true, classes: "italic", placeholder: "i - Italic"},
        'span': {selectable: true, classes: "", placeholder: "span - Span"},
        'div': {selectable: true, classes: "", placeholder: "div - Div"},
    };

    //states
    const [selectionStart, setSelectionStart] = useState(0);
    const [openModal, setOpenModal] = useState(false)

    //effects
    React.useEffect(() => {
        if (ref.current) {
            ref.current.style.height = 'auto';
            ref.current.style.height = ref.current.scrollHeight + 'px';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);


    let highlightedValue = value;

    highlight.forEach((highlightItem) => {
        highlightedValue = highlightedValue.replaceAll(highlightItem.highlight, `<span class="${highlightItem.className}">${highlightItem.highlight}</span>`);
    });

    const typeSwitcher = (type) => {
        if (type === 'h1' || type === 'h2') {
            return <div className={'text-secondary py-3 w-12 self-center'}>{type}</div>
        } else {
            return <div className={'py-3 w-10 mr-2 self-center'}>
                <FieldSelect
                    className={'!mb-0'}
                    selectClassName={'text-secondary bg-white border-none bg-none p-0 cursor-pointer'}
                    field={{
                        name: 'element-type',
                        validator: '',
                        values: Object.keys(TAGTYPES)
                            .filter((key) => {
                                return TAGTYPES[key].selectable;
                            })
                            .map((key) => {
                                return {value: key, label: type === key ? key : TAGTYPES[key].placeholder};
                            })
                    }}
                    value={type}
                    onChange={(name, newValue) => {
                        onChange(value, newValue)
                    }}
                />
            </div>
        }
    }

    //render
    return (
        <div
            className="flex mb-2">
            {typeSwitcher(type)}
            <div
                id={id}
                className={'grow-1 p-0 w-full border border-dashed border-sky-500 relative'}>
                <div
                    className={'whitespace-pre-wrap pointer-events-none absolute w-[calc(100%-2rem)] m-3  p-0 border-none min-h-20 text-transparent ' + TAGTYPES[type].classes}
                    dangerouslySetInnerHTML={{__html: highlightedValue}}
                ></div>
                <textarea
                    //tailwind classes: no padding, no outline, no resize, no scrollbar min-height:20px
                    className={'block w-[calc(100%-2rem)] m-3 p-0 !outline-none resize-none overflow-hidden border-none focus:ring-0 min-h-20 right-0 ' + TAGTYPES[type].classes}
                    rows="1"
                    ref={ref}
                    value={value}
                    onChange={(e) => {
                        onChange(e.target.value, type)
                        setSelectionStart(e.target.selectionStart);
                    }}
                    onKeyUp={(e) => {
                        if (!multiline) {
                            //Can not be separated or deleted
                            return false;
                        }
                        //on backspace or delete delete the element
                        if ((!value || value === '') && (e.keyCode === 8 || e.keyCode === 46)) {
                            e.preventDefault();
                            onDelete();
                        } else if (e.keyCode === 8 && selectionStart === 0) {
                            //on backspace combine with element before if exists
                            e.preventDefault();
                            onCombine();
                        } else if (e.keyCode === 13 && !e.shiftKey) {
                            //on return split the text at current cursor position without shift
                            e.preventDefault();
                            onSplit(selectionStart);
                        }
                    }
                    }
                />
                {type !== 'h1' && type !== 'h2' &&
                    <ArticleRegenerateModal openModal={openModal} setOpenModal={setOpenModal} articleID={articleID} textID={id} oldText={value} onChange={(newText) => {
                        onChange(newText, type)
                    }}/>
                }
            </div>
        </div>
    );
};

export default ArticleElement;
