import Box from "./Box";
import React, {useContext, useState} from "react";
import {apiPostDomain} from "../utils/api";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../utils/Auth";
import {useTranslation} from "react-i18next";
import Button from "./Button";
import SectionTitle from "./SectionTitle";
import IconPlus from "./icons/IconPlus";
import FormTranslated from "../components/form/FormTranslated";

const BoxCreateDomain = () => {
    ///hooks & constants
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const {t} = useTranslation();

    //states
    const [loading, setLoading] = useState(false);


    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPostDomain(validated.url).then((response) => {
            if (response.status) {
                navigate('/domains/' + response.domain.id);
            } else {
                formValidator.responseAdapter(response);
                formValidator.validateAll();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //render
    if(user.max_domains-user.domains_count<=0){
        return <Box className={'md:min-h-[274px] bg-darker pt-4'} borderClass={'border-secondary border-dashed'}>
            <SectionTitle icon={<IconPlus/>}>{t('title.createProject')}</SectionTitle>
            <div className="flex flex-col items-center justify-center h-full">
                <div className="">
                    {user.max_domains === 0 ? (
                        <>
                            <h2 className="font-bold">{t('payment.newUserTitle')}</h2>
                            <p className="mt-2 text-sm">{t('payment.newUserText')}</p>
                        </>
                    ) : (
                        <>
                            <h2 className="font-bold">{t('payment.maxReachedTitle')}</h2>
                            <p className="mt-2 text-sm">{t('payment.maxReachedText').replace('{max_domains}', user.max_domains)}</p>                       </>
                    )}
                </div>
                <div className="mt-6">
                    <Button
                        title={user.max_domains === 0 ? t('payment.viewPlans') : t('payment.upgradePlan')}
                        onClick={() => {
                            window.location.href = user.payment_plan_url
                        }}
                    />
                </div>
            </div>
        </Box>
    }
    return <Box className={'bg-darker  md:min-h-[274px]'} borderClass={'border-secondary border-dashed'}>
        <SectionTitle icon={<IconPlus/>}>{t('title.createProject')}</SectionTitle>
        <FormTranslated
            loading={loading}
            name={'newDomain'}
            filename={'newDomain'}
            onSubmit={handleSubmit}
        />
    </Box>

};


export default BoxCreateDomain;
