import Box from "./Box";
import FlexList from "./parts/FlexList";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {translateLoginState} from "../utils/utils";
const BoxAuthor = ({domain, isWordPress, author}) => {
    const {t} = useTranslation();
    const authorInfos = () => {
        let infoArray = [
            {label: t('author.type'), value: t('author.' + author.type)},
            {label: t('author.categories'), value: <span className={'font-mono'}>{author.categories.length}</span>, className: (author.categories.length === 0 ? 'text-danger' : '')},
            {
                label: t('author.writingRules'),
                value: (!author.writing_rules || Object.values(author.writing_rules).some(value => !value)) ? t('globals.incomplete') : t('globals.complete'),
                className: ((!author.writing_rules || Object.values(author.writing_rules).some(value => !value)) ? 'text-danger' : '')
            },
        ];
        if(domain){
            infoArray.splice(0, 0, {label: t('author.project'), value: <Link className="text-white transition-colors duration-300 hover:text-primary" to={"/domains/" + author.domain_id}>{author.domain.domain_name.replace('www.', '')}</Link>});

        }
        if (isWordPress) {
            infoArray.push({
                label: t('author.wordpressApi'),
                value: translateLoginState(author.wp_login_state),
                className: ((!author.wp_login_state  || author.wp_login_state !== 'loggedin') ? 'text-danger' : '')
            });
        }

        return infoArray;
    }

    return <Box icon={true} iconLink={"/authors/" + author.id} className={"!p-3 bg-dark"} key={author.id}>
        <Link to={"/authors/" + author.id} className={"block text-white transition-colors duration-300 hover:text-primary  mr-[4.4rem] relative before:content-[''] before:block before:ml-[-0.75rem] mb-3 before:mr-[-5.2rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary"}><h2 className={"pb-3 pr-3"}>{author.name}</h2></Link>
        <FlexList
            items={authorInfos()}
        />
    </Box>

};


export default BoxAuthor;
