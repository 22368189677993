import React from "react";
import {useTranslation} from "react-i18next";
import IconTag from "./icons/IconTag";
import InfoText from "./parts/InfoText";

const AuthorHome = () => {
    //hooks & constants
    const {t} = useTranslation();

    //render
    return (
        <>
            <InfoText
                to={'#categories'}
                icon={<IconTag />}
                steptext={t('project.step') + ' 1: '}
                text={t('project.categoriesDescriptionTitle')}
                description={t('project.categoriesStep')}
            />
            <InfoText
                to={'#authors'}
                icon={<IconTag />}
                steptext={t('project.step') + ' 2: '}
                text={t('project.authorsDescriptionTitle')}
                description={t('project.authorsStep')}
                border={false}
            />
        </>
    )
}

export default AuthorHome;
