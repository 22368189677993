import Box from "./Box";
import React, {useState} from "react";
import {apiPostDomainCategory} from "../utils/api";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import SectionTitle from "./SectionTitle";
import IconPlus from "./icons/IconPlus";
import FormTranslated from "../components/form/FormTranslated";

const BoxCreateCategory = ({domainId, onChange=null}) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const [uniqueIndex, setUniqueIndex] = useState(0);

    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPostDomainCategory(domainId, validated.title, validated.external_id)
            .then((response) => {
                if (response.status) {
                    if(onChange) {
                        onChange(response.category);
                    }
                    setUniqueIndex(uniqueIndex+1);
                    toast(response.message);
                } else {
                    formValidator.responseAdapter(response);
                    formValidator.validateAll();
                }
            }).finally(() => {
            setLoading(false);
        });
    }

    return <Box className={'bg-darker pt-4'} borderClass={'border-secondary border-dashed'}>
            <SectionTitle icon={<IconPlus/>}>{t('title.createCategory')}</SectionTitle>
            <FormTranslated
                loading={loading}
                name={'createCategory'+uniqueIndex}
                filename={'createCategory'}
                onSubmit={handleSubmit}
            />
    </Box>

};


export default BoxCreateCategory;
