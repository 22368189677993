import Box from "./Box";
import {useState} from "react";
import {apiDeleteDomainCategory, apiPutDomainCategory} from "../utils/api";
import IconTrash from "./icons/IconTrash";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import IconWordPress from "./icons/IconWordPress";
import classNames from "classnames";
import FormTranslated from "../components/form/FormTranslated";

const BoxCategory = ({isWordPress, editable = true, category, onChange = null}) => {
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const {t} = useTranslation();

    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPutDomainCategory(category.domain_id, category.id, validated.title, validated.external_id)
            .then((response) => {
                if (response.status) {
                    if (onChange) {
                        onChange(response.category);
                    }
                    setEdit(false);
                    toast(response.message);
                } else {
                    formValidator.responseAdapter(response);
                    formValidator.validateAll();
                }
            }).finally(() => {
            setLoading(false);
        });
    }

    const onDelete = () => {
        if (window.confirm(t('category.deleteConfirm'))) {
            setLoading(true);
            apiDeleteDomainCategory(category.domain_id, category.id)
                .then((response) => {
                    if (onChange) {
                        onChange(null);
                    }
                    if (response.status) {
                        toast(response.message);
                    } else {
                        toast(response.message);
                        setLoading(false);
                    }
                }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }

    let icon = '';
    if (isWordPress) {
        icon = <IconWordPress
            className={classNames('inline-block align-middle mr-2', category.found ? 'text-success' : 'text-danger')}/>;
    }

    return (
        <Box className={'!p-3 h-full bg-dark'} icon={editable} iconFunction={() => setEdit(!edit)} iconState={edit}>
            {editable && <button className="absolute top-3 right-12 text-white" onClick={onDelete}><IconTrash/>
            </button>}
            <h2 className={"pr-3 mr-14"}>
                {icon}<span className={'inline-block align-middle'}>{category.title} </span> <span
                className={'inline-block align-middle text-xs'}>(ID: <span className={'font-mono'}>{category.external_id}</span>)</span>
            </h2>
            {edit ? <div className={'pt-4'}>
                <FormTranslated
                    loading={loading}
                    name={'editCategory'}
                    filename={'editCategory'}
                    data={category}
                    onSubmit={handleSubmit}
                />
            </div> : ''}
        </Box>
    )

};


export default BoxCategory;
