import {Link} from "react-router-dom";

const Breadcrumb = ({ breadcrumbItems=[] }) => {
    return (
        <div className="flex items-center uppercase mb-6">
            <BreadcrumbItem title="Dashboard" href={'/'} lastone={breadcrumbItems.length===0}/>
            {breadcrumbItems.map((item, index) => {
                    return <BreadcrumbItem
                        key={index}
                        {...item}
                        lastone={index === breadcrumbItems.length-1}
                    />
                })}
        </div>)
}

const BreadcrumbItem = ({ href=null, title, lastone= false }) => {
    if(href){
        return (
            <Link className={"inline-flex items-center"+(lastone?" font-boldfont":'')} to={href}>
                {title}
                {!lastone && <span className={'mx-1'}>/</span>}
            </Link>
        )
    }
    return (
        <span className={"inline-flex items-center"+(lastone?" font-boldfont":'')}>
            {title}
            {!lastone && <span className={'mx-1'}>/</span>}
        </span>
    )


}


export default Breadcrumb
