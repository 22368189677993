import {createBrowserHistory} from 'history'
import {useState, useEffect, useRef} from 'react';
import {t} from "i18next";
import {format} from "date-fns";

export default function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {ref, isComponentVisible, setIsComponentVisible};
}

export const getLoginToken = () => {
    return localStorage.getItem("loginToken");
}

export const deleteLoginToken = () => {
    localStorage.removeItem("loginToken");
}

export const setLoginToken = (token) => {
    localStorage.setItem("loginToken", token);
}
export const createDateObj = (date = false) => {
    //iphonefix
    if (date === false) {
        return new Date();
    } else if (typeof date === 'number') {
        return new Date(date);
    } else if (typeof date === 'string' && date.indexOf('T') > 0) {
        return new Date(date);
    } else if (typeof date === 'string' && date.indexOf('-') > 0) {
        return new Date(date.replace(/-/g, "/"));
    } else if (typeof date === 'string') {
        return new Date(date);
    } else if (typeof date === 'object') {
        return date;
    } else {
        console.log(typeof date, date);
    }
}

export const history = createBrowserHistory({
    forceRefresh: true
})

export const formatterCurrency = (val) => {
    if (val < 100 && val > -100 && val !== 0) {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(val) + " Cent";
    }
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currencyDisplay: 'code',
        style: 'currency',
        currency: 'EUR'
    }).format(val / 100).replace(',00', '');
}

export const formatterDecimals = (val, lang) => {
    return new Intl.NumberFormat(lang, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(val);
}

export const formatterDate = (val) => {
    return format(createDateObj(val), t('date.format'));
}

export const IBANCheck = (input) => {
    function mod97(string) {
        let checksum = string.slice(0, 2), fragment;
        for (let offset = 2; offset < string.length; offset += 7) {
            fragment = String(checksum) + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    }

    const CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
        SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };
    let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
        code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
        digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return 1 === mod97(digits);
}

export const translateLoginState = (loginState) => {
    switch (loginState) {
        case "none":
            return t('globals.notConnected');
        case "loggedin":
            return t('globals.connected');
        case "failed":
            return t('globals.failed');
        case "pending":
            return t('globals.pending');
        default:
            return 'todo';
    }
}


export const domainBorderColors = () => {
    return [
        'border-project-1',
        'border-project-2',
        'border-project-3',
        'border-project-4',
        'border-project-5',
        'border-project-6',
        'border-project-7',
        'border-project-8',
        'border-project-9',
        'border-project-10',
        'border-project-11',
        'border-project-12',
        'border-project-13',
        'border-project-14',
        'border-project-15',
    ];
}

export const domainBackgroundColors = () => {
    return [
        'bg-project-1',
        'bg-project-2',
        'bg-project-3',
        'bg-project-4',
        'bg-project-5',
        'bg-project-6',
        'bg-project-7',
        'bg-project-8',
        'bg-project-9',
        'bg-project-10',
        'bg-project-11',
        'bg-project-12',
        'bg-project-13',
        'bg-project-14',
        'bg-project-15',
    ];
}

export const ARTICLE_STATES = {
    START: "start",
    SUGGESTION: "suggestion",
    SUGGESTION_DENIED: "suggestion_denied",
    SUGGESTION_ACCEPTED: "suggestion_accepted",
    OUTLINE_GENERATING: "outline_generating",
    OUTLINE: "outline",
    OUTLINE_DENIED: "outline_denied",
    OUTLINE_ACCEPTED: "outline_accepted",
    ARTICLE_GENERATING: "article_generating",
    ARTICLE: "article",
    ARTICLE_DENIED: "article_denied",
    WAITING_FOR_PUBLISH: "waiting_for_publish",
    PUBLISHING: "publishing",
    PUBLISHED: "published",
}

export const useInterval = (callback, delay) => {
    const intervalRef = useRef();
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback]);

    useEffect(() => {
        if (typeof delay === 'number') {
            intervalRef.current = window.setInterval(() => callbackRef.current(), delay);
            return () => window.clearInterval(intervalRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay]);
    return intervalRef;
}

export const wordCounter = (text, searchword = "*") => {
    if (searchword !== "*") {
        //find all occurences of searchword in sentence
        const regex = new RegExp(searchword, 'gi');
        return (text.match(regex) || []).length;
    }
    return text.trim().split(/\s+/).length;
}

