import React from 'react'
import classnames from "classnames";

const FieldCurrency = ({ field, value, onChange, error }) =>
    <div className="form-group">
        <label htmlFor={field.name}>{field.label}</label>
        <div className={classnames("currency","form-group",{'text-danger':error!==''})} style={{ display: 'table' }}>
            <span className="input-group-addon">€</span>
            <input
                type={field.type}
                className="form-control"
                id={field.name}
                name={field.name}
                value={value}
                onChange={(e)=>{
                    const name = e.target.name;
                    const value = e.target.value;
                    if (value === '' || /^[0-9\b]+$/.test(value)) {
                        onChange(name, value)
                    }else{
                        onChange(name, '')
                    }
                }}
                aria-describedby={field.name + 'Help'}
                placeholder={field.placeholder}
            />
            <span className="input-group-addon">,00</span>
        </div>
        {field.suffix && <small id={field.name + 'Help'} className="form-text text-muted">{field.suffix}</small>}
        {error!=='' && <small className="form-text text-danger">{error}</small>}
    </div>

export default FieldCurrency


