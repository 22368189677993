import Navigation from "../components/navigation/Navigation";
import Breadcrumb from "../components/Breadcrumb";
import MainTitle from "../components/MainTitle";
import Dashboard from "./pages/Dashboard";
import {AuthContext} from "../utils/Auth";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {useContext} from "react";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Page404 from "./pages/Page404";
import Register from "./pages/Register";
import {useTranslation} from "react-i18next";
import ForgotPassword from "./pages/ForgotPassword";
import Authors from "./pages/Authors";
import Domains from "./pages/Domains";
import Domain from "./pages/Domain";
import Author from "./pages/Author";
import FooterLinks from "../components/navigation/FooterLinks";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import Images from "./pages/Images";
import Imprint from "./pages/Imprint";
import DataPolicy from "./pages/DataPolicy";
import Profile from "./pages/Profile";
import {Tooltip} from "react-tooltip";
import TermsOfUse from "./pages/TermsOfUse";
import Terms from "./pages/Terms";


const Layout = () => {
    //hooks
    const {t} = useTranslation();
    window.$routePrefix = ''
    const {
        isLoggedIn,
        user,
        pageTitle,
        breadcrumbItems
    } = useContext(AuthContext);

    //render
    return (<div className="App bg-dark text-light max-lg:mt-[76px]">
        <BrowserRouter>
            <Navigation/>
            <header className="pt-6 max-w-7xl mx-auto px-4 lg:px-8">
                {isLoggedIn() && <>
                    {user && !user.tos_update_needed &&
                        <>
                            {(window.location.pathname !== '/' ?
                                <Breadcrumb breadcrumbItems={breadcrumbItems}></Breadcrumb> : '')}
                            <MainTitle>{pageTitle}</MainTitle>
                        </>
                    }
                </>
                }
            </header>
            <main className="py-6 max-w-7xl mx-auto px-4 lg:px-8 min-h-[calc(100vh-274px)]">
                {isLoggedIn() ?
                    <>
                        {user && !user.tos_update_needed ?
                            <Routes>
                                <Route path={window.$routePrefix + "/"} element={<Dashboard/>}/>
                                <Route path={window.$routePrefix + "/domains"} element={<Domains/>}/>
                                <Route path={window.$routePrefix + "/domains/:id"} element={<Domain/>}/>
                                <Route path={window.$routePrefix + "/authors"} element={<Authors/>}/>
                                <Route path={window.$routePrefix + "/authors/:id"} element={<Author/>}/>
                                <Route path={window.$routePrefix + "/articles"} element={<Articles/>}/>
                                <Route path={window.$routePrefix + "/articles/:id"} element={<Article/>}/>
                                <Route path={window.$routePrefix + "/images/"} element={<Images/>}/>
                                <Route path={window.$routePrefix + "/imprint"} element={<Imprint/>}/>
                                <Route path={window.$routePrefix + "/datapolicy"} element={<DataPolicy/>}/>
                                <Route path={window.$routePrefix + "/terms-of-use"} element={<TermsOfUse/>}/>
                                <Route path={window.$routePrefix + "/profile"} element={<Profile/>}/>
                                <Route path="*" element={<Page404/>}/>
                            </Routes> :
                            <Routes>
                                <Route path="*" element={<Terms/>}/>
                            </Routes>
                        }
                    </> :
                    <Routes>
                        <Route path={window.$routePrefix + "/reset-password/:token"} element={<ResetPassword/>}/>
                        <Route path={window.$routePrefix + "/forgot-password"} element={<ForgotPassword/>}/>
                        <Route path={window.$routePrefix + "/login"} element={<Login/>}/>
                        <Route path={window.$routePrefix + "/"} element={<Login/>}/>
                        <Route path={window.$routePrefix + "/register"} element={<Register/>}/>
                        <Route path={window.$routePrefix + "/imprint"} element={<Imprint/>}/>
                        <Route path={window.$routePrefix + "/datapolicy"} element={<DataPolicy/>}/>
                        <Route path={window.$routePrefix + "/terms-of-use"} element={<TermsOfUse/>}/>
                        <Route path={window.$routePrefix + "/terms"} element={<Terms/>}/>
                        <Route path="*" element={<Page404/>}/>
                    </Routes>
                }
                <Tooltip id="react-tooltip" className={'text-sm'}/>
            </main>
            <footer className="">
                <div
                    className={'grid grid-cols-12 gap-4 border-solid bg-dark border-secondary border-t text-sm py-4 max-w-screen-2xl px-4 mx-auto align-center'}>
                    <div className={'col-span-12 md:col-span-6 text-center md:text-left'}>
                        <p>{t('footer.text')}</p>
                    </div>
                    <div className={'col-span-12 md:col-span-6'}>
                        <FooterLinks/>
                    </div>
                </div>
            </footer>
        </BrowserRouter>
    </div>)
}

export default Layout
