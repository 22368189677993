import React from 'react'
import classnames from 'classnames'
import InfoBox from "../InfoBox";

const FieldText = ({ field, value, onChange, error }) => {
    if(typeof(error)==='function') {
        error = error()
    }

    return (
        <div className={classnames("form-group",{'text-danger':error!==''})}>
            <label className={'block pb-1'} htmlFor={field.name}>
                {field.label}
                {field.suffix && <InfoBox content={field.suffix}/>}
            </label>
            <textarea className={classnames("form-control",{'text-danger':error!==''})}
                      value={value}
                      id={field.name}
                      name={field.name}
                      onChange={(e)=>{
                          onChange(e.target.name, e.target.value);
                      }}
                      aria-describedby={field.name + 'Help'}
                      placeholder={field.placeholder}></textarea>
            {error!=='' && <small className="form-text text-danger block">{error}</small>}
        </div>
    )
}

export default FieldText