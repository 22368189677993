import React, { useState, useEffect } from 'react'
import classnames from "classnames"

import FieldList from '../../components/form/FieldList'

const FieldCurrencySelect = ({field, value, onChange, error}) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const handleClick = (e) => {
          const el = document.getElementById(`group_${field.name}`)
          if (el && !el.contains(e.target)) {
            setOpen(false)
          }
        }
        document.addEventListener("click", handleClick)
        return () => {
          document.removeEventListener("click", handleClick)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleOpen = () =>  setOpen(!open)
    const onSelect = (value) => {
        onChange(field.name, ''+value);
        setOpen(false)
    }
    const handleChange = (e) => {
        onChange(field.name, e.target.value);
        setOpen(false)
    }

    return (
        <div className="form-group" id={`group_${field.name}`}>
            <label htmlFor={field.name}>{field.label}</label>
            <div className={classnames("currency","form-group",{'text-danger':error!==''})}>
            <span className="input-group-addon">€</span>
            <input
                type={field.type}
                className={classnames("form-control",{'text-danger':error!==''})}
                id={field.name}
                name={field.name}
                value={value}
                onChange={handleChange}
                onFocus={toggleOpen}
                autoComplete="off"
                aria-describedby={field.name + 'Help'}
                placeholder={field.placeholder}
            />
            <span className="input-group-addon">,00</span>
            </div>
            {field.suffix && <small id={field.name + 'Help'} className="form-text text-muted">{field.suffix}</small>}
            {error!=='' && <small className="form-text text-danger">{error}</small>}
            {open &&
                <FieldList
                    value={value}
                    values={field.values}
                    onSelect={onSelect}
                    id={`list-${field.name}`}
                />
            }
        </div>
    )

}

export default  FieldCurrencySelect

