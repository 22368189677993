import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../utils/Auth";
import Section from "../../components/Section";
import IconImage from "../../components/icons/IconImage";
import BoxImage from "../../components/BoxImage";
import {apiGetImages} from "../../utils/api";

const Images = () => {
    //hooks & constants
    const {t} = useTranslation();
    const {setPageAttributes} = useContext(AuthContext);

    //states
    const [images, setImages] = useState([])

    //effects
    useEffect(() => {
        setPageAttributes(t('title.images'));
        getImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //methods
    const getImages = () => {
        apiGetImages().then((response) => {
            if (response.status) {
                setImages(response.images);
            }
        })
    }



    //render
    return <Section icon={<IconImage/>} title={t('title.images')}>
        <div className={'grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'}>
            {
                images.map((image, index) => (
                    <BoxImage image={image} index={index}/>
                ))
            }
        </div>
    </Section>
}


export default Images
