import React from 'react'
import {useTranslation} from "react-i18next";
import Datepicker from "react-tailwindcss-datepicker";
import classnames from "classnames";

const FieldDatepicker = ({className='', field, value, onChange, error }) => {

    //hooks & constants
    const {t, i18n} = useTranslation();

    //methods
    if(typeof(error)==='function') {
        error = error()
    }

    return (
        <div className={"mb-4 relative " + className}>
            <Datepicker
                containerClassName={'relative'}
                inputClassName={classnames("min-w-[250px] focus:ring-0 focus:ring-offset-0 text-light focus:border-primary peer block w-full rounded bg-darker border border-solid text-sm",(error!=='' ? 'border-danger' : 'border-secondary'))}
                toggleClassName={classnames("peer-focus:border-primary peer-focus:bg-primary absolute text-white bg-secondary rounded-r top-0 right-0 h-full px-3 text-gray-400 border border-solid border-l-0 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed",(error!=='' ? 'border-danger' : 'border-secondary'))}
                primaryColor={'blue'}
                separator={'-'}
                value={value}
                displayFormat={t('date.formatDatepicker')}
                placeholder={t('date.date')}
                onChange={value=>onChange(field.name, value)}
                showShortcuts={true}
                startWeekOn="mon"
                i18n={i18n.language[0]+i18n.language[1]}
                configs={{
                    shortcuts: {
                        today: t('date.today'),
                        yesterday: t('date.yesterday'),
                        past: period => t('date.lastXDays').replace('{daysAmount}', period),
                        currentMonth: t('date.currentMonth'),
                        pastMonth: t('date.pastMonth')
                    }
            }}
            />
            {field.suffix && <small id={field.name + 'Help'} className="block">{field.suffix}</small>}
            {error!=='' && <small className={'text-danger block'}>{error}</small>}
        </div>
    )
}

export default FieldDatepicker
