import React, {useContext} from 'react';
import {apiPostLogout} from "../../utils/api";
import useComponentVisible, {deleteLoginToken} from "../../utils/utils";
import {AuthContext} from "../../utils/Auth";
import IconChevronRight from "../icons/IconChevronRight";
import classNames from "classnames";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import IconLogOut from "../icons/IconLogOut";
import toast from "react-hot-toast";

const ProfileDropdown = () => {
    let navigate = useNavigate();
    const {user, setUser} = useContext(AuthContext);
    const { t } = useTranslation();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const logout = () => {
        apiPostLogout().then((response) => {
            setUser(null);
            deleteLoginToken()
            navigate('/login');
            toast(response.message);
        })
    }
    const toggleDropdown = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    const linkStyling = 'block w-full text-left relative py-2 px-3 transition-colors duration-300 hover:bg-secondary';


    return (
        <div className="relative inline-block">
            <button
                className={classNames((isComponentVisible ? 'bg-secondary': 'bg-transparent'),'flex items-center text-center block transition-colors duration-300 rounded border border-solid border-secondary py-2 px-3 hover:bg-secondary')}
                onClick={toggleDropdown}
            >
                <span>{user.name}</span>
                <IconChevronRight className={classNames((isComponentVisible ? '-rotate-90': 'rotate-90'),'ml-2')}/>
            </button>

            {isComponentVisible && (
                <div
                    ref={ref}
                    onClick={toggleDropdown}
                    className="absolute right-0 z-20 w-36 mt-2 origin-top-right rounded bg-dark border border-solid border-secondary divide-y divide-secondary"
                >
                    <Link to={"/profile"} className={linkStyling}>{t('link.profile')}</Link>
                    <a href={user.payment_plan_url} className={linkStyling}>
                        {t('link.payment')}
                    </a>
                    <button onClick={logout} className={linkStyling}>
                        <IconLogOut className={'align-middle inline-block mr-2 verti'}/><span className={'align-middle inline-block'}>{t('link.logout')}</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default ProfileDropdown;
