import React from 'react';

const IconLogo = ({className = ''}) => {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className="w-40 h-10"
          viewBox="0 0 889.061 147.747"
      >
          <defs>
              <linearGradient
                  id="b"
                  x1="-27.534"
                  x2="669.152"
                  y1="85.543"
                  y2="85.543"
                  gradientUnits="userSpaceOnUse"
              >
                  <stop offset="0" stopColor="#ff9800"></stop>
                  <stop offset="0.135" stopColor="#ff9800"></stop>
                  <stop offset="0.728" stopColor="#ff9800"></stop>
                  <stop offset="1" stopColor="#fff"></stop>
              </linearGradient>
              <linearGradient
                  id="c"
                  y1="85.543"
                  y2="85.543"
                  xlinkHref="#b"
              ></linearGradient>
              <linearGradient
                  id="d"
                  y1="85.134"
                  y2="85.134"
                  xlinkHref="#b"
              ></linearGradient>
              <linearGradient
                  id="e"
                  y1="85.543"
                  y2="85.543"
                  xlinkHref="#b"
              ></linearGradient>
              <linearGradient
                  id="f"
                  y1="71.627"
                  y2="71.627"
                  xlinkHref="#b"
              ></linearGradient>
              <linearGradient
                  id="g"
                  y1="122.379"
                  y2="122.379"
                  xlinkHref="#b"
              ></linearGradient>
          </defs>
          <path
              fill="url(#b)"
              d="M117.264 93.524h-73.06c.681 9.961 4.841 14.939 12.484 14.939 4.911 0 8.458-2.251 10.642-6.754h48.092c-1.637 8.187-5.084 15.521-10.335 22-5.253 6.481-11.87 11.598-19.851 15.349-7.981 3.754-16.75 5.628-26.297 5.628-11.46 0-21.661-2.386-30.595-7.163-8.937-4.773-15.895-11.63-20.874-20.566C2.488 108.022 0 97.55 0 85.543s2.488-22.476 7.47-31.414c4.979-8.934 11.937-15.79 20.874-20.567 8.935-4.774 19.135-7.163 30.595-7.163s21.658 2.354 30.595 7.06c8.934 4.707 15.893 11.428 20.874 20.158 4.979 8.732 7.47 19.032 7.47 30.901 0 3.141-.205 6.14-.614 9.005zM71.832 73.673c0-3.818-1.228-6.719-3.684-8.698-2.456-1.976-5.525-2.967-9.209-2.967-8.052 0-12.759 3.888-14.121 11.665h27.013z"
          ></path>
          <path
              fill="url(#c)"
              d="M190.732 100.073l18.418-72.241h48.297l-38.474 115.422h-56.688L123.812 27.832h48.297l18.623 72.241z"
          ></path>
          <path
              fill="url(#d)"
              d="M381.36 40.316c7.435 8.87 11.153 20.874 11.153 36.018v66.92h-45.228V82.065c0-5.455-1.467-9.788-4.4-12.995-2.935-3.204-6.855-4.81-11.767-4.81-5.049 0-9.004 1.605-11.87 4.81-2.865 3.207-4.297 7.54-4.297 12.995v61.189h-45.432V27.832h45.432v17.6c3.546-5.455 8.288-9.89 14.223-13.302 5.935-3.409 12.995-5.116 21.181-5.116 13.232 0 23.567 4.435 31.004 13.302z"
          ></path>
          <path
              fill="url(#e)"
              d="M487.877 143.254l-19.441-25.99-14.53 25.99h-49.934l38.679-60.78-40.726-54.642h50.753l19.441 26.195 14.734-26.195h49.935l-39.906 59.349 41.748 56.073h-50.753z"
          ></path>
          <path
              fill="url(#f)"
              d="M620.694 104.575v38.679h-17.191c-31.653 0-47.478-15.758-47.478-47.273V65.693h-14.326V27.832h14.326V0h45.432v27.832h18.827v37.86h-18.827v31.106c0 2.73.646 4.707 1.944 5.935 1.295 1.229 3.443 1.842 6.446 1.842h10.847z"
          ></path>
          <path
              fill="url(#g)"
              d="M641.566 138.547c-4.777-4.228-7.163-9.548-7.163-15.963 0-6.548 2.386-11.972 7.163-16.27 4.773-4.297 11.118-6.446 19.032-6.446 7.776 0 14.05 2.149 18.827 6.446 4.774 4.298 7.163 9.722 7.163 16.27 0 6.415-2.389 11.735-7.163 15.963-4.777 4.23-11.051 6.344-18.827 6.344-7.914 0-14.259-2.113-19.032-6.344z"
          ></path>
          <path
              fill="#fff"
              stroke="#051222"
              strokeMiterlimit="10"
              d="M798.486 129.432h-39.293l-5.827 17.815h-38.794l43.123-117.88h42.623l42.956 117.88h-38.96l-5.828-17.815zm-8.99-27.805l-10.656-32.8-10.655 32.8h21.312zM888.561 29.367v117.88h-36.962V29.367h36.962z"
          ></path>
      </svg>
  );
};

export default IconLogo;
