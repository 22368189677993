import {React, useContext, useEffect, useState} from 'react'
import Box from "../../components/Box";
import Section from "../../components/Section";
import IconRegister from "../../components/icons/IconEdit";
import {apiPostProfile} from "../../utils/api";
import {AuthContext} from "../../utils/Auth";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import FormTranslated from "../../components/form/FormTranslated";
import {useNavigate} from "react-router-dom";


const Terms = () => {
    //hooks & constants
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {setPageAttributes, user, setUser} = useContext(AuthContext);

    //states
    const [loading, setLoading] = useState(false);

    //effects
    useEffect(() => {
        setPageAttributes(t('title.termsOfUse'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPostProfile({tos: validated.tos}).then((response) => {
            if (response.status) {
                setUser(response.user);
                toast(response.message);
            } else {
                formValidator.responseAdapter(response);
                formValidator.validateAll();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //render
    return (
        <Section icon={<IconRegister/>} className={'max-w-md w-11/12 mx-auto'}  title={(user && user.tos_update_needed && user.tos_version !== 0 ? t('title.updatedTerms') : t('title.termsOfUse'))}>
            <Box className={'text-light'}>
                <FormTranslated
                    loading={loading}
                    name={'terms'}
                    filename={'terms'}
                    onSubmit={handleSubmit}
                />
                {user && user.tos_update_needed && user.tos_version > 0 &&
                    <div className="mt-4 text-sm">{t('register.termsUpdated')}</div>
                }
                {(!user || user.tos_version === 0) &&
                    <div className="mt-4 text-sm">{t('register.acceptTerms')}</div>
                }
            </Box>
        </Section>

    )
}

export default Terms
