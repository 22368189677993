import React from 'react'


const FieldInfobox = ({field}) => {
    return (
        <div className={((field.class) ? field.class : "")}>
            {field.label}
        </div>
    )
}

export default FieldInfobox
