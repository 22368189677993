import Section from "../../components/Section";
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {apiGetDomains} from "../../utils/api";
import BoxCreateDomain from "../../components/BoxCreateDomain";
import {AuthContext} from "../../utils/Auth";
import BoxDomain from "../../components/BoxDomain";
import IconProject from "../../components/icons/IconProject";

const Domains = () => {
    //hooks & constants
    const {t} = useTranslation();
    const {setPageAttributes} = useContext(AuthContext);

    //states
    const [domains, setDomains] = useState([])

    //effects
    useEffect(() => {
        setPageAttributes(t('title.projects'));
        apiGetDomains().then((response) => {
            if (response.status) {
                setDomains(response.domains)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //methods

    //render
    return <Section icon={<IconProject/>} title={t('title.activeProjects')}>
        <div className={'grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'}>
            {
                domains.map((domain, index) => (<BoxDomain domain={domain} key={domain.id} index={index}/>))
            }
            <BoxCreateDomain/>
        </div>
    </Section>
}


export default Domains
