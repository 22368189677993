import React from 'react';
import IconInfo from "./icons/IconInfo";

const InfoBox = ({content}) => {
    return (
        <div data-tooltip-content={content} data-tooltip-id={'react-tooltip'} className={'inline ml-1'}>
            <IconInfo className={'w-5 h-5 inline'}/>
        </div>
    );
};

export default InfoBox;
