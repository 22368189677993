import React from 'react'
import classnames from 'classnames'

const FieldTextSelect = ({value, onSelect, values, id}) => {
    const onClick = (e) => {
        const data = e.currentTarget.value
        if(data) {
            onSelect(data)
        }
    }

    return (
        <ul className="field-select-list" id={id}>
            {values.map((option, key)=> 
                    <li
                        key={key} 
                        value={option.value}
                        onClick={onClick}
                        className={classnames(
                            {'selected': value===option.value}
                        )}
                    >{option.label}</li>
                )
            }            
        </ul>
    )
}

export default FieldTextSelect