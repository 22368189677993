import React, { useState, useEffect } from 'react'
import classnames from "classnames"

import FieldList from '../../components/form/FieldList'

const FieldSetCurrencySelect = ({field, value, onChange, error}) => {
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        const handleClick = (e) => {
          const el = document.getElementById(`group_${field.name}`)
          if (el && !el.contains(e.target)) {
            setOpen(false)
          }
        }
        document.addEventListener("click", handleClick)
        return () => {
          document.removeEventListener("click", handleClick)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleOpen = () =>  setOpen(!open)
    const onSelect = (value) => {
        onChange(field.name, ''+value);
        setOpen(false)
    }
    const handleChange = (e) => {
        onChange(field.name, e.target.value);
        setOpen(false)
    }

    const isEditMode = (value && (!field.default || ''+value!==''+field.default)) || edit;

    return (
        <div className="form-group" id={`group_${field.name}`}>
            <label htmlFor={field.name}>{field.label}</label>
            {!isEditMode && <button type="button" className="btn btn-limit" onClick={() => {
                if(field.default)onChange(field.name, ''+field.default);
                setEdit(true)
            }}>Limit setzen</button>}
            <>
                <div className={classnames("currency","set-currency","form-group",(isEditMode) ? '': 'hidden',{'text-danger':error!==''})}>
                <span className="input-group-addon">€</span>
                    <input
                        type={field.type}
                        className={classnames("form-control",{'text-danger':error!==''})}
                        id={field.name}
                        name={field.name}
                        value={value}
                        onChange={handleChange}
                        onFocus={toggleOpen}
                        autoComplete="off"
                        aria-describedby={field.name + 'Help'}
                        placeholder={field.placeholder}
                    />
                    <span className="input-group-addon">,00</span>
                </div>
                <button type="button" className={classnames("btn-remove-limit", (isEditMode) ? '': 'hidden')} onClick={() => {
                    setEdit(false)
                    if(field.default){
                        onChange(field.name, ''+field.default);
                    }else{
                        onChange(field.name, 0);
                    }
                    setOpen(false)
                }} ></button>
            </>
            {field.suffix && <small id={field.name + 'Help'} className="block">{field.suffix}</small>}
            {error!=='' && <small className="form-text text-danger block">{error}</small>}
            {open &&
                <FieldList
                    value={value}
                    values={field.values}
                    onSelect={onSelect}
                    id={`list-${field.name}`}
                />
            }
        </div>
    )

}

export default  FieldSetCurrencySelect

