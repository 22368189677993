import React from 'react'

const Button = ({title, icon, ...rest}) => {
    return (
        <button  {...rest} className={"rounded-lg w-full text-white uppercase disabled:opacity-75 transition-all bg-darker hover:bg-secondary-dark duration-300 py-3 px-4"}>
            {icon && (
            <div className="hidden lg:inline-block align-middle mr-3">
                {icon}
            </div>
        )}{title}</button>
    )
}

export default Button
