import Box from "./Box";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
const BoxImage = ({image}) => {
    //hooks & constants
    const {t} = useTranslation();

    //states
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);

    return <Box className={'bg-secondary col-span-4 !pt-12'} icon={true} iconState={edit}
                iconFunction={() => setEdit(!edit)}>
        {!edit ? <>
            Image
        </> : <>
            FORM EDIT IMAGE TITLE/ALT
        </>}
    </Box>

};


export default BoxImage;
