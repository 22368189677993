import React from 'react'
import classNames from "classnames";

const FieldSingleCheckbox = ({ field, value, onChange, error}) =>
    <div className="relative mb-4">
        <label className="flex" htmlFor={field.name}>
            <input
                type="checkbox"
                className={classNames("bg-darker border border-solid text-success w-5 h-5 focus:ring-0 focus:ring-offset-0 rounded",(error!=='' ? 'border-danger' : 'border-secondary'))}
                id={field.name}
                aria-describedby={field.name + 'Help'}
                name={field.name}
                checked={value}
                onChange={()=>{
                    onChange(field.name, !value);
                }}
            /> <span className="ml-2" dangerouslySetInnerHTML={{__html: field.label}}></span></label>
        {error!=='' && <small className={'text-danger block'}>{error}</small>}
    </div>

export default FieldSingleCheckbox
