import Box from "./Box";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {domainBorderColors} from "../utils/utils";
import FavIcon from "./parts/FavIcon";
import LinkWithNumber from "./LinkWithNumber";
const BoxDomain = ({domain, index}) => {
    const {t} = useTranslation();


    return <Box icon={true} iconLink={"/domains/" + domain.id}
                borderClass={domainBorderColors().at(index)}
                key={domain.id}>
        <div className={"text-center"}>
            <Link to={"/domains/" + domain.id} className={'inline-block mb-3'}><FavIcon url={domain.favicon}/></Link>
            <Link to={"/domains/" + domain.id}
                  className={"text-white transition-colors duration-300 hover:text-primary"}><h2
                className={"mb-4"}>{domain.domain_name}</h2></Link>
        </div>
        <LinkWithNumber num={domain.suggestion_count} numClassName="w-8"
                        href={'/articles/?domain_id=' + domain.id + '&state=suggestion'}
                        text={t('project.articleSuggestions')}/>
        <LinkWithNumber num={domain.outline_count} numClassName="w-8"
                        href={'/articles/?domain_id=' + domain.id + '&state=outline'}
                        text={t('project.articleOutlines')}/>
        <LinkWithNumber num={domain.unpublished_count} numClassName="w-8"
                        href={'/articles/?domain_id=' + domain.id + '&state=article'}
                        text={t('project.articlesUnpublished')}/>
        <LinkWithNumber num={domain.published_count} numClassName="w-8"
                        href={'/articles/?domain_id=' + domain.id + '&state=published'}
                        text={t('project.articlesPublished')}/>
        <LinkWithNumber num={domain.media_count} numClassName="w-8"
                        href={'/media/?domain_id=' + domain.id}
                        text={t('project.media')}/>
    </Box>

};


export default BoxDomain;
