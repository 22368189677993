import {React, useContext, useEffect, useState} from 'react'
import Box from "../../components/Box";
import Section from "../../components/Section";
import IconRegister from "../../components/icons/IconEdit";
import IconGoogle from "../../components/icons/IconGoogle";
import LinkWithArrow from "../../components/LinkWithArrow";
import {Link, useNavigate } from "react-router-dom";
import {apiPostRegister} from "../../utils/api";
import {AuthContext} from "../../utils/Auth";
import {setLoginToken} from "../../utils/utils";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import FormTranslated from "../../components/form/FormTranslated";


const Register = () => {
    //hooks & constants
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {setPageAttributes} = useContext(AuthContext);

    //states
    const {setUser} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    //effects
    useEffect(() => {
        setPageAttributes(t('title.register'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPostRegister(validated.name, validated.email, validated.password, validated.tos).then((response) => {
            if (response.status) {
                setUser(response.user);
                setLoginToken(response.token)
                navigate('/')
                toast(response.message);
            } else {
                formValidator.responseAdapter(response);
                formValidator.validateAll();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //render
    return (
        <Section icon={<IconRegister/>} className={'max-w-md w-11/12 mx-auto'}  title={t('button.register')}>
            <Box className={'text-light'}>
                <FormTranslated
                    loading={loading}
                    name={'register'}
                    filename={'register'}
                    onSubmit={handleSubmit}
                />
                <div className={"text-center relative mt-6 mb-6 ml-[-1.5rem] mr-[-1.5rem] before:content-[''] before:block before:w-full before:h-px before:absolute before:top-1/2 before:left-0 before:bg-secondary"}>
                    <div className={"relative bg-dark inline-block pr-9 pl-9"}>{t('register.or')}</div>
                </div>
                <div className="text-center text-white mb-2">
                    <IconGoogle/><LinkWithArrow href={'https://staging.evnxt.ai/login-with-google'} text={t('register.googleRegister')}/>
                </div>
            </Box>
            <div className={"mt-4 mb-4"}><Link to={"/login/"} className={'text-light hover:text-white transition-colors duration-300'}>{t('register.registered')}</Link></div>
        </Section>

    )
}

export default Register
