import Box from "./Box";
import React, {useState} from "react";
import {apiPostAuthor} from "../utils/api";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import SectionTitle from "./SectionTitle";
import IconPlus from "./icons/IconPlus";
import FormTranslated from "../components/form/FormTranslated";
import {useNavigate} from "react-router-dom";

const BoxCreateAuthor = ({domainId, onChange=null}) => {
    //hooks & constants
    const {t} = useTranslation();
    const navigate = useNavigate();

    //states
    const [loading, setLoading] = useState(false);

    //effects

    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPostAuthor(validated.name, domainId)
            .then((response) => {
                if (response.status) {
                    if(onChange) {
                        onChange(response.author);
                    }
                    toast(response.message);
                    //redirect to details page
                    navigate('/authors/'+response.author.id);
                } else {
                    formValidator.responseAdapter(response);
                    formValidator.validateAll();
                }
            }).finally(() => {
            setLoading(false);
        });
    }

    //render
    return <Box className={'bg-darker pt-4'} borderClass={'border-secondary border-dashed'}>
        <SectionTitle icon={<IconPlus/>}>{t('title.createAuthor')}</SectionTitle>
        <FormTranslated
                loading={loading}
                name={'createAuthor'}
                filename={'createAuthor'}
                onSubmit={handleSubmit}
            />

    </Box>

};


export default BoxCreateAuthor;
