import React, {useContext, useState} from 'react'
import {AuthContext} from "../../utils/Auth";
import {Link, useLocation} from "react-router-dom";
import IconLogIn from "../../components/icons/IconLogIn";
import IconRegister from "../../components/icons/IconEdit";
import IconLogo from "../../components/icons/IconLogo";
import IconEN from "../../components/icons/flags/IconEN";
import IconDE from "../../components/icons/flags/IconDE";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import ProfileDropdown from "./ProfileDropdown";
import IconMenu from "../icons/IconMenu";
import IconCross from "../icons/IconCross";

const Navigation = () => {
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = useState(false);
    const {isLoggedIn} = useContext(AuthContext);
    const {t, i18n} = useTranslation();
    const toggleMobileMenu = () => {
        setMobileOpen(!mobileOpen);
    };
    const lngs = {
        en: {nativeName: 'English', flag: <IconEN/>},
        de: {nativeName: 'Deutsch', flag: <IconDE/>}
    };

    const links = {}

    if (isLoggedIn()) {
        links.dashboard ={
            title: t('title.dashboard'),
            to: '/'
        }
        links.domains = {
            title: t('title.projects'),
            to: '/domains/'
        }
        links.articles = {
            title: t('title.articles'),
            to: '/articles/'
        }
    }

    const buttonStyling = 'uppercase text-center block transition-colors duration-300 rounded bg-transparent border border-solid border-secondary py-2 px-3 hover:bg-secondary';
    const iconStyling = 'mr-2 w-5 h-5 inline-block align-middle';
    const linkStyling = 'block uppercase relative py-2 transition-colors duration-300 hover:text-primary';
    const linkActiveStyling = 'text-primary after:content-[""] after:block after:w-full after:h-px after:absolute after:bottom-[calc(-1rem-3px)] after:left-0 after:bg-primary';

    const mobileLinkStyling = 'block uppercase relative text-xl py-2 transition-colors duration-300 hover:text-primary';
    const mobileLinkActiveStyling = 'text-primary';
    const mobileButtonStyling = 'uppercase text-center my-3 w-48 text-lg block transition-colors duration-300 rounded bg-transparent border border-solid border-secondary py-2 px-3 hover:bg-secondary';

    return (
        <div
            className={'border-b border-solid bg-dark border-secondary max-lg:fixed max-lg:w-full max-lg:z-20 max-lg:top-0'}>
            <nav className={'grid grid-cols-12 gap-4 py-4 max-w-screen-2xl px-4 mx-auto align-center'}>
                <div className={'col-span-2 max-lg:col-span-3'}>
                    <Link to={'/'}><IconLogo/></Link>
                </div>
                <div className={classNames('max-lg:hidden', (isLoggedIn() ? 'col-span-6' : 'col-span-5'))}>
                    <ul className={'flex items-center h-full'}>
                        {Object.keys(links).map((link) => (
                            <li key={link} className={'px-6'}>
                                <Link to={links[link].to}
                                      className={classNames((links[link].to === location.pathname ? linkActiveStyling : ''), linkStyling)}>{links[link].title}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div
                    className={classNames('max-lg:col-span-9 flex items-center justify-end', (isLoggedIn() ? 'col-span-4' : 'col-span-5'))}>
                    {!isLoggedIn() ?
                        <div className={'flex gap-4 max-lg:hidden'}>
                            <Link to={"/login/"} className={buttonStyling}><IconLogIn className={iconStyling}/>
                                <span className={'align-middle inline-block'}>{t('button.login')}</span>
                            </Link>
                            <Link to={"/register/"} className={buttonStyling}><IconRegister className={iconStyling}/>
                                <span className={'align-middle inline-block'}>{t('button.register')}</span>
                            </Link>
                        </div> : <div className={'col-span-3 items-center text-right max-lg:hidden'}>
                            <ProfileDropdown/>
                        </div>
                    }
                    <div className={'ml-4 text-right'}>
                        {Object.keys(lngs).map((lng) => (
                            <button key={lng}
                                    className={classNames('my-2 mx-1 border border-solid border-2 rounded-full', i18n.resolvedLanguage === lng ? 'border-primary' : 'border-transparent')}
                                    type="submit" onClick={() => i18n.changeLanguage(lng)}>
                                {lngs[lng].flag}
                            </button>
                        ))}
                    </div>
                    <button className={'lg:hidden ml-4'} onClick={toggleMobileMenu}>{(mobileOpen ?
                        <IconCross className={'w-8 h-8'}/> : <IconMenu/>)}</button>

                    {mobileOpen && <div
                        className={'lg:hidden flex-col fixed flex justify-center items-center top-[77px] z-10 bg-dark left-0 bottom-0 right-0'}>
                        {(isLoggedIn() ?
                                <>
                                    <ProfileDropdown/>
                                    <ul className={'pb-[77px] mt-6'}>
                                        {Object.keys(links).map((link) => (
                                            <li key={link} className={'px-6 py-2 w-full text-center'}>
                                                <Link to={links[link].to} onClick={toggleMobileMenu}
                                                      className={classNames((links[link].to === location.pathname ? mobileLinkActiveStyling : ''), mobileLinkStyling)}>{links[link].title}</Link>
                                            </li>
                                        ))}
                                    </ul>

                                </>
                                :
                                <>
                                    <Link to={"/login/"} onClick={toggleMobileMenu}
                                          className={mobileButtonStyling}><IconLogIn className={iconStyling}/>
                                        <span className={'align-middle inline-block'}>{t('button.login')}</span>
                                    </Link>
                                    <Link to={"/register/"} onClick={toggleMobileMenu}
                                          className={mobileButtonStyling}><IconRegister className={iconStyling}/>
                                        <span className={'align-middle inline-block'}>{t('button.register')}</span>
                                    </Link>
                                </>
                        )}
                    </div>}
                </div>
            </nav>
        </div>
    )
}

export default Navigation
