import {React, useContext, useEffect, useState} from 'react'
import Box from "../../components/Box";
import Section from "../../components/Section";
import IconRegister from "../../components/icons/IconEdit";
import {apiPostResetPassword} from "../../utils/api";
import {useNavigate, useParams} from "react-router-dom";
import {setLoginToken} from "../../utils/utils";
import {AuthContext} from "../../utils/Auth";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import FormTranslated from "../../components/form/FormTranslated";

const ResetPassword = () => {
    //hooks & constants
    const {token} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {
        setUser,
        setPageAttributes
    } = useContext(AuthContext);

    //states
    const [loading, setLoading] = useState(false);

    //effects
    useEffect(() => {
        setPageAttributes(t('title.resetPassword'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const url = new URLSearchParams(window.location.search);
        const email = url.get('email');
        const {validated} = fieldData;
        setLoading(true);
        apiPostResetPassword(email, token, validated.password).then((response) => {
            if (response.status) {
                setUser(response.user);
                setLoginToken(response.token);
                navigate('/');
                toast(response.message);
            } else {
                formValidator.responseAdapter(response);
                formValidator.validateAll();
            }
        });
    }

    //render
    return (
        <Section icon={<IconRegister/>} className={'max-w-md w-11/12 mx-auto'} title={t('title.resetPassword')}>
            <Box className={'text-light'}>
                <FormTranslated
                    loading={loading}
                    name={'resetPassword'}
                    filename={'resetPassword'}
                    onSubmit={handleSubmit}
                />
            </Box>
        </Section>

    )
}

export default ResetPassword
