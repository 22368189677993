import React, {useEffect, useState} from 'react';
import {t} from "i18next";
import Box from "../Box";
import Modal from "../Modal";
import FieldButton from "../form/FieldButton";
import FieldText from "../form/FieldText";
import IconImage from "../icons/IconImage";
import {apiGetImages} from "../../utils/api";
import IconLoading from "../icons/IconLoading";

const ArticleImage = ({article = {}, changeArticleImage}) => {
    //hooks & constants
    const [delayDebounceFn, setDelayDebounceFn] = useState(null)

    //states
    const [openModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([])
    const [search, setSearch] = useState('')

    //effects
    useEffect(() => {
        if (!article.author.domain.id) return;
        if (!openModal) return;
        if (delayDebounceFn) {
            clearTimeout(delayDebounceFn)
        }
        setDelayDebounceFn(setTimeout(() => {
            setLoading(true)
            getImages()
        }, 500))
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article.author.domain.id, search, openModal])

    //methods

    const getImages = () => {
        let query = {
            domain_id: article.author.domain.id
        }
        if (search) {
            query.search = search
        }
        apiGetImages(query).then((response) => {
            if (response.status) {
                setImages(response.images);
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleImageChange = (image) => {
        changeArticleImage(image)
        setOpenModal(false)
    };

    //render
    return (<>
            <Box className={'!p-3 mb-4'}>
                <div
                    className={"block relative before:content-[''] before:block before:ml-[-0.75rem] before:mr-[-0.75rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary"}>
                    <h2 className={'pb-3 pr-3 mr-8 uppercase text-white font-boldfont'}>{t('title.articleImage')}</h2>
                </div>
                <div className={'pt-3'}>
                    {article.image && <>
                        <img className={'mb-2 cursor-pointer w-full h-auto'} src={article.image.thumb_url} alt={article.image.alt}/>
                        <FieldButton
                            field={{
                                label: t('button.changeArticleImage')
                            }}
                            disabled={loading}
                            primary={false}
                            onClick={() => {
                                setOpenModal(true)
                            }}
                        />
                    </>}
                    {!article.image && <FieldButton
                        field={{
                            label: t('button.setArticleImage')
                        }}
                        disabled={loading}
                        primary={false}
                        onClick={() => {
                            setOpenModal(true)
                        }}
                    />}
                </div>
            </Box>

            {<Modal
                isOpen={openModal}
                setIsOpen={setOpenModal}
                className={'h-[calc(100%-4rem)]'}>
                <h3 className={"font-boldfont text-sm text-white mb-4 uppercase"}>
                    <IconImage className={'inline-block'}/> {t('title.selectArticleImage')}
                </h3>
                <FieldText
                    className={'mb-6'}
                    field={{type: 'text', name: 'image-search', placeholder: t('filter.search'), validator: ''}}
                    error={''}
                    value={search}
                    onChange={(fieldName, value) => {
                        setSearch(value);
                    }}
                />
                {loading ?
                    <div className={'flex items-center justify-center h-[calc(100%-6rem)]'}>
                        <IconLoading animated="true" className={'!w-20 !h-20'}/>
                    </div>
                    : <div className={'grid gap-1 sm:grid-cols-2 md:grid-cols-3 overflow-auto pr-4 h-[calc(100%-6rem)]'}>
                        {images.map((image) => (
                                <img
                                    key={image.id}
                                    onClick={() => handleImageChange(image)}
                                    className={'cursor-pointer w-full h-auto border-2' + (article.image_id === image.id ? ' border-primary' : ' border-dark')}
                                    src={image.thumb_url}
                                    alt={image.alt}

                                />
                        ))}
                    </div>}
            </Modal>
            }
        </>
    );
};

export default ArticleImage;
