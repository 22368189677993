import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../utils/Auth";
import {apiGetProfile, apiPutChangePassword} from "../../utils/api";
import IconKey from "../../components/icons/IconKey";
import FormTranslated from "../../components/form/FormTranslated";
import Tabs from "../../components/Tabs";
import toast from "react-hot-toast";
import IconLogIn from "../../components/icons/IconLogIn";
import IconStar from "../../components/icons/IconStar";
import ClipboardButton from "../../components/ButtonCopyToClipboard";
import Button from "../../components/Button";
import Box from "../../components/Box";

const Profile = () => {
    //hooks & constants
    const {t} = useTranslation();
    const {user,
        setPageAttributes
    } = useContext(AuthContext);


    const tableHeaders = [
        t('partner.table.date'), t('partner.table.clicks'), t('partner.table.leads'), t('partner.table.sales'), t('partner.table.revenue')
    ];
    const partnerStatisticsData = {
        revenueSinceLastPayout: 500,
        lastPayout: 250,
        lastFiveDays: [
            { date: '03.09.2023', clicks: 120, leads: 10, sales: 3, revenue: 100 },
            { date: '02.09.2023', clicks: 110, leads: 9, sales: 2, revenue: 80 },
            { date: '01.09.2023', clicks: 105, leads: 8, sales: 3, revenue: 90 },
            { date: '31.08.2023', clicks: 130, leads: 11, sales: 2, revenue: 70 },
            { date: '30.08.2023', clicks: 125, leads: 10, sales: 4, revenue: 110 }
        ]
    };


    //states
    const [profile, setProfile] = useState(null)
    const [plan, setPlan] = useState(null)
    const [loading, setLoading] = useState(false);

    //effects
    useEffect(() => {
        setPageAttributes(t('title.profile'));
        apiGetProfile().then((response) => {
            if (response.status) {
                setProfile(response.user)
                setPlan(response.plan)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //methods
    const tabs = () => {
        return {
            info: {
                title: t('title.account'),
                icon: <IconLogIn/>,
                content:
                    <>
                        {user.max_domains === 0 ? (
                            <Box className={'max-w-lg'}> <h2 className="mb-2 font-bold font">
                                {t('profile.welcomeTitle')}
                            </h2>
                                <p>{t('profile.welcomeText')}</p>
                                <br />
                                <Button
                                    title={t('payment.newPlans')}
                                    onClick={() => {
                                        window.location.href = user.payment_plan_url;
                                    }}
                                />
                            </Box>
                        ) : (
                            <>
                                <h3 className="uppercase mb-4 text-white font-bold font">
                                    {t('title.currentPlan')}
                                </h3>
                                <div>{plan.name}</div>
                                <div>
                                    {profile.usage.articles_left} {t('article.articlesLeft')}
                                </div>
                                <div>
                                    {profile.usage.premium_articles_left} {t('article.premiumArticlesLeft')}
                                </div>
                                <div className="inline-block mt-4">
                                    <Button
                                        title={
                                            profile.max_domains === 0
                                                ? t('payment.viewPlans')
                                                : t('payment.upgradePlan')
                                        }
                                        onClick={() => {
                                            window.location.href = profile.payment_plan_url;
                                        }}
                                    />
                                </div>
                            </>
                        )}

                    </>
            },
            partner: {
                title: t('title.partner'),
                icon: <IconStar/>,
                content: <div className="p-6">
                    <div className="md:flex">
                        <div className=" md:w-3/5  pr-4">
                            <h2 className="text-2xl mb-4">{t('partner.profiletitle')}</h2>
                            <p className="mb-2">{t('partner.revenue')}: {partnerStatisticsData.revenueSinceLastPayout} €</p>
                            <p className="mb-4">{t('partner.lastPayout')}: {partnerStatisticsData.lastPayout} €</p>
                        </div>
                        <div className="md:w-2/5">
                            <label className={'mt-5 block'} htmlFor="link">{t('partner.link')}</label>
                            <div className="flex mt-2 items-center">
                                <input type="text" id={'link'} value={'https://www.partnercode.de'}
                                       className="mt-2 autofill:bg-darker text-light focus:ring-0 focus:ring-offset-0 focus:border-primary block w-full rounded bg-darker border border-solid text-sm border-secondary"
                                       readOnly/>
                                <ClipboardButton link="https://www.partnercode.de"/>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-auto pb-4">
                        <table className="min-w-full mt-8">
                            <thead>
                            <tr className="text-left">
                                {tableHeaders.map(header => (
                                    <th key={header} className="py-2 px-4 border-b border-secondary">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {partnerStatisticsData.lastFiveDays.map((day, idx) => (
                                <tr key={day.date} className={idx % 2 === 1 ? 'bg-darker' : ''}>
                                    <td className="py-2 px-4">{day.date}</td>
                                    <td className="py-2 px-4">{day.clicks}</td>
                                    <td className="py-2 px-4">{day.leads}</td>
                                    <td className="py-2 px-4">{day.sales}</td>
                                    <td className="py-2 px-4">{day.revenue} €</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <p className="mt-8">{t('partner.profileText')}</p>
                </div>
            },
            changepassword: {
                title: t('profile.changePassword'),
                icon: <IconKey/>,
                content: <FormTranslated
                    loading={loading}
                    name={'changePassword'}
                    filename={'changePassword'}
                    onSubmit={handleSubmit}
                />
            }
        }
    }

    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPutChangePassword(validated.old_password, validated.new_password).then((response) => {
            if (response.status) {
                toast(response.message);
            } else {
                formValidator.responseAdapter(response);
                formValidator.validateAll();
            }
        }).finally(() => {
            setLoading(false);
        });
    }



    //render
    return profile && <div className={'grid gap-4 grid-cols grid-cols-12'}>
        <div className={'col-span-12'}>
            <Tabs initialActiveItem={'info'} items={tabs()}/>
        </div>
    </div>
}


export default Profile
