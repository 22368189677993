import React from 'react'
import IconLoading from "../icons/IconLoading";

const FieldButton = ({loading = false, primary = false, field, onClick, disabled = false}) => {
    let buttonClasses = 'relative rounded-lg text-white text-sm uppercase disabled:opacity-75 transition-all duration-300 font-boldfont py-2 px-4';

    if (primary) {
        buttonClasses += ' bg-[length:200%_200%] bg-left-top bg-gradient-to-br from-primary via-primary-light to-primary enabled:hover:bg-[99%] shadow-[0_3px_10px_rgba(0,0,0,0.3)]';
        }else{
        buttonClasses += ' bg-secondary hover:bg-secondary-dark';
        }

    return <div className="">
        <button onClick={(e) => {
            e.preventDefault();
            onClick()
        }} className={buttonClasses} disabled={disabled || loading}>
            {(loading ? <><IconLoading className={'mx-auto absolute top-[calc(50%-0.75rem)] left-[calc(50%-0.75rem)]'}/><span className={'opacity-0'}>{field.label}</span></> : field.label)}
        </button>
    </div>
}

export default FieldButton
