import Box from "../../components/Box";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../utils/Auth";
import IconReset from "../../components/icons/IconReset";
import FieldText from "../../components/form/FieldText";
import FieldSelect from "../../components/form/FieldSelect";
import FieldDatepicker from "../../components/form/FieldDatepicker";
import {apiGetArticles} from "../../utils/api";
import {useLocation} from "react-router-dom";
import ArticleRow from "../../components/article/ArticleRow";
import {useInterval} from "../../utils/utils";

const Articles = () => {
    //hooks & constants
    const {t} = useTranslation();
    const {user, setPageAttributes} = useContext(AuthContext);

    //states
    const [articles, setArticles] = useState([])
    const [filterSearch, setFilterSearch] = useState(new URLSearchParams(useLocation().search).get('q') ?? '')
    const [filterState, setFilterState] = useState(new URLSearchParams(useLocation().search).get('state') ?? '')
    const [filterAuthor, setFilterAuthor] = useState(new URLSearchParams(useLocation().search).get('author_id') ?? '')
    const [filterDate, setFilterDate] = useState({startDate: null, endDate: null})
    const [filterDomain, setFilterDomain] = useState(new URLSearchParams(useLocation().search).get('domain_id') ?? '')
    const [filterValues, setFilterValues] = useState({
        domains: [],
        authors: [],
        states: []
    })

    //effects
    useEffect(() => {
        setPageAttributes(t('title.articles'));
        getArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getArticles()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSearch, filterState, filterAuthor, filterDate, filterDomain])

    useInterval(() => {
        getArticles();
    },30000);

    //methods

    const onChangeArticle = (article) => {
        setArticles(articles.map((a) => {
            if (a.id === article.id) return article;
            return a;
        }))
    }

    const resetFilters = () => {
        setFilterSearch('')
        setFilterState('')
        setFilterAuthor('')
        setFilterDomain('')
        setFilterDate({startDate: null, endDate: null})
    }

    const getArticles = () => {
        const payload = {}
        if (filterSearch) payload.q = filterSearch
        if (filterState) payload.state = filterState
        if (filterAuthor) payload.author_id = filterAuthor
        if (filterDomain) payload.domain_id = filterDomain
        if (filterDate.startDate) payload.start_date = filterDate.startDate
        if (filterDate.endDate) payload.end_date = filterDate.endDate
        apiGetArticles(payload).then((response) => {
            if (response.status) {
                setArticles(response.articles);
                setFilterValues({
                    domains: Object.keys(response.domains).map((key) => {
                        return {label: response.domains[key], value: key}
                    }),
                    authors: Object.keys(response.authors).map((key) => {
                        return {label: response.authors[key], value: key}
                    }),
                    states: Object.keys(response.states).map((key) => {
                        return {label: response.states[key], value: key}
                    })
                });
            }
        })
    }



    //render
    return <Box className={'!p-3'}>
        <div
            className={"block relative before:content-[''] before:block before:ml-[-0.75rem] before:mr-[-0.75rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary"}>
            <h2 className={'pb-3 pr-3 mr-8 uppercase text-white font-boldfont'}>{t('title.filter')}</h2>
            <button
                className="absolute top-0 right-0 text-white flex gap-2 items-center hover:text-primary transition-colors duration-300"
                onClick={resetFilters}><span>{t('filter.reset')}</span><IconReset/></button>
        </div>
        <div
            className={"pt-3 flex flex-wrap gap-4 relative before:content-[''] before:block before:ml-[-0.75rem] before:mr-[-0.75rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary"}>
            <FieldText
                className={'!mb-3 grow'}
                field={{type: 'text', name: 'filter-search', placeholder: t('filter.search'), validator: ''}}
                error={''}
                value={filterSearch}
                onChange={(fieldname, val) => {
                    setFilterSearch(val)
                }}
            />
            <FieldSelect
                className={'!mb-3 grow'}
                field={{
                    placeholder: t('filter.project'),
                    name: 'filter-project',
                    validator: '',
                    values: filterValues.domains
                }}
                value={filterDomain}
                onChange={(fieldname, val) => {
                    setFilterDomain(val)
                    setFilterAuthor('')
                }}
            />
            <FieldSelect
                className={'!mb-3 grow'}
                field={{
                    placeholder: t('filter.author'),
                    name: 'filter-author',
                    validator: '',
                    values: filterValues.authors
                }}
                value={filterAuthor}
                onChange={(fieldname, val) => {
                    setFilterAuthor(val)
                }}
            />
            <FieldSelect
                className={'!mb-3 grow'}
                field={{
                    placeholder: t('filter.state'),
                    name: 'filter-state',
                    validator: '',
                    values: filterValues.states
                }}
                value={filterState}
                onChange={(fieldname, val) => {
                    setFilterState(val)
                }}
            />
            <FieldDatepicker
                className={'!mb-3 grow'}
                error={''}
                field={{
                    placeholder: t('filter.date'),
                    name: 'filter-date',
                    validator: '',
                }}
                value={filterDate}
                onChange={(fieldname, val) => {
                    setFilterDate(val)
                }}
            />
        </div>
        <div
            className={"py-3 block relative before:content-[''] before:block before:ml-[-0.75rem] before:mr-[-0.75rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary"}>
            <h2 className={'uppercase text-white font-boldfont inline-block mr-2'}>{t('title.articles')}</h2>
            <span className={'text-xs inline-block'}>({t('title.currentPlan')}: {t('article.xArticlesLeft').replace('{articlesAmount}', user.usage.articles_left)}, {t('article.xPremiumArticlesLeft').replace('{articlesAmount}', user.usage.premium_articles_left)} )</span>
        </div>
        {Object.keys(articles).length > 0 ? (
                <div className={'grid grid-cols-12 pt-3 gap-x-4'}>
                    {Object.keys(articles).map((key, index) => {
                        const article = articles[key];
                        return (
                            <ArticleRow
                                key={article.id}
                                article={article}
                                index={index}
                                onChange={onChangeArticle}
                            />
                        );
                    })}
                </div>
            ) : (
                <div className={'grid grid-cols-1 pt-3'}>
                    {t('filter.noarticles')}
                </div>
            )}
    </Box>
}


export default Articles
