import Box from "./Box";
import {useTranslation} from "react-i18next";
import SectionTitle from "./SectionTitle";
import ClipboardButton from "./ButtonCopyToClipboard";
import React from "react";
const BoxPartner = ({}) => {
    const {t} = useTranslation();


    return <Box className={'bg-[length:200%_200%] bg-left-top bg-gradient-to-br from-primary via-primary-light to-primary md:min-h-[350px] text-secondary'} borderClass={'border-primary-light'}>
        <SectionTitle className={'text-secondary'}>{t('partner.infoTitle')}</SectionTitle>
        <p>{t('partner.infoText')}</p>
        <label className={'mt-5 block'} htmlFor="link">{t('partner.link')}</label>
        <div className="flex mt-2 items-center">
            <input type="text" id={'link'} value={'https://www.partnercode.de'} className="mt-2 autofill:bg-darker text-light focus:ring-0 focus:ring-offset-0 focus:border-primary block w-full rounded bg-darker border border-solid text-sm border-secondary" readOnly/>
            <ClipboardButton link="https://www.partnercode.de" />
        </div>
    </Box>

};


export default BoxPartner;
