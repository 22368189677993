import {useContext, useEffect} from "react";
import {AuthContext} from "../../utils/Auth";
import {useTranslation} from "react-i18next";

const Page404 = () => {
    //hooks & constants
    const {setPageAttributes} = useContext(AuthContext);
    const {t} = useTranslation();

    //states

    //effects
    useEffect(() => {
        setPageAttributes(t('title.404'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //methods

    //render
    return (
        <div>404</div>
    )
}

export default Page404
