export default function checkSecurePassword (password, email) {
  // configs
  let maxReapeatings = 3
  //let maxSequences = 3
  let redColor = '#a94442'
  let orangeColor = '#ff9100'
  let yellowColor = '#ffEA00'
  let greenColor = '#00e676'
  let sets = ['', 'abcdefghijklmnopqrstuvwxyzäöüß', 'ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ', '0123456789', ' !§$%|&/()=-?*;:_+#^,.@<>[]{}”\'']

  let isToShort = false
  let hasRepeatedChars = false
  //let hasSequences = false
  let emailIncluded = false
  let setsIncluded = 0

  const minLength = 8

  if (password.length < minLength) {
    isToShort = true
  }

  // check for repeating letters or sequences
  let repeatings = 0
  //let sequences = 0
  let charCodeBefore = -1
  let charCode = -1
  for (let i = 0, len = password.length; i < len; i++) {
    let char = password[i]
    charCode = char.charCodeAt(0)

    // check for repeating chars
    if (charCodeBefore === charCode) {
      repeatings++
    } else {
      repeatings = 0
    }

    // check for sequences
    /*if (charCodeBefore + 1 === charCode || charCodeBefore === charCode + 1) {
      sequences++
    }
    else {
      sequences = 0
    }*/

    // check for sets
    let matchedSet = null
    for (let j = 0, len2 = sets.length; j < len2; j++) {
      if (sets[j].indexOf(char) !== -1) {
        matchedSet = j
      }
    }
    if (matchedSet) {
      sets.splice(matchedSet, 1)
      setsIncluded++
      matchedSet = null
    }

    if (repeatings >= maxReapeatings) {
      hasRepeatedChars = true
    }
    /*if (sequences >= maxSequences) {
      hasSequences = true
    }*/

    charCodeBefore = charCode
  }

  // eslint-disable-next-line no-useless-escape
  const matches = password.match(/[\!\§\$\%\|\&\/\(\)\=\-\?\*\;\:\_\+\#\^\,\.\@\<\>\[\]v{\}\”\']+/)
  if (matches != null) {
    // containsNoSpecialChars = true;
  }
  if (email && password.indexOf(email.toLowerCase()) !== -1) {
    emailIncluded = true
  }

  // check pw
  let ret = false
  let state = 'ungültig'
  let color = redColor
  let message = 'Außer Buchstaben sollte mindestens eine Zahl oder ein Sonderzeichen (!§$%|&/()=-?*;:_+#^,.@<>) enthalten sein.'

  if (isToShort) {
    message = `Verwende bitte ${minLength} oder mehr Zeichen. Außer Buchstaben muss mindestens eine Zahl, ein Großbuchstabe und ein Sonderzeichen (!§$%|&/()=-?*;:_+#^,.@<>) enthalten sein.`
    state = 'zu kurz'
  } else if (setsIncluded < 2) {
    message = 'Außer Buchstaben sollte mindestens eine Zahl, ein Großbuchstabe und ein Sonderzeichen (!§$%|&/()=-?*;:_+#^,.@<>) enthalten sein.'
  } else if (emailIncluded || hasRepeatedChars) {
  //} else if (emailIncluded || hasRepeatedChars || hasSequences) {
    message = 'Das Passwort ist zu leicht zu erraten. Bitte wähle ein anderes Passwort.'
  } else {
    ret = true
    if (setsIncluded === 2) {
      state = 'schwach'
      color = orangeColor
    } else if (setsIncluded === 3) {
      state = 'mittel'
      color = yellowColor
    } else if (setsIncluded === 4) {
      state = 'stark'
      color = greenColor
      message = 's'
    }
  }
  return {
    valid: ret, state, color, message
  }
}
