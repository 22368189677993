import React from 'react'

const SectionTitle = ({ icon = null, children, className = '' }) => {
    let iconComponent = null;

    if (icon) {
        iconComponent = <div className={'inline-block align-middle mr-1'}>{icon}</div>;
    }

    return (
        <h2 className={`font-boldfont text-sm text-light mb-2 uppercase ${className}`}>
            {iconComponent}
            <span className={"inline-block align-middle"}>{children}</span>
        </h2>
    )
}

export default SectionTitle
