import React, {useEffect, useState} from 'react';
import ArticleElement from "./ArticleElement";
import IconArrowsUpDown from "../icons/IconArrowsUpDown";
import {ARTICLE_STATES} from "../../utils/utils";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

const ArticlePart = ({
                         id,
                         intro = false,
                         state,
                         articleID,
                         articlePart,
                         externalTitle,
                         onChangeExternalTitle,
                         highlight = [],
                         onChange
                     }) => {
    //hooks & constants
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: id,
        disabled: intro,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    //states
    const [tiles, setTiles] = useState([])

    //effects
    useEffect(() => {
        let parser = new DOMParser();
        let doc = parser.parseFromString(articlePart.text, 'text/html');
        let nodes = doc.body.childNodes;
        let htmltags = Array.from(nodes).map((node) => {
            return {
                //add uuid to key
                key: node.className ? node.className : 'c' + Math.floor(Math.random() * 10000000000000000),
                text: node.textContent.trim(),
                tag: node.tagName ? node.tagName.toLowerCase() : 'p'
            }
        })
        if (htmltags.length === 0) {
            htmltags.push({
                key: 0,
                text: '',
                tag: 'p'
            })
        }
        setTiles(htmltags);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articlePart.text])
    //methods
    const getTitle = () => {
        if (intro) {
            return <ArticleElement
                type={'h1'}
                multiline={false}
                value={externalTitle}
                highlight={highlight}
                onChange={onChangeExternalTitle}
            />
        }
        return <ArticleElement
            type={'h2'}
            multiline={false}
            value={articlePart.title}
            highlight={highlight}
            onChange={changePartTitle}
        />
    }

    const changePartText = (key, value, tagType) => {
        let index = tiles.findIndex((tile) => tile.key === key);
        let newTiles = [...tiles];
        newTiles[index].text = value;
        newTiles[index].tag = tagType;
        let text = createHTML(newTiles);
        let newPart = {...articlePart};
        newPart.text = text;
        onChange(newPart);
    }

    const createHTML = (tiles) => {
        let text = '';
        tiles.forEach((tile) => {
            text += '<' + tile.tag + ' class="' + tile.key + '">' + tile.text + '</' + tile.tag + '>';
        })

        return text;
    }

    const onDeletePartElement = (key) => {
        let newTiles = [...tiles];
        let index = newTiles.findIndex((tile) => tile.key === key);
        newTiles.splice(index, 1);
        let text = createHTML(newTiles);
        let newPart = {...articlePart};
        newPart.text = text;
        onChange(newPart);
        //set correct caret position
        if (index > 0) {
            setSelection(newTiles[index - 1].key, newTiles[index - 1].text.length);
        }
    }

    const onSplitPartElement = (key, position) => {
        let newTiles = [...tiles];
        let index = newTiles.findIndex((tile) => tile.key === key);
        let tile = newTiles[index];
        let firstTile = {...tile};
        let secondTile = {...tile};
        firstTile.text = tile.text.substring(0, position);
        firstTile.key = 'c' + Math.floor(Math.random() * 10000000000000000);
        secondTile.text = tile.text.substring(position);
        secondTile.key = 'c' + Math.floor(Math.random() * 10000000000000000);
        newTiles.splice(index, 1, firstTile, secondTile);
        let newText = createHTML(newTiles);
        let newPart = {...articlePart};
        newPart.text = newText;
        onChange(newPart);
        //set correct caret position
        setSelection(secondTile.key, 0);

    }

    const combinePartElements = (key) => {
        //combine with element before if exists
        let newTiles = [...tiles];
        let index = newTiles.findIndex((tile) => tile.key === key);
        if (index < 1) {
            return false;
        }
        let tile = newTiles[index];
        let previousTile = newTiles[index - 1];
        previousTile.text = previousTile.text + ' ' + tile.text;
        newTiles.splice(index, 1);
        let newText = createHTML(newTiles);
        let newPart = {...articlePart};
        newPart.text = newText;
        onChange(newPart);
        //set correct caret position
        setSelection(previousTile.key, previousTile.text.length - tile.text.length);
    }

    const setSelection = (id, position) => {
        window.setTimeout(() => {
            let element = document.querySelector('#' + id + ' textarea');
            if (!element) {
                return false;
            }

            element.focus();
            element.setSelectionRange(position, position);
        }, 100)
    }

    const changePartTitle = (value) => {
        let newPart = {...articlePart};
        newPart.title = value;
        onChange(newPart);
    }


//render
    return <div
        ref={intro ? null : setNodeRef}
        style={intro ? {} : style}
        className={'flex px-4 mb-3 pb-4 border-b last:border-b-0 border-dark'}>
        <div className={'grow'}>
            {getTitle()}
            {(state !== ARTICLE_STATES.OUTLINE && state !== ARTICLE_STATES.OUTLINE_ACCEPTED && state !== ARTICLE_STATES.OUTLINE_DENIED) && tiles.map((htmltag) => {
                return <ArticleElement
                    multiline={true}
                    key={htmltag.key}
                    id={htmltag.key}
                    articleID={articleID}
                    type={htmltag.tag}
                    value={htmltag.text}
                    highlight={highlight}
                    onChange={(value, tagType) => changePartText(htmltag.key, value, tagType)}
                    onDelete={() => onDeletePartElement(htmltag.key)}
                    onSplit={(position) => onSplitPartElement(htmltag.key, position)}
                    onCombine={() => combinePartElements(htmltag.key)}
                />
            })
            }
        </div>
        {!intro && <div {...attributes} {...listeners} className={'self-center pl-3 pb-2'}><IconArrowsUpDown/></div>}
    </div>

}

export default ArticlePart;
