import {useState} from "react";
import {apiGetDomainCategoryRefresh} from "../utils/api";
import {useTranslation} from "react-i18next";
import IconLoading from "./icons/IconLoading";
import toast from "react-hot-toast";
import Box from "./Box";
import SectionTitle from "./SectionTitle";

const BoxRefreshCategories = ({isWordPress, domainId, onChange = null}) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const handleClick = () => {
        setLoading(true);
        if (window.confirm(t('category.refreshConfirm'))) {
            setLoading(true);
            apiGetDomainCategoryRefresh(domainId)
                .then((response) => {
                    if (onChange) {
                        onChange(response.categories);
                    }
                    toast(response.message);
                }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }

    if(!isWordPress) {
        return null;
    }

    return <Box className={'h-full bg-dark pt-4'}>
        <SectionTitle icon={<IconLoading animated={false}/>}>{t('title.autoRefreshCategories')}</SectionTitle>
        <div className={'my-6 text-sm'}>
            {t('category.autoRefreshInfotext')}
        </div>
        <button
        disabled={loading}
        className={'rounded-lg text-white text-sm uppercase disabled:opacity-75 transition-all duration-300 font-boldfont py-2 px-4 bg-secondary hover:bg-secondary-dark'} onClick={handleClick}>
            <span>{t('button.refresh')}</span>
        </button>
    </Box>
};


export default BoxRefreshCategories;
