import React, {useState} from 'react'
import classnames from 'classnames'
import IconEye from "../icons/IconEye";
import IconEyeSlash from "../icons/IconEyeSlash";
import InfoBox from "../InfoBox";

const FieldText = ({className='', inputClassName = '', field, value, onChange, error }) => {
    if(typeof(error)==='function') {
        error = error()
    }

    const [showPassword, setShowPassword] = useState(false)
    return (
        <div className={"mb-4 relative " + className}>
            {field.label && <label className={'block pb-1'} htmlFor={field.name}>
                {field.label}
                {field.suffix && <InfoBox content={field.suffix}/>}
            </label>}
            <input
                type={(showPassword ? 'text' : field.type)}
                className={classnames("autofill:bg-darker focus:ring-0 focus:ring-offset-0 focus:border-primary block w-full rounded bg-darker border border-solid text-sm",(error!=='' ? 'border-danger' : 'border-secondary'), inputClassName)}
                id={field.name}
                name={field.name}
                value={value}
                onChange={(e)=>{
                    onChange(e.target.name, e.target.value);
                }}
                aria-describedby={field.name + 'Help'}
                placeholder={field.placeholder}
            />
            {field.type === "password" && <button type="button" className={classnames('absolute right-2 top-[2.3rem]', {'text-danger':error!==''})} onClick={() => setShowPassword(!showPassword)}>{(showPassword ? <IconEyeSlash/> : <IconEye/>)}</button>}
            {error!=='' && <small className={'text-danger block'}>{error}</small>}
        </div>
    )
}

export default FieldText
