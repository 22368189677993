import React from 'react';
import classNames from "classnames";
import IconCross from "./icons/IconCross";

const Modal = ({isOpen = true, setIsOpen, className,  children, disabled = false}) => {

    //states


    return (
        <div className={classNames(
            isOpen ? '' : 'hidden',
            'fixed flex items-center justify-center top-0 bg-dark bg-opacity-70 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-hidden h-full'
        )}>
            <div className={'relative bg-dark border border-secondary color-white w-full max-w-5xl max-h-full p-5 rounded-xl'+(className ? ' '+className : '')}>
                <button className={'text-white absolute top-4 right-4 transition-colors duration-300 enabled:hover:text-primary'} disabled={disabled} onClick={() => setIsOpen(false)}><IconCross/></button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
