import React from 'react';
import {formatterDecimals} from "../../utils/utils";
import {useTranslation} from "react-i18next";

const ArticleBar = ({single, title, value, valuePoints, max}) => {
    const percentage = (value / max) * 100;
    const {t, i18n} = useTranslation();

    let color = 'success';
    let colorText = t('article.perfect');
    if (valuePoints === 0) {
        color = 'danger';
        colorText = t('article.low')
    } else if (valuePoints === 1.25) {
        color = 'primary-light';
        colorText = t('article.ok')
    }

    return (
        <>
            <div className={'flex justify-between'}>
                <div>{title}: <span className={'text-xs text-' + color}>{colorText}</span></div>
                {!single && <div>{value}/{max}</div>}
                {single && <div>{formatterDecimals(value, i18n.language)}%</div>}
            </div>
            {!single && <div
                className={'mt-1 relative h-[3px] before:bg-' + color + ' before:bg-opacity-30 before:content-[""] before:absolute before:left-0 before:top-[1px] before:h-[1px]  before:w-full'}>
                <span style={{width: percentage + '%'}} className={'max-w-full block h-[3px] bg-' + color}></span>
            </div>}
        </>
    );
};

export default ArticleBar;
