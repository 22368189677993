import ArticleImage from "./ArticleImage";
import Box from "../Box";
import ArticleBar from "./ArticleBar";
import React from "react";
import {useTranslation} from "react-i18next";
import IconTag from "../icons/IconTag";
import FieldSelect from "../form/FieldSelect";
import {formatterDecimals} from "../../utils/utils";

const SidebarArticle = ({paragraphs, words, keywords, article, changeArticleImage, changeCategory}) => {
    const {t, i18n} = useTranslation();
    const wordsMax = article.article_parts.length * 300
    const maxParagraphs = article.article_parts.length * 4
    const activeKeywordsCount = keywords.reduce((total, keyword) => total + keyword.count, 0)
    const activeKeywordsAmount = keywords.filter(keyword => keyword.count > 0).length
    let totalPoints;

    const pointsCalculator = (val, min, mid) => {
        if(val >= min && val < mid){
            return 1.25
        } else if (val >= mid) {
            return 2.5
        }
        return 0
    }

    const wordsValue = words/wordsMax*100
    const wordsValuePoints = pointsCalculator(wordsValue, 55, 75)

    const paragraphsValue = paragraphs/maxParagraphs*100
    const paragraphsValuePoints = pointsCalculator(paragraphsValue, 55, 75)

    const keywordsCountValue = activeKeywordsAmount / 10 * 100
    const keywordsCountValuePoints = pointsCalculator(keywordsCountValue, 30, 50)

    const keywordsValue = activeKeywordsCount/words*100
    const keywordsValuePoints = pointsCalculator(keywordsValue, 2, 3)

    totalPoints = wordsValuePoints + paragraphsValuePoints + keywordsCountValuePoints + keywordsValuePoints
    if(article.image){
        totalPoints += 2
    }
    const pointsDevider = 10/12
    // methods
    const handleCategoryChange = (category) => {
        changeCategory(category)
    };

    return <>
        <ArticleImage article={article} changeArticleImage={changeArticleImage}/>
        {article.author.categories.length > 0 &&
            <Box className={'!p-3 mb-4'}>
                <div
                    className={"block relative before:content-[''] before:block before:ml-[-0.75rem] before:mr-[-0.75rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary"}>
                    <h2 className={'pb-3 pr-3 mr-8 uppercase text-white font-boldfont'}>{t('title.category')}</h2>
                </div>
                <div className={'pt-3'}>
                    <div className={'relative'}>
                        <IconTag className={'absolute left-2 top-[0.7rem] z-10 pointer-events-none'}/>
                        <FieldSelect
                            className={'!mb-0'}
                            selectClassName={'pl-8'}
                            field={{
                                name: 'suggestion-category-' + article.id,
                                validator: '',
                                values: article.author.categories.map((cat, key) => {
                                    return {value: cat.title, label: cat.title}
                                })
                            }}
                            value={article.category}
                            onChange={(name, value) => handleCategoryChange(value)}
                        />
                    </div>
                </div>
            </Box>
        }
        <Box className={'!p-3 mb-4'}>
            <div
                className={"block relative before:content-[''] before:block before:ml-[-0.75rem] before:mr-[-0.75rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary"}>
                <h2 className={'pb-3 pr-3 mr-8 uppercase text-white font-boldfont'}>{t('title.textStructure')}</h2>
            </div>
            <div className={'pt-3'}>
                <ArticleBar title={t('article.wordCount')} value={words} valuePoints={wordsValuePoints} max={wordsMax}/>
            </div>
            <div className={'pt-3 pb-3'}>
                <ArticleBar title={t('article.paragraphs')} value={paragraphs} valuePoints={paragraphsValuePoints} max={maxParagraphs}/>
            </div>
        </Box>
        <Box className={'!p-3 mb-4'}>
            <div
                className={"block relative before:content-[''] before:block before:ml-[-0.75rem] before:mr-[-0.75rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary"}>
                <h2 className={'pb-3 pr-3 mr-8 uppercase text-white font-boldfont'}>{t('title.keywords')}</h2>
            </div>
            <div className={'pt-3'}>
                <ArticleBar title={t('article.keywordCount')} value={activeKeywordsAmount} valuePoints={keywordsCountValuePoints} max={keywords.length}/>
            </div>
            <div className={'pt-3 pb-3'}>
                <ArticleBar single={true} title={t('article.keywordDensity')} value={keywordsValue} valuePoints={keywordsValuePoints}/>
            </div>
            <div className={'pt-3 pb-3'}>
                <div className={'whitespace-nowrap mr-4 mb-1 inline-block'}><span
                    className={'w-5 inline-block rounded-lg align-middle h-2 mr-2 bg-opacity-30 border border-solid border-success bg-success'}/><span
                    className={'inline-block align-middle'}>{t('article.goodUsage')}</span></div>
                <div className={'whitespace-nowrap mr-4 mb-1 inline-block'}><span
                    className={'w-5 inline-block rounded-lg align-middle h-2 mr-2 bg-opacity-30 border border-solid border-more bg-more'}/><span
                    className={'inline-block align-middle'}>{t('article.useMore')}</span></div>
                <div className={'whitespace-nowrap inline-block'}><span
                    className={'w-5 inline-block rounded-lg align-middle h-2 mr-2 bg-less bg-opacity-30 border border-solid border-less'}/><span
                    className={'inline-block align-middle'}>{t('article.useLess')}</span></div>
                <div className={'mt-4'}>
                    {Object.values(keywords).map((keyword) =>
                        <div
                            key={keyword.title}
                            className={'mr-2 mb-2 px-2 py-1 text-sm rounded-full inline-block align-top border border-solid ' + keyword.borderClassName + ' ' + keyword.className + ' bg-opacity-30'}>{keyword.title} {keyword.count ? <>({keyword.count}/{keyword.percentage}%)</> : null}
                        </div>
                    )}
                </div>
            </div>
        </Box>
        <Box className={'!p-3 mb-8 bg-primary bg-opacity-30'} borderClass={'border-primary'}>
            <div className={'flex justify-between'}>
                <h2 className={'text-white font-boldfont uppercase'}>{t('title.totalScore')}</h2>
                <div className={'text-white font-boldfont'}>{formatterDecimals(totalPoints*pointsDevider, i18n.language)}/10</div>
            </div>
        </Box>
    </>
}

export default SidebarArticle;
