import IconAdjustments from "./icons/IconAdjustments";
import IconCross from "./icons/IconCross";
import Form from "./form/Form";
import React, { useState, useEffect } from "react";
import {useTranslation} from "react-i18next";
import {apiPutAuthor} from "../utils/api";
import toast from "react-hot-toast";
import BoxCategory from "./BoxCategory";
import {Link} from "react-router-dom";
import InfoText from "./parts/InfoText";



const AuthorCategories = ({author, onChange = null}) => {
    //hooks & constants
    const {t} = useTranslation();

    //states
    const [editAuthorCategories, setEditAuthorCategories] = useState(false);
    const [loading, setLoading] = useState(false);

    //effects
    useEffect(() => {
        if (author.categories.length === 0 && author.domain.categories.length > 0) {
            setEditAuthorCategories(true);
        }
    }, [author]);

    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        const categoryObject = getCategoriesObject();
        const newValues = {...categoryObject, ...validated};
        const categoryIds = []
        Object.keys(newValues).forEach((key) => {
            if (newValues[key]) {
                categoryIds.push(key.replace('category_', ''));
            }
        });

        setLoading(true);
        apiPutAuthor(author.id, {categories: JSON.stringify(categoryIds)})
            .then((response) => {
                if (response.status) {
                    if (onChange) {
                        onChange(response.author.categories);
                    }
                    setEditAuthorCategories(false);
                    toast(response.message);
                } else {
                    formValidator.responseAdapter(response);
                    formValidator.validateAll();
                }
            }).finally(() => {
            setLoading(false);
        });
    }


    const authorCategoriesJSON = () => {
        const authorCategoriesJSON = {
            pages: [{
                title: t('author.editAuthorCategories'),
                fields: Object.keys(author.domain.categories).map((key) => {
                    const category = author.domain.categories[key];
                    return {
                        name: 'category_' + category.id,
                        type: 'toggle',
                        label: category.title,
                        suffix: '',
                        placeholder: '',
                        validator: '',
                        visibleWhen: ''
                    }
                })
            }]
        };


        authorCategoriesJSON.pages[0].fields.push({
            name: "",
            type: "submit-primary",
            label: t('button.saveSettings'),
            classes: "pt-4",
            suffix: ""
        });
        return authorCategoriesJSON;
    };

    const getCategoriesObject = () => {
        const categories = {};
        author.categories.forEach(category => {
            categories['category_' + category.id] = true;
        });
        return categories;
    }

    //render
    return (
        <div className={'relative'}>
            <InfoText
                description={t('author.categoriesDescription')}
                border={false}
            />
            {(author.domain.categories && author.domain.categories.length > 0) &&
                <button onClick={() => setEditAuthorCategories(!editAuthorCategories)}
                        className={'text-white absolute top-0 right-0'}>{(!editAuthorCategories ?
                    <IconAdjustments/> :
                    <IconCross/>)}</button>
            }
            {!editAuthorCategories ?
                <div className={'space-y-2'}>
                    {author.categories.length > 0 && author.categories.map((category) => {
                        return <div key={category.id}
                                    className={''}>
                            <BoxCategory
                                editable={false}
                                category={category}
                                isWordPress={author.domain.type === 'wordpress'}
                            />
                        </div>
                    })}
                    {author.categories.length === 0 && author.domain.categories.length > 0 &&
                        <h3 className={'text-white font-boldfont'}>{t('category.noCategories')}</h3>
                    }
                    {author.categories.length === 0 && author.domain.categories.length === 0 &&
                        <><h3 className={'text-white font-boldfont'}>{t('category.noCategoriesProject')}</h3><Link to={"/domains/" + author.domain_id} className={'rounded-lg text-white text-sm uppercase inline-block mt-4 transition-all duration-300 font-boldfont py-2 px-4 bg-secondary hover:bg-secondary-dark'}>{t('category.goToCat')}</Link></>
                    }
                </div>
                : <Form
                    loading={loading}
                    name={'editAuthorCategories'}
                    fields={authorCategoriesJSON()}
                    data={getCategoriesObject()}
                    onSubmit={handleSubmit}
                />
            }
        </div>
    )
}

export default AuthorCategories;
