import React from 'react'
import classnames from "classnames";

const FieldMultiRadio = ({ field, value, onChange, error }) => {

    const renderOptions = (options) => {
        return options.map((value,key)=>{
            return <label className="radio-inline" key={key}>
                <input type="radio" name={field.name} id={field.name} value={value.value} /> {value.label}
            </label>
        });
    }
    
    const getAdditionalText = () => {
        let option = getOption(value);
        if(!option){
            return '';
        }
        return option.additional;
    }

    const getOption = (val) =>{
        return field.options.find((elem)=>{
            return elem.value===val;
        })
    }
    
    return (
        <div className={classnames("form-group",{'text-danger':error!==''})} onChange={(e) => onChange(field.name, e.target.value)}>
            <label htmlFor={field.name} className={'control-label'}>{field.label}</label>
            {renderOptions(field.options)}
            {field.suffix && <small id={field.name + 'Help'} className="form-text text-muted">{field.suffix}</small>}
            <div>{getAdditionalText()}</div>
        </div>
    )
}

export default FieldMultiRadio