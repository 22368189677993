import IconAdjustments from "./icons/IconAdjustments";
import IconCross from "./icons/IconCross";
import FlexList from "./parts/FlexList";
import Form from "./form/Form";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {apiPutAuthor} from "../utils/api";
import toast from "react-hot-toast";
import InfoText from "./parts/InfoText";


const WritingRules = ({questions, writingRules, authorId, onChange = null}) => {
    //hooks & constants
    const {t} = useTranslation();

    //states
    const [editWritingRules, setEditWritingRules] = useState(false);
    const [loading, setLoading] = useState(false);
    const writingRulesComplete = writingRules && Object.keys(questions).every(key => writingRules[key]);

    //effects
    useEffect(() => {
        if (!writingRulesComplete) {
            setEditWritingRules(true);
        }
    }, [authorId, writingRulesComplete]);

    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        const newValues = {...writingRules, ...validated};
        setLoading(true);
        apiPutAuthor(authorId, {writing_rules: JSON.stringify(newValues)})
            .then((response) => {
                if (response.status) {
                    if (onChange) {
                        onChange(response.author.writing_rules);
                    }
                    toast(t('toast.author.writingRulesUpdated'));
                } else {
                    formValidator.responseAdapter(response);
                    formValidator.validateAll();
                }
            }).finally(() => {
            setLoading(false);
        });
    }


    const writingRulesJSON = () => {
        const writingRulesJSON = {
            pages: [{
                title: t('author.editWritingRules'),
                classes: 'sm:grid sm:grid-cols-2 sm:gap-4',
                fields: []
            }]
        };

        Object.keys(questions).forEach((key) => {
            const item = questions[key];
            const itemObj = {
                name: key,
                type: (item['type'] === 'textarea' ? 'text' : item['type']),
                label: item['label'],
                suffix: item['description'],
                placeholder: '',
                validator: 'required',
                visibleWhen: ''
            }

            if (item['type'] === 'select') {
                itemObj.values = [];
                itemObj.values.push({label: t('validator.pleaseSelect'), value: ''})
                Object.keys(item['choices']).forEach((val) => {
                    itemObj.values.push({label: item['choices'][val], value: val})
                })
                if (item['other']) {
                    itemObj.values.push({
                            label: t('author.other'), value: 'other'
                        }
                    )
                }
            }
            writingRulesJSON.pages[0].fields.push(itemObj);
            if (item['type'] === 'select' && item['other']) {
                writingRulesJSON.pages[0].fields.push({
                    name: key + '_other',
                    type: 'text',
                    visibleWhen: key + '==other',
                    label: item['label'] + ' (' + t('author.other') + ')',
                    suffix: '',
                    placeholder: '',
                    validator: ''
                });
            }

        });

        writingRulesJSON.pages[0].fields.push({
            name: "",
            type: "submit-primary",
            label: t('button.saveSettings'),
            classes: "col-span-2",
            suffix: ""
        });
        return writingRulesJSON;
    };

    //render
    return (
        <div className={'relative'}>
            <InfoText
                description={t('author.writingRulesDescription')}
                border={false}
            />
            <button onClick={() => setEditWritingRules(!editWritingRules)}
                    className={'text-white absolute top-0 right-0'}>{(!editWritingRules ?
                <IconAdjustments/> :
                <IconCross/>)}</button>
            {!editWritingRules ? <>
                <FlexList className={'space-y-0 !flex-row flex-wrap relative before:content-none sm:before:content-[""] before:block before:w-px before:absolute before:bottom-0 before:top-0 before:left-1/2 before:bg-secondary'} itemClasses={'basis-full sm:basis-1/2 py-2 sm:odd:pr-12 sm:even:pl-12'}
                          items={Object.keys(questions).map((key, index) => {
                              let value = writingRules?writingRules[key]:'';
                              let className = '';
                              if (!value) {
                                  value = t('globals.incomplete');
                                  className = 'text-danger'
                              } else if (questions[key]['type'] === 'select') {
                                  if(writingRules[key] === 'other'){
                                      value = writingRules[key + '_other'];
                                  } else{
                                        value = questions[key]['choices'][writingRules[key]];
                                  }
                              }
                              return {
                                  label: questions[key]['label'],
                                  value,
                                  className: className
                              }
                          })}
                />
            </> : <>
                <Form
                    loading={loading}
                    name={'editWritingRules'}
                    fields={writingRulesJSON()}
                    data={writingRules}
                    onSubmit={handleSubmit}
                />
            </>
            }
        </div>
    )
}

export default WritingRules
