import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
const Tabs = ({items, initialActiveItem}) => {
    //hooks & constants
    useNavigate();

    //states
    const [activeTab, setActiveTab] = useState(window.location.hash ? window.location.hash.replace('#', '') : initialActiveItem);

    //effects
    useEffect(() => {
        console.log(window.location.hash);
        if(window.location.hash){
            setActiveTab(window.location.hash.replace('#', ''));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.hash]);

    //methods

    //render
    return (
        <div className={''}>
            <div className={'flex gap-x-1 flex-wrap'}>
                {items && Object.keys(items).map((key, index) => {
                    const item = items[key];
                    let icon = null;
                    if(item.icon){
                        icon = <div className={'hidden lg:inline-block align-middle mr-1'}>{item.icon}</div>;
                    }
                    return <button key={key} onClick={() => {
                       window.location.hash = key;
                    }} className={classNames("w-full sm:w-auto text-white border-x border-t border-secondary py-2 px-4 xl:pr-4 sm:rounded-t-xl", (index === 0 ? 'rounded-t-xl' : ''), (key === activeTab ? 'bg-secondary' : ''))}>
                        {icon} <span className={"inline-block align-middle"}>{item.title}</span>
                    </button>
                }
                )}
            </div>
            <div>
                {items && Object.keys(items).map((key) => {
                        const item = items[key];
                        return <div key={key} className={classNames("border border-solid border-secondary rounded-b-xl sm:rounded-tr-xl relative p-6", (key === activeTab ? 'block' : 'hidden'))}>
                            {item.content}
                        </div>
                    }
                )}
            </div>
        </div>
    );
};

export default Tabs;
