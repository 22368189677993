import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../utils/Auth";
import {apiGetArticle, apiPutArticle} from "../../utils/api";
import {Link, useParams} from "react-router-dom";
import IconTime from "../../components/icons/IconTime";
import {ARTICLE_STATES, wordCounter, formatterDate, useInterval} from "../../utils/utils";
import IconProject from "../../components/icons/IconProject";
import IconLogIn from "../../components/icons/IconLogIn";
import IconTag from "../../components/icons/IconTag";
import IconState from "../../components/icons/IconState";
import FieldButton from "../../components/form/FieldButton";
import ArticlePart from "../../components/article/ArticlePart";
import toast from "react-hot-toast";
import SortableList from "../../components/SortableList";
import SidebarArticle from "../../components/article/SidebarArticle";
import Box from "../../components/Box";
import IconLoading from "../../components/icons/IconLoading";
import IconTrash from "../../components/icons/IconTrash";

const Article = () => {
    //hooks & constants
    const {t} = useTranslation();
    const {setPageAttributes} = useContext(AuthContext);
    const {id} = useParams();

    //states
    const [article, setArticle] = useState(null)
    const [loading, setLoading] = useState(false)

    //effects
    useEffect(() => {
        setPageAttributes(t('title.article'), [{title: t('title.articles'), href: '/articles'}]);
        getArticle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useInterval(() => {
        if(article && isGeneratingState(article.state.name)){
            getArticle();
        }
    },30000);

    //methods
    const getArticle = () => {
        apiGetArticle(id).then((response) => {
            if (response.status) {
                setArticle(response.article)
                setPageAttributes(response.article.title, [{title: t('title.articles'), href: '/articles'}]);
            }
        })
    }

    const getKeywords = () => {
        return Object.keys(article.author.writing_rules).filter((key) => {
            return key.startsWith('keyword') && article.author.writing_rules[key] !== '';
        }).map((key) => {
            return article.author.writing_rules[key];
        });

    }

    const wordCount = (searchword = '*') => {
        let count = wordCounter(article.title, searchword);
        article.article_parts.length > 0 && article.article_parts.forEach((articlePart) => {
            count = count + wordCounter(articlePart.title, searchword) + wordCounter(articlePart.text, searchword);
        });
        return count;
    }

    const paragraphCount = () => {
        let count = 0;
        article.article_parts.length > 0 && article.article_parts.forEach((articlePart) => {
                count+= articlePart.text.split('</').length-1;
        });

        return count;
    }

    const saveHelper = (nextState) => {
        setLoading(true);
        let obj = {
            state: nextState,
            title: article.title,
            category: article.category,
            article_parts: article.article_parts.map((articlePart, index) => {
                return {
                    id: articlePart.id,
                    title: articlePart.title,
                    text: articlePart.text,
                    order: index
                }
            })
        }
        if (article.image_id) {
            obj.image_id = article.image_id;
        }

        apiPutArticle(article.id, obj).then((response) => {
            toast.success(response.message);
            setArticle(response.article);
        }).finally(() => {
            setLoading(false);
        })
    }

    const saveOutline = () => {
        saveHelper(ARTICLE_STATES.OUTLINE);
    }

    const acceptOutline = () => {
        saveHelper(ARTICLE_STATES.OUTLINE_ACCEPTED);
    }

    const denyOutline = () => {
        saveHelper(ARTICLE_STATES.OUTLINE_DENIED);
    }

    const saveArticle = () => {
        saveHelper(ARTICLE_STATES.ARTICLE);
    }

    const denyArticle = () => {
        saveHelper(ARTICLE_STATES.ARTICLE_DENIED);
    }

    const publishArticle = () => {
        saveHelper(ARTICLE_STATES.WAITING_FOR_PUBLISH);
    }

    const onChangeOrder = (oldId, newId) => {
        let articleParts = article.article_parts;
        const oldIndex = articleParts.findIndex((articlePart) => {
            return 'article-part-' + articlePart.id === oldId;
        });
        const newIndex = articleParts.findIndex((articlePart) => {
            return 'article-part-' + articlePart.id === newId;
        });
        if (oldIndex !== -1 && newIndex !== -1) {
            articleParts.splice(newIndex, 0, articleParts.splice(oldIndex, 1)[0]);
            setArticle({...article, article_parts: articleParts});
        }
    }

    const onChangeMainTitle = (value) => {
        setArticle({...article, title: value});
    }

    const onChangeCategory = (value) => {
        setArticle({...article, category: value});
    }

    const onChangeArticleImage = (image) => {
        setArticle({...article, image: image, image_id: image.id});
    }

    const onChangeArticlePart = (articlePart) => {
        let articleParts = article.article_parts;
        const index = articleParts.findIndex((articlePartItem) => {
            return articlePartItem.id === articlePart.id;
        });
        if (index !== -1) {
            articleParts[index] = articlePart;
            setArticle({...article, article_parts: articleParts});
        }
    }


    //render
    if (!article) return null;
    const words = wordCount();
    const paragraphs = paragraphCount();
    let usedKeywords = 0;
    getKeywords().forEach((keyword) => {
        if(wordCount(keyword) > 0){
            usedKeywords++;
        }
    });
    const keywords = getKeywords().map((keyword) => {
        const count = wordCount(keyword);
        const percentage = Math.round((count / words) * 10000) / 100;
        let className;
        let borderClassName;
        if (percentage === 0) {
            //spamy keywords
            className = '';
            borderClassName = '';
        } else if (percentage > 5/usedKeywords) {
            //spamy keywords
            className = 'bg-less';
            borderClassName = 'border-less';
        } else if (percentage < 3/usedKeywords) {
            //warning keywords
            className = 'bg-more';
            borderClassName = 'border-more';
        } else {
            //success keywords
            className = 'bg-success-dark';
            borderClassName = 'border-success-dark';
        }

        return {
            title: keyword,
            count,
            percentage,
            className,
            borderClassName
        };
    });
    //order by percentage
    keywords.sort((a, b) => {
        return b.percentage - a.percentage;
    });
    const highlights = keywords.map((keyword) => {
        return {
            highlight: keyword.title,
            className: keyword.className + ' bg-opacity-50'
        }
    });

    const isGeneratingState = (stateName) => {
        return stateName === ARTICLE_STATES.OUTLINE_GENERATING ||
            stateName === ARTICLE_STATES.ARTICLE_GENERATING ||
            stateName === ARTICLE_STATES.WAITING_FOR_PUBLISH ||
            stateName === ARTICLE_STATES.PUBLISHING;
    }


    return article && <div className={'grid gap-4 grid-cols-12'}>
        <div className={'col-span-12'}>
            <div className={'inline-flex items-center mr-4'}><IconTime/>&nbsp;
                <span>{formatterDate(article.created_at)}</span></div>
            <Link to={'/domains/' + article.author.domain.id}
                  className={'inline-flex items-center mr-4 text-white transition-colors duration-300 hover:text-primary'}><IconProject/>&nbsp;
                <span>{article.author.domain.domain_name}</span></Link>
            <Link to={'/authors/' + article.author.id}
                  className={'inline-flex items-center mr-4 text-white transition-colors duration-300 hover:text-primary'}><IconLogIn/>&nbsp;
                <span>{article.author.name}</span></Link>
            <div className={'inline-flex items-center mr-4'}><IconTag/>&nbsp;<span>{article.category}</span></div>
            <div className={'inline-flex items-center mr-4'}><IconState/>&nbsp;<span>{article.state.display_name}</span>
            </div>
        </div>
        {
            isGeneratingState(article.state.name) &&
            <div className={'col-span-12 pt-6 flex flex-wrap items-center justify-center'}>
                <IconLoading animated={true} className={'!w-16 !h-16 text-primary'}/>
                <div className={'w-full text-center mt-2 mb-4 text-primary'}>{article.state.display_name}</div>
            </div>
        }
        {
            !isGeneratingState(article.state.name) &&
            <div className={'col-span-12 lg:col-span-8 bg-white text-darker pt-6'}>
                <SortableList onChangeOrder={onChangeOrder}>
                    {
                        article.article_parts.length > 0 && article.article_parts.map((articlePart, index) => {
                            return <ArticlePart
                                key={'article-part-' + articlePart.id}
                                id={'article-part-' + articlePart.id}
                                articleID={article.id}
                                state={article.state.name}
                                externalTitle={article.title}
                                articlePart={articlePart}
                                highlight={highlights}
                                onChangeExternalTitle={onChangeMainTitle}
                                onChange={onChangeArticlePart}
                                intro={index === 0}
                            />
                        })
                    }
                </SortableList>
            </div>
        }
        <div className={'col-span-12 lg:col-span-4'}>
            {article.state.name === ARTICLE_STATES.ARTICLE &&
                <>
                    <SidebarArticle
                    paragraphs={paragraphs}
                    words={words}
                    keywords={keywords}
                    article={article}
                    changeArticleImage={onChangeArticleImage}
                    changeCategory={onChangeCategory}
                    />
                    <div className={'flex flex-wrap gap-2'}>
                        <FieldButton
                            field={{
                                label: t('button.save_publish')
                            }}
                            disabled={loading}
                            primary={true}
                            onClick={publishArticle}
                        />
                        <FieldButton
                            field={{
                                label: t('button.just_save')
                            }}
                            disabled={loading}
                            primary={false}
                            onClick={saveArticle}
                        />
                        <button
                            onClick={denyArticle}
                            disabled={loading}
                            className={'bg-danger rounded-xl text-white text-center py-1 px-2 hover:bg-danger-dark transition-colors duration-300'}>
                            <IconTrash className={'!w-5 !h-5 !stroke-white'}/>
                        </button>
                    </div>
                </>
            }
            {article.state.name === ARTICLE_STATES.ARTICLE_DENIED &&
                <FieldButton
                    field={{
                        label: t('button.restoreArticle')
                    }}
                    disabled={loading}
                    primary={false}
                    onClick={saveArticle}
                />

            }
            {article.state.name === ARTICLE_STATES.WAITING_FOR_PUBLISH &&
                <FieldButton
                    field={{
                        label: t('button.revokeArticle')
                    }}
                    disabled={loading}
                    primary={false}
                    onClick={saveArticle}
                />
            }
            {article.state.name === ARTICLE_STATES.OUTLINE &&
                <div className={'flex flex-wrap gap-2'}>
                    <FieldButton
                        field={{
                            label: t('button.generate_article')
                        }}
                        disabled={loading}
                        primary={true}
                        onClick={acceptOutline}
                    />
                    <FieldButton
                        field={{
                            label: t('button.just_save')
                        }}
                        disabled={loading}
                        primary={false}
                        onClick={saveOutline}
                    />
                    <button
                        onClick={denyOutline}
                        disabled={loading}
                        className={'bg-danger rounded-xl text-white text-center py-1 px-2 hover:bg-danger-dark transition-colors duration-300'}>
                        <IconTrash className={'!w-5 !h-5 !stroke-white'}/>
                    </button>
                </div>
            }
            {article.state.name === ARTICLE_STATES.OUTLINE_DENIED &&
                <FieldButton
                    field={{
                        label: t('button.restoreArticle')
                    }}
                    disabled={loading}
                    primary={false}
                    onClick={saveOutline}
                />
            }
            {article.state.name === ARTICLE_STATES.OUTLINE_ACCEPTED &&
                <FieldButton
                    field={{
                        label: t('button.revokeArticle')
                    }}
                    disabled={loading}
                    primary={false}
                    onClick={saveOutline}
                />
            }
            {article.state.name === ARTICLE_STATES.PUBLISHED &&
                <>
                    {article.author.domain.type === "wordpress" &&
                        <Box className={'!p-3 mb-4 bg-secondary'}>
                            <div
                                className={"block relative before:content-[''] before:block before:ml-[-0.75rem] before:mr-[-0.75rem] before:h-px before:absolute before:bottom-0 before:right-0 before:left-0 before:bg-secondary-dark"}>
                                <h2 className={'pb-3 pr-3 mr-8 uppercase text-white font-boldfont'}>{t('title.wordpress')}</h2>
                            </div>
                            <div className={'pt-3'}>
                                <a href={article.author.domain.url + '/wp-admin/post.php?post=' + article.wp_post_id + '&action=edit'}
                                   rel="noreferrer"
                                   target={'_blank'}
                                   className={'mr-2 inline-block relative rounded-lg text-white text-sm uppercase disabled:opacity-75 transition-all duration-300 font-boldfont py-2 px-4 bg-darker hover:bg-dark'}>
                                    {t('button.editArticle')}
                                </a>
                                <a href={article.author.domain.url + '/?p=' + article.wp_post_id + ''} rel="noreferrer" target={'_blank'}
                                   className={'inline-block relative rounded-lg text-white text-sm uppercase disabled:opacity-75 transition-all duration-300 font-boldfont py-2 px-4 bg-[length:200%_200%] bg-left-top bg-gradient-to-br from-primary via-primary-light to-primary enabled:hover:bg-[99%] shadow-[0_3px_10px_rgba(0,0,0,0.3)]'}>
                                    {t('button.toArticle')}
                                </a>
                            </div>
                        </Box>
                    }
                    <SidebarArticle
                        paragraphs={paragraphs}
                        words={words}
                        keywords={keywords}
                        article={article}
                        changeArticleImage={onChangeArticleImage}
                        changeCategory={onChangeCategory}
                    />
                </>
            }
        </div>
    </div>
}


export default Article
