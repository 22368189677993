import React from 'react';

const IconArrow = ({className = ''}) => {
  return (

  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 19" strokeWidth={1} stroke="currentColor" className={"w-16 h-4 stroke-primary " + className}>
    <path d="M7.5,15.4L1.1,9c-0.4-0.4-0.4-1.2,0-1.6l6.4-6.4C7.7,0.9,8,0.8,8.3,0.8h49.3c1.2,0,2.2,1,2.2,2.2v10.5
	c0,1.2-1,2.2-2.2,2.2H8.3C8,15.8,7.7,15.6,7.5,15.4z"/>
    <g>
      <path d="M8.8,4.4c0.5-0.1,1.2-0.2,2-0.2c1,0,1.8,0.2,2.3,0.7c0.4,0.4,0.7,1,0.7,1.7c0,0.7-0.2,1.3-0.6,1.7
		c-0.6,0.6-1.5,0.9-2.5,0.9c-0.3,0-0.6,0-0.8-0.1v3.2h-1V4.4z M9.9,8.3c0.2,0.1,0.5,0.1,0.9,0.1c1.3,0,2-0.6,2-1.7
		c0-1.1-0.8-1.6-1.9-1.6c-0.5,0-0.8,0-1,0.1V8.3z"/>
      <path d="M15.2,4.4c0.5-0.1,1.3-0.2,2-0.2c1.1,0,1.8,0.2,2.3,0.7c0.4,0.4,0.6,0.9,0.6,1.5c0,1.1-0.7,1.8-1.5,2.1v0
		c0.6,0.2,1,0.8,1.2,1.6c0.3,1.1,0.5,1.9,0.6,2.2h-1.1c-0.1-0.2-0.3-0.9-0.5-1.9c-0.2-1.1-0.7-1.5-1.6-1.6h-1v3.5h-1V4.4z M16.2,8.1
		h1.1c1.1,0,1.8-0.6,1.8-1.5c0-1-0.8-1.5-1.9-1.5c-0.5,0-0.9,0-1,0.1V8.1z"/>
      <path d="M25.8,8.6h-3.1v2.9h3.5v0.9h-4.5V4.3H26v0.9h-3.3v2.6h3.1V8.6z"/>
      <path d="M34.4,8.8c-0.1-1.1-0.1-2.5-0.1-3.5h0c-0.3,0.9-0.6,2-1,3.1l-1.4,3.9H31l-1.3-3.9c-0.4-1.1-0.7-2.2-0.9-3.1h0
		c0,1-0.1,2.4-0.2,3.6l-0.2,3.5h-1l0.6-8.1h1.3l1.4,3.9c0.3,1,0.6,1.9,0.8,2.7h0c0.2-0.8,0.5-1.7,0.9-2.7l1.4-3.9h1.3l0.5,8.1h-1
		L34.4,8.8z"/>
      <path d="M38.2,4.3v8.1h-1V4.3H38.2z"/>
      <path d="M41.1,4.3v4.8c0,1.8,0.8,2.6,1.9,2.6c1.2,0,2-0.8,2-2.6V4.3H46V9c0,2.5-1.3,3.5-3.1,3.5c-1.7,0-2.9-0.9-2.9-3.5V4.3H41.1z"
      />
      <path d="M54.6,8.8c-0.1-1.1-0.1-2.5-0.1-3.5h0c-0.3,0.9-0.6,2-1,3.1L52,12.4h-0.8l-1.3-3.9c-0.4-1.1-0.7-2.2-0.9-3.1h0
		c0,1-0.1,2.4-0.2,3.6l-0.2,3.5h-1l0.6-8.1h1.3l1.4,3.9c0.3,1,0.6,1.9,0.8,2.7h0c0.2-0.8,0.5-1.7,0.9-2.7L54,4.3h1.3l0.5,8.1h-1
		L54.6,8.8z"/>
    </g>
  </svg>
  );
};

export default IconArrow;
