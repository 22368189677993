import React from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import IconCopy from './icons/IconCopy';

const ClipboardButton = ({ link }) => {
    const {t} = useTranslation();

    const copyToClipboard = () => {
        let copyPromise = navigator.clipboard.writeText(link);
        copyPromise.then(() => {
            toast.success(t('copy.link.success'),{icon: <IconCopy/>});
        }).catch((error) => {
            toast.error(t('copy.link.error'));
        });

    };

    return (
        <>
            <button
                onClick={copyToClipboard}
                className="inline-block bg-secondary border-none rounded text-white text-center mt-2 py-3 ml-1 px-2 hover:border-primary hover:bg-primary transition-colors duration-300">
                <IconCopy />
            </button>
        </>
    );
};

export default ClipboardButton
