import Section from "../../components/Section";
import Box from "../../components/Box";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {apiDeleteAuthor, apiGetAuthor, apiPostAuthorLogout, apiPutAuthor} from "../../utils/api";
import {Link, useNavigate, useParams} from "react-router-dom";
import FlexList from '../../components/parts/FlexList';
import IconTag from "../../components/icons/IconTag";
import Tabs from "../../components/Tabs";
import IconPencil from "../../components/icons/IconPencil";
import IconWordPress from "../../components/icons/IconWordPress";
import {AuthContext} from "../../utils/Auth";
import toast from "react-hot-toast";
import IconLoading from "../../components/icons/IconLoading";
import {translateLoginState} from "../../utils/utils";
import WritingRules from "../../components/WritingRules";
import IconTrash from "../../components/icons/IconTrash";
import LinkWithNumber from "../../components/LinkWithNumber";
import AuthorCategories from "../../components/AuthorCategories";
import FormTranslated from "../../components/form/FormTranslated";
import IconKey from "../../components/icons/IconKey";
import Keywords from "../../components/Keywords";
import IconLink from "../../components/icons/IconLink";
import BoxLink from "../../components/BoxLink";
import BoxCreateLink from "../../components/BoxCreateLink";
import AuthorHome from "../../components/AuthorHome";
import IconLogIn from "../../components/icons/IconLogIn";
import ButtonSecondary from "../../components/ButtonSecondary";
import IconArrowLeft from "../../components/icons/IconArrowLeft";
import IconLightBulb from "../../components/icons/IconLightBulb";
import InfoText from "../../components/parts/InfoText";
import Form from "../../components/form/Form";

const Author = () => {
    //hooks & constants
    const {id} = useParams();
    const {t} = useTranslation();
    const {setPageAttributes} = useContext(AuthContext);
    const navigate = useNavigate();

    //states
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [author, setAuthor] = useState(null)
    const [currPostType, setCurrPostType] = useState(null)

    //effects
    useEffect(() => {
        apiGetAuthor(id).then((response) => {
            if (response.status) {
                setAuthor(response.author)
                setCurrPostType(response.author.wp_post_type)
                setPageAttributes(t('title.author') + ': ' + response.author.name, [{
                    title: response.author.domain.domain_name,
                    href: '/domains/' + response.author.domain.id + '#authors'
                }]);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    //methods
    const deleteAuthor = () => {
        if (window.confirm(t('author.deleteConfirm'))) {
            setLoading(true);
            apiDeleteAuthor(author.id)
                .then((response) => {
                        if (response.status) {
                            toast(response.message);
                            navigate('/domains/' + author.domain_id + '#authors');
                        } else {
                            toast(response.message);
                            setLoading(false);
                        }
                    }
                );
        } else {
            setLoading(false);
        }
    }


    const authorInfos = () => {
        let infoArray = [
            {label: t('globals.name'), value: author.name},
            {
                label: t('author.project'),
                value: <Link className="transition-colors text-white duration-300 hover:text-primary"
                             to={"/domains/" + author.domain_id}>{author.domain.domain_name.replace('www.', '')}</Link>
            },
            {
                label: t('author.categories'),
                value: <Link to={'#categories'}
                             className={'transition-colors duration-300 hover:text-primary font-mono'}>{author.categories.length}</Link>,
                className: (author.categories.length === 0 ? 'text-danger' : 'text-white')
            },
            {
                label: t('author.writingRules'),
                value: <Link to={'#writingRules'}
                             className={'transition-colors duration-300 hover:text-primary'}>{!writingRulesComplete ? t('globals.incomplete') : t('globals.complete')}</Link>,
                className: !writingRulesComplete ? 'text-danger' : 'text-white'
            },
            {
                label: t('author.keywords'),
                value: <Link to={'#keywords'}
                             className={'transition-colors duration-300 hover:text-primary'}>{!keywordsComplete ? t('globals.incomplete') : t('globals.complete')}</Link>,
                className: !keywordsComplete ? 'text-danger' : 'text-white'
            }
        ];
        if (author.domain.type === 'wordpress') {
            infoArray.push({
                label: t('author.wordpressApi'),
                value: <Link to={'#wordpressApi'}
                             className={'transition-colors duration-300 hover:text-primary'}>{translateLoginState(author.wp_login_state)}</Link>,
                className: (!author.wp_login_state || author.wp_login_state !== 'loggedin') ? 'text-danger' : 'text-white'
            });
        }

        return infoArray;
    }


    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPutAuthor(author.id, validated)
            .then((response) => {
                if (response.status) {
                    setEdit(false);
                    setAuthor({...author, ...response.author});
                    toast(response.message);
                } else {
                    formValidator.responseAdapter(response);
                    formValidator.validateAll();
                }
            }).finally(() => {
            setLoading(false);
        });
    }

    function getQuestionsFiltered(objects, keyword, include) {
        const filteredObjects = {};
        for (const key in objects) {
            if (objects.hasOwnProperty(key)) {
                const keyContainsKeyword = key.includes(keyword);
                if ((include && keyContainsKeyword) || (!include && !keyContainsKeyword)) {
                    filteredObjects[key] = objects[key];
                }
            }
        }
        return filteredObjects;
    }

    const apiPostTypeTaxonomyJSON = () => {
        const apiPostTypeTaxonomyJSON = {
            pages: [{
                title: t('author.editPostTypeTaxonomy'),
                classes: 'sm:grid sm:grid-cols-2 sm:gap-4',
                fields: []
            }]
        };

        const postType = {
            name: 'wp_post_type',
            type: 'select',
            label: t('author.postType'),
            suffix: '',
            placeholder: '',
            validator: '',
            values: []
        }

        Object.keys(author.wp_meta).forEach((key) => {
            const item = author.wp_meta[key];
            postType.values.push({label: item['label'] + ' (' + key + ')', value: key})
        })

        apiPostTypeTaxonomyJSON.pages[0].fields.push(postType);

        const postTaxonomy = {
            name: 'wp_taxonomy_type',
            type: 'select',
            label: t('author.taxonomyName'),
            suffix: '',
            placeholder: '',
            validator: '',
            values: []
        }

        if (author.wp_meta[currPostType]) {
            Object.keys(author.wp_meta[currPostType]['taxonomies']).forEach((key) => {
                const taxonomy = author.wp_meta[currPostType]['taxonomies'][key];
                postTaxonomy.values.push({label: taxonomy + ' (' + key + ')', value: key})
            })
        }

        apiPostTypeTaxonomyJSON.pages[0].fields.push(postTaxonomy);
        apiPostTypeTaxonomyJSON.pages[0].fields.push({
            name: "",
            type: "submit-primary",
            label: t('button.saveSettings'),
            classes: "col-span-2",
            suffix: ""
        });
        return apiPostTypeTaxonomyJSON;
    };

    const tabs = () => {
        let tabItems = {
            info: {
                title: <span className={'lg:hidden'}>{t('author.home')}</span>,
                icon: <IconLightBulb className={'lg:mr-[-0.4rem]'}/>,
                content: <AuthorHome/>
            },
            categories: {
                title: t('author.categories'),
                icon: <IconTag/>,
                content:
                    <AuthorCategories author={author}
                                      onChange={(authorCategories) => {
                                          setAuthor({...author, categories: authorCategories})
                                      }}/>
            },
            writingRules: {
                title: t('author.writingRules'),
                icon: <IconPencil/>,
                content:
                    <WritingRules
                        writingRules={author.writing_rules}
                        questions={writingRuleQuestions}
                        authorId={author.id}
                        onChange={(writingRules) => {
                            setAuthor({...author, writing_rules: writingRules})
                        }}
                    />
            },
            keywords: {
                title: t('author.keywords'),
                icon: <IconKey/>,
                content:
                    <Keywords
                        keywords={author.writing_rules}
                        questions={keywordQuestions}
                        authorId={author.id}
                        onChange={(keywords) => {
                            setAuthor({...author, writing_rules: keywords})
                        }}
                    />
            },
            links: {
                title: t('author.links'),
                icon: <IconLink/>,
                content: <div className={''}>
                    <div className={'space-y-2 pb-4'}>
                        <InfoText
                            description={t('author.linksDescription')}
                            border={false}
                        />
                        {author.links.map((link, index) => {
                            return <div key={link.id} className={''}>
                                <BoxLink
                                    link={link}
                                    onChange={(link) => {
                                        if (!link) {
                                            //is deleted
                                            const links = [...author.links];
                                            links.splice(index, 1);
                                            setAuthor({...author, links})
                                        } else {
                                            //is edited
                                            const links = [...author.links];
                                            links[index] = link;
                                            setAuthor({...author, links})
                                        }
                                    }}
                                />
                            </div>
                        })}
                    </div>
                    <div className={'grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2'}>
                        <BoxCreateLink
                            authorId={author.id}
                            onChange={(link) => {
                                const links = [...author.links];
                                links.push(link);
                                setAuthor({...author, links})
                            }}
                        />
                    </div>
                </div>
            },
            wordpressApi: {
                title: t('author.wordpressApi'),
                icon: <IconWordPress/>,
                content:
                    <div className={''}>
                        <InfoText
                            description={t('author.wordpressApiDescription')}
                            border={false}
                        />
                        {author.wp_login_state !== 'loggedin' ?
                            <FormTranslated
                                loading={loading}
                                name={'apiConnection'}
                                filename={'apiConnection'}
                                data={{
                                    wp_login_email: author.wp_login_email,
                                    wp_login_token: author.wp_login_token
                                }}
                                onSubmit={handleSubmit}
                            /> : <>
                                <h3 className={'uppercase mb-4 text-white font-boldfont'}>{t('globals.connected')}</h3>
                                <small>{t('author.loginEmail')}</small><br/>{author.wp_login_email}<br/><br/>
                                <small>{t('author.loginToken')}</small><br/>{author.wp_login_token}<br/><br/>
                                <button onClick={() => {
                                    setLoading(true);
                                    apiPostAuthorLogout(author.id)
                                        .then((response) => {
                                            if (response.status) {
                                                setAuthor({...author, ...response.author});
                                                toast(response.message);
                                            }
                                        }).finally(() => {
                                        setLoading(false);
                                    });
                                }}
                                        className={'rounded-lg text-white text-sm uppercase disabled:opacity-75 transition-all duration-300 font-boldfont py-2 px-4 bg-secondary hover:bg-secondary-dark'}
                                        disabled={loading}>
                                    {(loading ? <IconLoading className={'mx-auto'}/> : t('button.disconnect'))}
                                </button>

                                {author.wp_meta &&
                                    <div className={'mt-8 pt-7 border-t border-dashed border-secondary'}>
                                        <Form
                                            loading={loading}
                                            name={'apiPostTypeTaxonomy'}
                                            fields={apiPostTypeTaxonomyJSON()}
                                            data={{
                                                wp_post_type: author.wp_post_type,
                                                wp_taxonomy_type: author.wp_taxonomy_type,
                                            }}
                                            onChange={(fieldData, fieldName, newValue) => {
                                                if (fieldName === 'wp_post_type') {
                                                    setCurrPostType(newValue);
                                                }
                                            }}
                                            onSubmit={handleSubmit}
                                        />
                                    </div>
                                }

                            </>
                        }
                        <InfoText
                            description={<>
                                <p className={'mt-8 pt-7 border-t border-dashed border-secondary'}>{t('author.wordpressApiDescriptionTab')}</p>
                                <br/>
                                <ul className={'list-decimal'}>
                                    {t('author.wordpressApiSteps', {returnObjects: true}).map((step, index) => (
                                        <li className={'mt-2 ml-3'} key={index}>{step}</li>
                                    ))}
                                </ul>
                            </>}
                            border={false}
                        />
                    </div>
            }
        }
        if (author.domain.type !== 'wordpress') {
            delete tabItems.wordpressApi;
        }

        return tabItems;
    }

    //render
    let keywordQuestions = author ? getQuestionsFiltered(author.questions, 'keyword', true) : {};
    let writingRuleQuestions = author ? getQuestionsFiltered(author.questions, 'keyword', false) : {};
    let keywordsComplete = author && author.writing_rules && Object.keys(keywordQuestions).every(key => author.writing_rules[key]);
    let writingRulesComplete = author && author.writing_rules &&
        Object.keys(writingRuleQuestions).every(key => author.writing_rules[key]);
    return author && <div className={"grid gap-5 grid-cols grid-cols-12"}>
        <div className={'col-span-12 lg:col-span-4 xl:col-span-3'}>
            <div className="mb-7">
                <ButtonSecondary
                    title={t('author.backToProject')}
                    icon={<IconArrowLeft/>}
                    onClick={() => {
                        navigate('/domains/' + author.domain_id + '#authors');
                    }}
                /></div>
            <Section icon={<IconLogIn/>}
                     title={t('title.authorInfo')}>
                <Box className={'bg-secondary col-span-4 !pt-12'} icon={true} iconState={edit}
                     iconFunction={() => setEdit(!edit)}>
                    {!edit ? <>
                        <FlexList items={authorInfos()}/>
                        <hr className="border-dark mt-8 ml-[calc(-1.5rem-1px)] mr-[calc(-1.5rem-1px)]"/>
                        <br/>
                        <LinkWithNumber flex={true} num={author.suggestion_count} numClassName="w-8"
                                        href={'/articles/?author_id=' + author.id + '&state=suggestion'}
                                        text={t('project.articleSuggestions')}/>
                        <LinkWithNumber flex={true} num={author.outline_count} numClassName="w-8"
                                        href={'/articles/?author_id=' + author.id + '&state=outline'}
                                        text={t('project.articleOutlines')}/>
                        <LinkWithNumber flex={true} num={author.unpublished_count} numClassName="w-8"
                                        href={'/articles/?author_id=' + author.id + '&state=article'}
                                        text={t('project.articlesUnpublished')}/>
                        <LinkWithNumber flex={true} num={author.published_count} numClassName="w-8"
                                        href={'/articles/?pauthor_id=' + author.id + '&state=published'}
                                        text={t('project.articlesPublished')}/>
                    </> : <>
                        <button className="absolute top-3 right-12 text-white" onClick={deleteAuthor}>
                            <IconTrash/></button>
                        <FormTranslated
                            loading={loading}
                            name={'editAuthor'}
                            filename={'editAuthor'}
                            data={{name: author.name}}
                            onSubmit={handleSubmit}
                        />
                    </>}
                </Box>
            </Section></div>
        <div className={'col-span-12 lg:col-span-8 xl:col-span-9'}>
            <Tabs initialActiveItem={'info'} items={tabs()}/>
        </div>
    </div>
}


export default Author
