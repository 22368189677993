import React, {useEffect, useState} from 'react'
import FieldText from "./FieldText";
import FieldCurrency from "./FieldCurrency";
import FieldCurrencyDecimal from "./FieldCurrencyDecimal";
import FieldButton from "./FieldButton";
import FieldOptions from "./FieldOptions";
import FieldSelect from "./FieldSelect";
import FieldToggle from "./FieldToggle";
import FieldSingleCheckbox from "./FieldSingleCheckbox";
import FieldMultiRadio from "./FieldMultiRadio";
import {SwitchTransition, CSSTransition} from "react-transition-group";
import FieldInfobox from "./FieldInfobox";
import FieldCurrencySelect from "./FieldCurrencySelect";
import FieldSetCurrencySelect from "./FieldSetCurrencySelect";
import FormValidation from "../../utils/FormValidation";
import FieldTextArea from "./FieldTextArea";

const Form = ({
                  data,
                  onChange,
                  mode,
                  externalData,
                  fields,
                  //name = 'noname',
                  onChangePage,
                  loading,
                  onSubmit,
                  extraMessage
              }) => {

    const [page, setPage] = useState(0);
    const [values, setValues] = useState({
        changed: data || {},
        original: data || {},
        errors: {},
        validated: data || {},
    });
    const [formValidator, setFormValidator] = useState(null);
    useEffect(() => {
        const formValidator = new FormValidation(values, setValues.bind(this));
        formValidator.addFieldValidations(getValidations());
        if (externalData) {
            formValidator.addExternalData(externalData);
        }
        setFormValidator(formValidator);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!mode || mode !== 'controlled') return () => {};
        setValues({
            changed: data || {},
            original: data || {},
            errors: {},
            validated: {},
        })
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if(formValidator)formValidator.newValues(values);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);


    const getValidations = (allFields = false) => {
        let validators = {};
        if (allFields) {
            fields.pages.forEach((page) => {
                page.fields.forEach((field) => {
                    if (field.validator) {
                        validators[field.name] = field.validator;
                    }
                });
            });
        } else {
            //just current page
            fields.pages[page].fields.forEach((field) => {
                if (field.validator) {
                    validators[field.name] = field.validator;
                }
            });
        }
        return validators;
    }

    const setValue = (name, value) => {
        formValidator.handleFieldChange(name, value);
        if (onChange) {
            onChange(values, name, value);
        }
    }

    const nextPageHandler = () => {
        if (fields.pages.length >= page) {
            setPage(page + 1);
            formValidator.addFieldValidations(getValidations());
        }
    }

    const nextPage = () => {
        if (!formValidator.validateAll(values)) return '';
        if (onChangePage) {
            //fielddata, formvalidator nextpagehandler currentPage
            onChangePage(values, formValidator, nextPageHandler, page);
        } else {
            nextPageHandler();
        }
    }

    const handleSubmit = () => {
        if (!formValidator.validateAll(values)) {
            return null;
        }
        if (!onSubmit) {
            return null;
        }
        onSubmit(values, formValidator);
    }

    const isFieldHidden = (field) => {
        if (!field.visibleWhen) return false;
        const operators = ['<=', '>=', '==', '!=', '<', '>'];
        for (let i = 0; i < operators.length; i++) {
            if (field.visibleWhen.indexOf(operators[i]) !== -1) {
                const [fieldname, value] = field.visibleWhen.split(operators[i]);
                let otherFieldVal = values.validated[fieldname];
                if (typeof otherFieldVal === 'undefined') otherFieldVal = values.changed[fieldname];
                if (typeof otherFieldVal === 'undefined') otherFieldVal = values.original[fieldname];
                // eslint-disable-next-line
                if (operators[i] === '==' || operators[i] === '!=') return !otherFieldVal || !eval('"' + otherFieldVal + '"' + operators[i] + '"' + value + '"');
                // eslint-disable-next-line
                return !otherFieldVal || !eval(parseFloat(otherFieldVal) + operators[i] + parseFloat(value));
            }
        }
        return false;
    }

    const renderFields = (fields) => {
        return fields.map((field) => {
            if (isFieldHidden(field)) return null;
            let elem;
            let value = values.changed[field.name] || '';
            let error = values.errors[field.name] || '';
            switch (field.type) {
                case 'text':
                case 'url':
                case 'number':
                case 'password':
                case 'email':
                    elem = (
                        <FieldText field={field} value={value} onChange={setValue} error={error}
                                   extraMessage={extraMessage}/>
                    );
                    break;
                case 'currency':
                    elem = (<FieldCurrency field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                case 'currency-decimal':
                    elem = (<FieldCurrencyDecimal field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                case 'button':
                    elem = (<FieldButton field={field} value={value} onClick={nextPage} error={error}/>);
                    break;
                case 'submit':
                    elem = (
                        <FieldButton loading={!!loading} field={field} value={value} onClick={handleSubmit}
                                     error={error}/>);
                    break;
                case 'submit-primary':
                    elem = (
                        <FieldButton loading={!!loading} primary={true} field={field} value={value} onClick={handleSubmit}
                                     error={error}/>);
                    break;
                case 'options':
                    elem = (<FieldOptions field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                case 'select':
                    elem = (<FieldSelect field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                case 'singlecheckbox':
                    elem = (<FieldSingleCheckbox field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                case 'toggle':
                    elem = (<FieldToggle field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                case 'multiradio':
                    elem = (<FieldMultiRadio field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                case 'infobox':
                    elem = (<FieldInfobox field={field}/>);
                    break;
                case 'textarea':
                    elem = (<FieldTextArea field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                case 'currency-select':
                    elem = (<FieldCurrencySelect field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                case 'set-currency-select':
                    elem = (
                        <FieldSetCurrencySelect field={field} value={value} onChange={setValue} error={error}/>);
                    break;
                default:
                    elem = (<div>{field.type}</div>);
            }
            return (<div key={field.name} className={field.classes ? field.classes : ''}>{elem}</div>)
        })
    }

    const renderPage = () => {
        return (<div>
            <form>
                {fields.pages[page].title && <h3 className={'mb-4 uppercase text-white font-boldfont'}>{fields.pages[page].title}</h3>}
                <div className={fields.pages[page].classes}>
                    {renderFields(fields.pages[page].fields)}
                </div>
            </form>
        </div>)
    }

    return <div>
        <SwitchTransition mode={'out-in'}>
            <CSSTransition
                key={page}
                timeout={200}
                addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
            >
                {renderPage()}
            </CSSTransition>
        </SwitchTransition>
    </div>
}


export default Form
