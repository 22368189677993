import {React, useContext, useEffect, useState} from 'react'
import {Link, Navigate, useNavigate} from "react-router-dom"
import Box from "../../components/Box";
import Section from "../../components/Section";
import IconLogIn from "../../components/icons/IconLogIn";
import IconGoogle from "../../components/icons/IconGoogle";
import LinkWithArrow from "../../components/LinkWithArrow";
import {apiPostLogin} from "../../utils/api";
import {AuthContext} from "../../utils/Auth";
import {setLoginToken} from "../../utils/utils";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import FormTranslated from "../../components/form/FormTranslated";


const Login = () => {
    //hooks & constants
    let navigate = useNavigate();
    const {
        setUser,
        isLoggedIn,
        setPageAttributes
    } = useContext(AuthContext);
    const {t} = useTranslation();

    //states
    const [loading, setLoading] = useState(false);

    //effects
    useEffect(() => {
        setPageAttributes(t('title.login'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        setLoading(true);
        apiPostLogin(validated.email, validated.password).then((response) => {
            if (response.status) {
                setUser(response.user);
                setLoginToken(response.token);
                navigate('/');
                toast(response.message);
            } else {
                formValidator.responseAdapter(response);
                formValidator.validateAll();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    if (isLoggedIn()) {
        return <Navigate to={'/'}/>
    }

    //render
    return (
        <Section icon={<IconLogIn/>} className={'max-w-md w-11/12 mx-auto'} title={t('button.login')}>
            <Box className={'text-light'}>
                <FormTranslated
                    loading={loading}
                    name={'login'}
                    filename={'login'}
                    onSubmit={handleSubmit}
                />
                <div
                    className={"text-center relative mt-6 mb-6 ml-[-1.5rem] mr-[-1.5rem] before:content-[''] before:block before:w-full before:h-px before:absolute before:top-1/2 before:left-0 before:bg-secondary"}>
                    <div className={"relative bg-dark inline-block pr-9 pl-9"}>{t('register.or')}</div>
                </div>
                <div className="text-center text-white mb-2">
                    <IconGoogle/><LinkWithArrow href={'https://staging.evnxt.ai/login-with-google'}
                                                text={t('register.googleLogin')}/>
                </div>
            </Box>
            <div className={"mt-4 mb-2"}><Link to={"/forgot-password/"}
                                               className={'text-light hover:text-white transition-colors duration-300'}>{t('register.forgotPassword')}</Link>
            </div>
            <div className={"mb-4"}><Link to={"/register/"}
                                          className={'text-light hover:text-white transition-colors duration-300'}>{t('register.noAccount')}</Link>
            </div>
        </Section>

    )
}

export default Login
