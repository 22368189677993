import IconAdjustments from "./icons/IconAdjustments";
import IconCross from "./icons/IconCross";
import FlexList from "./parts/FlexList";
import Form from "./form/Form";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {apiPutAuthor} from "../utils/api";
import toast from "react-hot-toast";
import InfoText from "./parts/InfoText";


const Keywords = ({questions, keywords, authorId, onChange = null}) => {
    //hooks & constants
    const {t} = useTranslation();

    //states
    const [editKeywords, setEditKeywords] = useState(false);
    const [loading, setLoading] = useState(false);
    const keywordsComplete = keywords && Object.keys(questions).every(key => keywords[key]);


    //effects
    useEffect(() => {
        if (!keywordsComplete) {
            setEditKeywords(true);
        }
    }, [authorId, keywordsComplete]);

    //methods
    const handleSubmit = (fieldData, formValidator) => {
        const {validated} = fieldData;
        const newValues = {...keywords, ...validated};
        setLoading(true);
        apiPutAuthor(authorId, {writing_rules: JSON.stringify(newValues)})
            .then((response) => {
                if (response.status) {
                    if (onChange) {
                        onChange(response.author.writing_rules);
                    }
                    toast(t('toast.author.keywordsUpdated'));
                } else {
                    formValidator.responseAdapter(response);
                    formValidator.validateAll();
                }
            }).finally(() => {
            setLoading(false);
        });
    }


    const keywordsJSON = () => {
        const keywordsJSON = {
            pages: [{
                title: t('author.editKeywords'),
                classes: 'sm:grid sm:grid-cols-2 sm:gap-4',
                fields: []
            }]
        };

        Object.keys(questions).forEach((key) => {
            const item = questions[key];
            const itemObj = {
                name: key,
                type: (item['type'] === 'textarea' ? 'text' : item['type']),
                label: item['label'],
                suffix: item['description'],
                placeholder: '',
                validator: 'required',
                visibleWhen: ''
            }
            keywordsJSON.pages[0].fields.push(itemObj);
        });

        keywordsJSON.pages[0].fields.push({
            name: "",
            type: "submit-primary",
            label: t('button.saveSettings'),
            classes: "col-span-2",
            suffix: ""
        });
        return keywordsJSON;
    };

    //render
    return (
        <div className={'relative'}>
            <InfoText
                description={t('author.keywordsDescription')}
                border={false}
            />
            <button onClick={() => setEditKeywords(!editKeywords)}
                    className={'text-white absolute top-0 right-0'}>{(!editKeywords ?
                <IconAdjustments/> :
                <IconCross/>)}</button>
            {!editKeywords ? <>
                <FlexList keyword={true} className={'space-y-0 !flex-row flex-wrap relative before:content-none sm:before:content-[""] before:block before:w-px before:absolute before:bottom-0 before:top-0 before:left-1/2 before:bg-secondary'} itemClasses={'basis-full sm:basis-1/2 py-2 sm:odd:pr-12 sm:even:pl-12'}
                          items={Object.keys(questions).map((key, index) => {
                              let value = keywords?keywords[key]:'';
                              let className = '';
                              if (!value) {
                                  value = t('globals.incomplete');
                                  className = 'text-danger'
                              } else if (questions[key]['type'] === 'select') {
                                  if(keywords[key] === 'other'){
                                      value = keywords[key + '_other'];
                                  } else{
                                        value = questions[key]['choices'][keywords[key]];
                                  }
                              }
                              return {
                                  label: questions[key]['label'],
                                  value,
                                  className: className
                              }
                          })}
                />
            </> : <>
                <Form
                    loading={loading}
                    name={'editKeywords'}
                    fields={keywordsJSON()}
                    data={keywords}
                    onSubmit={handleSubmit}
                />
            </>
            }
        </div>
    )
}

export default Keywords
