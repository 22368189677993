import React, {useEffect, useState} from "react";
import {getLoginToken, deleteLoginToken, useInterval} from "./utils";
import {apiGetMessages, apiGetUser, apiGetUserNoToken} from "./api";


const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null)
    const [userLoading, setUserLoading] = useState(true);
    const [pageTitle, setPageTitle] = useState('');
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        //check if we have a token in locaLstorage
        const token = getLoginToken();
        let phpSession = [];

        if (token) {
            setUserLoading(true);
            apiGetUser().then((response) => {
                if (response.status) {
                    setUser(response.user);
                } else {
                    deleteLoginToken()
                }
            }).finally(() => {
                setUserLoading(false);
            })
        }else{
            setUserLoading(true);
            apiGetUserNoToken().then((response) => {
                if (response.status) {
                    setUser(response.user);
                }
            }).finally(() => {
                setUserLoading(false);
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useInterval(() => {
        getMessages();
    }, 30*1000);

    const isLoading = () => {
        return userLoading;
    }
    const isLoggedIn = () => {
        return user !== null
    }

    const getMessages = () => {
        if(user){
            apiGetMessages().then((response) => {
                if (response.status) {
                    setLogs(response.messages)
                }
            })
        }
    }

    const setPageAttributes = (title, newBreadcrumbs = []) => {
        if (title !== pageTitle) {
            setPageTitle(title);
        }
        if (JSON.stringify(newBreadcrumbs) !== JSON.stringify(breadcrumbItems)) {
            setBreadcrumbItems(newBreadcrumbs);
        }
        document.title = title + ' | Evnxt App';
    }

    const value = {
        user,
        setUser,
        isLoggedIn,
        userLoading,
        setPageAttributes,
        pageTitle,
        breadcrumbItems,
        logs
    };


    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};


export const AuthContext = React.createContext(null);

export default AuthProvider;
